@mixin transition($v) {
  -webkit-transition: $v;
  -o-transition: $v;
  transition: $v;
}

@mixin transform($v) {
  -webkit-transform: $v;
  -ms-transform: $v;
  transform: $v;
}

@mixin transform-origin($v) {
  -webkit-transform-origin: $v;
  -ms-transform-origin: $v;
  transform-origin: $v;
}

@mixin flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin flex-basis($v) {
  -webkit-flex-basis: $v;
  -ms-flex-preferred-size: $v;
  flex-basis: $v;
}

@mixin flex-justify-content($v) {
  -webkit-box-pack: $v;
  -webkit-justify-content: $v;
  -ms-flex-pack: $v;
  justify-content: $v;
}

@mixin flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-width($v) {
  -webkit-box-flex: $v;
  -webkit-flex: $v;
  -ms-flex: $v;
  flex: $v;
}

@mixin flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin flex-grow($v) {
  -webkit-box-flex: $v;
  -webkit-flex-grow: $v;
  -ms-flex-positive: $v;
  flex-grow: $v;
}

@mixin flex-order($v) {
  -webkit-box-ordinal-group: $v + 1;
  -webkit-order: $v;
  -ms-flex-order: $v;
  order: $v;
}

@mixin box-shadow($v) {
  -webkit-box-shadow: $v;
  box-shadow: $v;
}

@mixin rating($v) {
  border-color: $v;
  color: $v;
  background-color: #fff;
  &:hover {
    background-color: $v;
    color: #fff;
  }
}