.toasted {
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  font-size: rem(14) !important;
  height: rem(50) !important;
  width: rem(480) !important;
  justify-content: start !important;
  top: rem(20) !important;
  transform: translateY(rem(-20)) !important;

  i {
    margin-bottom: rem(5);
    margin-right: rem(20);
  }
}

.toasted.bubble {
  border-radius: rem(10) !important;
}

.toasted.bubble.error {
  background: #333333 !important;

  i {
    color: #c11e53;
    margin-bottom: rem(5);
    margin-right: rem(20);
  }
}

.toasted.bubble.success {
  background: #333333 !important;

  i {
    color: #1cb09c;
    margin-bottom: rem(5);
    margin-right: rem(20);
  }
}
