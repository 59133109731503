$red: #c11e53;
$error: #dc3545;
$blue: #128caf;
$light-orange: #f39819;
$dark-orange: #e48500;
$light-blue: #0c8db8;
$green: #1cb09c;
$gray: #999;
$white: #ffffff;
$bg-dark-gray: #e1e1e0;
$bg-btn-sm-blue: #129ecd;
$bg-btn-round-blue: #0c8db8;
$bg-btn-disabled: #c4c4c4;
$border-error: #c11e53;
$header-item-border: rgba(13, 95, 119, 0.35);
$header-menu-active: #094b5e;

$dj-black-1: #222222;
$dj-black-2: #333333;
$dj-black-3: #454545;

$dj-color-gray: #f0f0ee;
$dj-color-middle-gray: #b2b2b2;
$dj-color-dark-gray: #333333;
$dj-color-orange: #ed9636;
$dj-color-red: $red;
$dj-color-white: $white;
$dj-color-blue: #129ecd;
$dj-color-dark-blue: #0a6987;
$bg-color-dark-grey: #c4c4c4;

$dj-text-color: $dj-color-dark-gray;

$dj-font-regular: "Raleway";
$dj-font-semibold: "Raleway SemiBold";
$dj-font-bold: "Raleway Bold";
$dj-font-extrabold: "Raleway ExtraBold";

$dj-font-size-base: 14px;

$dj-input-height: 50px;
$dj-input-lineheight: $dj-input-height;
$dj-input-padding-y: 10px;
$dj-input-padding-x: 10px;

$dj-input-color: $dj-text-color;
$dj-input-placeholder-color: $dj-color-middle-gray;
$dj-input-bg:  $dj-color-white;

$dj-validation-error: $red;

