.dj-pagination {
    display: flex;
    align-items: center;
    justify-content: center;

    &__prev {
        align-items: center;
        background-color: transparent;
        display: flex;
        font-family: $dj-font-bold;
        height: rem(40);
        justify-content: center;
        margin-right: rem(8);
        width: rem(40);

        i {
            transform: rotate(90deg);
        }
    }

    &__next {
        align-items: center;
        background-color: transparent;
        display: flex;
        font-family: $dj-font-bold;
        height: rem(40);
        justify-content: center;
        width: rem(40);

        i {
            transform: rotate(-90deg);
        }
    }

    &__page {
        align-items: center;
        background-color: $dj-color-gray;
        border: 2px solid $dj-color-orange;
        border-radius: rem(10);
        display: flex;
        font-family: $dj-font-bold;
        height: rem(40);
        justify-content: center;
        margin-right: rem(8);
        width: rem(40);
    }

    &__gap {
        align-items: center;
        border: 2px solid transparent;
        border-radius: rem(10);
        display: flex;
        font-family: $dj-font-bold;
        height: rem(40);
        justify-content: center;
        margin-right: rem(8);
        width: rem(16);
    }

    &__page--active {
        background-color: $dj-color-orange;
    }

    &--disabled {
        pointer-events: none;
        color: $dj-color-middle-gray;
        cursor: not-allowed;

        i {
            color: $dj-color-middle-gray;
        }
    }

    a {
        cursor: pointer;
    }

    i {
        margin-right: 0;
    }
}

