.dj-nav-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: rem(40);
  border-bottom: 3px solid $dj-color-gray;

  &__item {
    font-family: "Raleway Bold", sans-serif;
    font-size: rem(16);
    text-transform: uppercase;
    color: $dj-black-1;
    padding-bottom: rem(15);
    position: relative;
  }

  &__item--longer {
    width: rem(100);
    justify-content: center;
    display: flex;
  }


  &__item:hover:before, &__item--active:before {
    width: calc(100% + 10px);
    content: '';
    position: absolute;
    border-bottom: 4px solid $light-orange;
    left: 0;
    bottom: rem(-3);
    transform: translateX(-5px);
    cursor: pointer;
  }

  &__item a, &__item:hover a {
    color: $dj-black-1;
    text-decoration: none;
  }
}
