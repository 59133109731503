//*, ::after, ::before {
//    box-sizing: border-box
//}

//html, body, div, span, applet, object, iframe,
//h1, h2, h3, h4, h5, h6, p, blockquote, pre,
//a, abbr, acronym, address, big, cite, code,
//del, dfn, em, img, ins, kbd, q, s, samp,
//small, strike, strong, sub, sup, tt, var,
//b, u, i, center,
//dl, dt, dd, ol, ul, li,
//fieldset, form, label, legend,
//table, caption, tbody, tfoot, thead, tr, th, td,
//article, aside, canvas, details, embed,
//figure, figcaption, footer, header, hgroup,
//menu, nav, output, ruby, section, summary,
//time, mark, audio, video {
//    margin: 0;
//    padding: 0;
//    border: 0;
//    font-size: 100%;
//    font: inherit;
//    vertical-align: baseline;
//}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
*:focus {
    outline: none;
}
body {
    line-height: 1;
}
a {
    text-decoration: none;
}
img {
    vertical-align: middle;
    border-style: none;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

ol, ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

textarea:focus, input:focus{
    outline: none;
}

.multiple.search {
    .delete.icon {
        font-style: normal;
        &:before {
            content: 'x'
        }
    }
}

.mx-shortcuts-wrapper .mx-shortcuts.active {
    color: #419dec;
}

.list-circle-inside {
    list-style: circle;
    margin-bottom: 16px;
    padding-left: 40px;
}

button {
    background: transparent;
    border: 0;
}

.modal-open > .modal-backdrop {
    display: block;
}

.modal {
    display: block;
    z-index: 2;
}

.modal-backdrop {
    display: none;
    z-index: 1;
    opacity: .5;
}

.dropdown {
    white-space: nowrap;
}

button:focus {
    outline: none;
}