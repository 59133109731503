.notifications-icon-header {
    position: relative;
    justify-content: center;
    .notifications-open & {
        background: $header-menu-active;
    }
}

.notifications {
    color: $dj-black-2;
    & > * {
        padding: rem(30) rem(224) rem(24) rem(30);
        &:hover {
            box-shadow: 6.8px 5.9px 7px 0 rgba(0, 0, 0, 0.1);
        }
    }

    .notification-icon {
        width: rem(70);
        margin-top: rem(-10);
        [class^="icon-"] {
            color: $light-orange;
            font-size: rem(38);
        }
    }

    .notification-title {
        display: block;
        color: inherit;
        &:hover {
            text-decoration: none;
            color: inherit;
        }
    }

    .notification-text {
        font-family: "Raleway SemiBold", sans-serif;
        padding: rem(15) 0 rem(20);
        margin: 0;
    }

    .notification-date {
        font-family: "Raleway SemiBold", sans-serif;
    }
}