.quotation-list-block {
  font-family: "Raleway SemiBold", sans-serif;
  line-height: normal;
}

.quotation-list-body {
  padding: rem(30) 0;
  .item-type {
    i {
      font-size: rem(40);
    }
  }

  .item-ref {
    span {
      display: block;
      word-break: break-all;
    }
  }

  .item-date {
    position: relative;
    display: inline-block;
    padding-right: rem(36);
    cursor: pointer;
    width: rem(175);
    &:after {
      content: '';
      font: normal normal normal rem(32) 'Djump-icons';
      position: absolute;
      right: 0;
      top: -8px;
    }
  }
}

.quotation-item-details {
  background: $bg-dark-gray;
  border-radius: 0 0 10px 10px;
}

.borderless-search {
  background: $dj-color-gray;
  input {
    font-family: "Raleway Bold", sans-serif;
    font-size: rem(16);
    &::placeholder {
      color: $dj-color-middle-gray;
    }
  }
}

.quotation-filter {
  position: relative;
  display: inline-block;
  padding-right: rem(36);
  cursor: pointer;
  color: $blue;
  width: rem(175);
  &:after {
    content: '\e906';
    font: normal normal normal rem(32) 'Djump-icons';
    position: absolute;
    right: 0;
    top: -8px;
  }

  &.active {
    &:after {
      transform: rotate(180deg);
    }
  }
}
