.quotation-icon-block {
  position: relative;
}

.quotation-price-message {
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0 rem(15);
  //justify-content: end;
}

.quotation-delivery-option {
  cursor: pointer;
  height: rem(420);
  //width: rem(270);
  object-fit: cover;
  @include flex-container;
  @include flex-column;
  color: white;
  border-radius: rem(10);
  text-align: center;
  @include transition(all .3s ease-in-out);
  &:hover {
    box-shadow: 2.3px 2px 7px 0 rgba(0, 0, 0, 0.45);;
  }

  &.orange {
    background: #f5a00d;
    color: #222222;

    .dark {
      background: #c87e18;
    }
  }

  &.blue {
    background: #1c8fb9;

    .dark {
      background: #197fa5;
    }
  }

  &.red {
    background: #c11e53;

    .dark {
      background: #9d1944;
    }
  }

  &.green {
    background: #1cb09c;

    .dark {
      background: #189181;
    }
  }

  .option-header {
    height: rem(60);
    font-size: rem(14);
    text-transform: uppercase;
    font-family: "Raleway Bold", sans-serif;
  }

  .header-separator {
    height: rem(2);
  }

  .header-left {
    width: rem(20);
    border-radius: rem(10) 0 0 0;
  }

  .header-text {
    padding-right: rem(20);
    flex-grow: 1;
    justify-content: center;

    span {
      align-self: center;
    }
  }

  .price-title {
    margin-top: rem(30);
    font-family: "Raleway Bold", sans-serif;
    font-size: rem(14);
  }

  .price-constraints {
    max-width: 70%;
    margin: rem(15) auto 0;
    line-height: rem(20);
    display: inline-block;
  }

  .price {
    margin: rem(13) 15% rem(24) 15%;
    font-size: rem(40);
    font-family: "Raleway Bold", sans-serif;
  }

  ul {
    text-align: left;
    margin: 0 12% 0 12%;
    li {
      font-family: "Raleway Bold", sans-serif;
      font-size: 1rem;
      padding: rem(5) 0;
      &.delivery-timeframe {
        font-size: rem(24);
        font-family: "Raleway Bold", sans-serif;
        text-align: center;
        margin-top: rem(10);
      }
    }
  }

  .delivery-button {
    line-height: rem(50);
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: auto;
    margin-bottom: rem(25);
    font-family: "Raleway Bold", sans-serif;
    color: #222;

    height: rem(50);
    object-fit: contain;
    border-radius: rem(25);
    background-color: #ffffff;
    width: 80%;

    @include transition(all 0.3s ease-in-out);
  }
}

.quotation-option-placeholder {
  padding: rem(15);
  width: 22%;
  min-width: rem(270);
}

.quotation-description {
  padding: rem(15);

  h6 {
    margin: rem(20) 0 rem(20) 0;
    font-size: rem(16);
    font-family: "Raleway Bold", sans-serif;
    text-transform: uppercase;
  }

  h5 {
    display: inline-flex;
    align-items: center;
    margin-top: rem(20);
    font-size: rem(14);
    text-transform: uppercase;
    border-bottom: 2px solid $dj-black-1;
    cursor: pointer;

    i {
      font-size: rem(24);
    }
  }

  .result {
    height: rem(100);
    border-radius: rem(10);
    border: solid rem(2) #ed9636;
    background-color: #f0f0ee;
    display: flex;
    align-items: center;
    justify-content: center;
    @include flex-wrap;

    span {
      font-size: rem(25);
      font-family: "Raleway Bold", sans-serif;
      color: #454545;
      text-transform: uppercase;
      margin: 0 rem(10) 0 rem(10);
    }

    .separator {
      color: #ed9636;
    }

    .lowercase {
      text-transform: lowercase;
    }

    .selected-price {
      font-size: rem(40);
      font-weight: bold;
      margin-left: rem(70);
    }
  }
}

.quotation-options {
  flex-wrap: wrap;
  justify-content: space-between;
}

.vue-form-wizard {
  .wizard-progress-with-circle{
    display:none;
  }
  .stepTitle {
    font-family: 'Raleway Bold', sans-serif;
    font-size: rem(14);
    text-transform: uppercase;
    color: rgb(41, 41, 41);
  }

  .active {
    .checked {
      &:before {
        color: $green !important;
      }
    }
  }

  .wizard-icon-circle {
    border: none;
    position: relative;
    margin-bottom: rem(20);

    &:before {
      content: '\e917';
      font-size: rem(75);
      font-family: 'Djump-icons';
      font-weight: normal;
      position: absolute;
      top: rem(5);
      left: rem(5);
    }

    &.checked {
      &:before {
        content: "\e907";
        color: #c7c7c7;
      }

      .wizard-icon {
        color: #fff;
      }
    }

    .wizard-icon-container {
      background: transparent !important;
    }

    .wizard-icon {
      font: normal normal normal rem(36) 'Raleway Bold', sans-serif;
      position: relative;
    }
  }

  &.md {
    .wizard-nav-pills {
      >li {
        &.active>a {
          .wizard-icon {
            font-size: rem(36);
          }
        }

        a.disabled {
          .stepTitle {
            color: rgb(41, 41, 41);
          }
        }
      }
    }

    .wizard-icon-circle {
      width: rem(70);
      height: rem(70);
      font-size: rem(24);
    }
  }
}

