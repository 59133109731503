.dropdown-notification {
  border: 1px solid #96c8da;
  width: auto;
  display: block;
  border-radius: 4px;
  position: absolute;
  background-color: white;
  z-index: 1;
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.85rem 1.2rem;
    clear: both;
    font-weight: normal;
    color: #222222;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    &:hover {
      cursor: pointer;
      text-decoration: none;
      background-color: #f8f9fa;
    }
  }
}