.quotation-form-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: rem(50);
  &:not(.register-page) {
    background-color: $dj-color-gray;
    border-radius: rem(10);
  }
}

.quotation-form {
  width: rem(500);
  padding: rem(50) rem(95) 0;

  .register-page & {
    background-color: $dj-color-gray;
    border-radius: rem(10);
  }

  h3 {
    width: 100%;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: rem(15);

  }

  h4 {
    width: 100%;
    font-size: rem(16);
    font-weight: 600;
    text-align: center;
    margin-bottom: rem(40);
    min-height: rem(38);
  }
}
