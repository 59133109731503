.modal-open {
  overflow: hidden
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform: translate(0,-25%);
  transform: translate(0,-25%)
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0,0);
  transform: translate(0,0)
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (.5rem * 2))
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: .3rem;
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, .5);
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .5
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: #222;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef
}

.modal-footer>:not(:first-child) {
  margin-left: .25rem
}

.modal-footer>:not(:last-child) {
  margin-right: .25rem
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width:500px;
    margin: 1.75rem auto
  }

  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2))
  }

  .modal-sm {
    max-width: 300px
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width:800px
  }
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}
////////////////////////
//      Settings      //
////////////////////////

// overlay
$dj-modal-overlay-color:                   #000 !default;                    // Color of overlay screen
$dj-modal-overlay-opacity:                 0.45 !default;                        // Opacity of overlay screen
$dj-modal-shadow:                          0 0 8px rgba(0, 0, 0, 0.6) !default; // Shadow on image or iframe

// spacing
$dj-modal-popup-padding-left:              8px !default;                        // Padding from left and from right side
$dj-modal-popup-padding-left-mobile:       6px !default;                        // Same as above, but is applied when width of window is less than 800px

$dj-modal-z-index-base:                    1040 !default;                       // Base z-index of popup

// controls
$dj-modal-include-arrows:                  true !default;                       // Include styles for nav arrows
$dj-modal-controls-opacity:                0.65 !default;                       // Opacity of controls
$dj-modal-controls-color:                  #FFF !default;                       // Color of controls
$dj-modal-controls-border-color:           #3F3F3F !default; 	                 // Border color of controls
$dj-modal-inner-close-icon-color:          #333 !default;                       // Color of close button when inside
$dj-modal-controls-text-color:             #CCC !default;                       // Color of preloader and "1 of X" indicator
$dj-modal-controls-text-color-hover:       #FFF !default;                       // Hover color of preloader and "1 of X" indicator

// Iframe-type options
$dj-modal-include-iframe-type:             true !default;                       // Enable Iframe-type popups
$dj-modal-iframe-padding-top:              40px !default;                       // Iframe padding top
$dj-modal-iframe-background:               #000 !default;                       // Background color of iframes
$dj-modal-iframe-max-width:                900px !default;                      // Maximum width of iframes
$dj-modal-iframe-ratio:                    9/16 !default;                       // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$dj-modal-include-image-type:              true !default;                       // Enable Image-type popups
$dj-modal-image-background:                #444 !default;
$dj-modal-image-padding-top:               40px !default;                       // Image padding top
$dj-modal-image-padding-bottom:            40px !default;                       // Image padding bottom
$dj-modal-include-mobile-layout-for-image: true !default;                       // Removes paddings from top and bottom

// Image caption options
$dj-modal-caption-title-color:             #F3F3F3 !default;                    // Caption title color
$dj-modal-caption-subtitle-color:          #BDBDBD !default;                    // Caption subtitle color

// A11y
$dj-modal-use-visuallyhidden:              false !default;                      // Hide content from browsers, but make it available for screen readers

.dj-modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $dj-modal-z-index-base + 3;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

.dj-modal__container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 $dj-modal-popup-padding-left;
  box-sizing: border-box;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}
.dj-modal__content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: $dj-modal-z-index-base + 5;
}

.dj-modal__overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $dj-modal-z-index-base + 2;
  overflow: hidden;
  position: fixed;
  background: $dj-modal-overlay-color;
  opacity: $dj-modal-overlay-opacity;
}

.dj-popup {
  position: relative;
  padding: 30px;
  padding-top: 60px;
  max-width: 500px;
  margin: 20px auto;
  width: 444px;
  height: auto;
  max-height: 95vh;
  overflow: auto;
  border-radius: 10px;
  box-shadow: -0.2px 6px 13px 0 rgba(0, 0, 0, 0.24);
  background-color: $dj-color-gray;

  &--lg {
    max-width: rem(900);
    width: rem(800);
  }

  &--md {
    width: rem(500);
  }

  &--pom {
    width: 50rem;
    max-width: none;
  }

  &--overflow {
    .dj-popup__body {
      padding: 0;
      height: 70vh;
      overflow: auto;
      &-inner {
        padding-left: 65px;
        padding-right: 65px;
      }
    }
  }

  &__header {
    text-align: center;
  }

  &__body {
    padding-left: 65px;
    padding-right: 65px;
    .dj-popup--pom & {
      padding: 0 45px;
    }
  }

  &__title {
    font-family: $dj-font-semibold;
    font-size: rem(25);
    margin-bottom: rem(40);
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 8px;
    cursor: pointer;
  }
}
