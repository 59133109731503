.dj-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__inner {
    width: rem(60);
    height: rem(30);
    background-color: $dj-color-middle-gray;
    border-radius: 20px;
    position: relative;
  }

  &__inner--on {
    background-color: $dj-color-orange;
  }

  &__switch {
    width: rem(26);
    height: rem(26);
    background-color: $dj-color-white;
    border-radius: 50%;
    position: absolute;
    left: 2px;
    top: 50%;
    transform: translateY(-50%);
    animation: dj-slide-left .3s;
  }

  &__switch--on {
    left: auto;
    right: 2px;
    animation: dj-slide-right .3s;
  }

  &__label {
    font-family: "Raleway Bold";
    font-size: rem(12);
    text-transform: uppercase;
    margin-right: rem(12);
  }

  &__label--right {
    margin-left: rem(12);
  }

  &__label--off {
    color: $dj-color-middle-gray;
  }
}

@keyframes dj-slide-right {
  0% {
    left: 2px;
    right: auto;
  }
  100% {
    left: 28px;
    right: 2px;
  }
}

@keyframes dj-slide-left {
  0% {
    left: 28px;
    right: 2px;
  }
  100% {
    left: 2px;
    right: auto;
  }
}