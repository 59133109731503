.user-mngm-block {
  &.indent {
    padding-left: rem(36);
  }

  .block-gray {
    padding: rem(40) rem(60);
    width: 80%;
    margin-bottom: rem(30);
  }

  .input-row {
    margin-bottom: rem(20);

    label {
      display: block;
      font-size: rem(16);
      color: $dj-black-2;
      font-family: "Raleway Bold", sans-serif;
    }

    .wrap {
      flex-wrap: wrap;
    }

    .dj-form-control,
    .dj-form-control-readonly {
      padding: rem(10) rem(20);
      font-size: rem(16);
      font-family: "Raleway SemiBold", sans-serif;
      color: $dj-black-3;
      &::placeholder {
        font-family: "Raleway SemiBoldItalic", sans-serif;
        font-style: normal;
      }
      &.ui.fluid.dropdown {
        padding: rem(8) rem(25) rem(8) rem(8);
        height: auto;
        min-height: 50px;
        border-radius: 0;
        & > .dropdown.icon {
          top: 15px;
        }
      }
    }
  }

  .user-options {
    margin-right: rem(40);
    label {
      font-size: rem(16);
      color: $dj-black-2;
      font-family: "Raleway SemiBold", sans-serif;
      padding-left: rem(40);
    }
  }

  .exact-match-item {
    margin-bottom: 0;
    padding-left: rem(35);
    &:before {
      top: 0;
    }
    &:after {
      top: rem(-2) !important;
    }
  }

  .dj-error-msg {
    margin-top: 5px;
  }

  .dj-select,
  .dj-select__dropdown li {
    padding: 10px 20px
  }
}

.profile-actions {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
  .profile-action {
    &--left {
      padding-left: 0;
    }

    &--right {
      padding-right: 0;
    }
  }
}
