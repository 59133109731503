.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-self-center {
  align-self: center !important;
}

.flex-item {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@for $i from 1 through 12 {
  .d-flex > .flex-item-#{$i} {
    flex: 0 0 #{(100 / 12) * $i + '%'};
    max-width: #{(100 / 12) * $i + '%'};
  }

  .mt-#{$i} {
    margin-top: #{$i}px;
  }

  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }

  .ml-#{$i} {
    margin-left: #{$i}px;
  }

  .mr-#{$i} {
    margin-right: #{$i}px;
  }
}

.gutter-1 {
  margin: 0 1rem;
}

@import "reflex-grid/reflex";

.dj-grid-margin-auto {
  >[class*="dj-col-"] {
    margin: auto;
  }
}
.dj-col-margin-auto {
    margin: auto;
}
