.cookies-accept-block {
  background: $dj-black-2;
  padding: rem(25);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;

  .menu-open & {
    display: none;
  }

  .cookies-info {
    margin-right: rem(50);
    max-width: rem(800);
    line-height: 1.5;
  }
}

.cookies-page-actions-block {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 auto;
}