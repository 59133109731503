
.reporting-graph {
  text, g g text {
    font-family: "Raleway Bold", sans-serif !important;
  }
}

.reporting-block {
  .dj-panel {
    background: transparent;
    border: 0;
    min-height: rem(350);
  }

  .dj-tr-status-text__header {
    border-top-left-radius: 10px;
    margin-left: -12px;
    border-left-width: 20px;
  }

  .dj-tr-status-text__body {
    min-height: rem(215);
  }

  .dj-tr-status-text__title {
    font-size: rem(16);
    position: relative;
    [class^="icon-"] {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .dj-tr-status-text {
    padding: rem(10) 0 rem(10) rem(10);
    overflow: hidden;
    border-radius: 5px;
  }
}

.reporting-graph {
  svg > g:last-child > g:last-child { pointer-events: none }
}

.reporting-item,
.reporting-graph {
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 99%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border: 2px solid $dj-color-orange;
  border-radius: 10px;
  background: $dj-color-white;
}

.reporting-item {
  background-color: $dj-color-gray;
  padding: 0;
  &:hover {
    &:not(.hovered) {
      border-radius: 10px;
      transition-delay: .1s;
      &:after {
        border-style: solid;
        width: 0;
        border-width: 20px;
        border-color: $dj-color-orange #FFF #FFF $dj-color-orange;
        border-radius: 10px 0 0 0;
      }
    }
  }
  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    right: -2px;
    border-width: 0;
    transition: border-width .1s;
  }

  &.hovered {
    border-style: dashed;
    box-shadow: 2.3px 2px 24px 0 rgba(0, 0, 0, 0.15);
  }
}

.reporting-graph {
  transform: rotateY(180deg);
  overflow: hidden;
  padding: rem(10) 0 rem(10) rem(10);
}

.clicked {
  .reporting-item {
    transform: rotateY(180deg);
  }

  .reporting-graph {
    transform: none;
  }
}

.edit-chart-block {
  .edit-row {
    margin-bottom: rem(30);
    & > label {
      font-family: "Raleway Bold", sans-serif;
      font-size: rem(16);
      color: $dj-black-2;
      margin-bottom: rem(15);
      text-transform: uppercase;
      display: block;
    }
  }

  .edit-options {
    display: flex;
    flex-wrap: wrap;
    padding-top: rem(15);
    .edit-option {
      margin-right: rem(50);
      margin-bottom: rem(20);
      label {
        font-family: "Raleway SemiBold", sans-serif;
        font-size: rem(16);
        color: $dj-black-2;
      }
    }
  }

  .actions-row {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 rem(15);
  }
}

.modal-confirm-txt {
  font-family: "Raleway SemiBold", sans-serif;
  font-size: rem(16);
  color: $dj-black-2;
  line-height: 1.5rem;
}
