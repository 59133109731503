$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
        1: $spacer,
        2: ($spacer * 2),
        3: ($spacer * 3),
        4: ($spacer * 4),
        5: ($spacer * 5),
        0: 0,
), $spacers);

@each $prop, $abbrev in (margin: dj-m, padding: dj-p) {
  @each $size, $length in $spacers {

    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

.dj-mx-a {
  margin-right: auto;
  margin-left: auto;
}

.dj-ml-a {
  margin-left: auto;
}

.dj-my-a {
  margin-top: auto;
  margin-bottom: auto;
}