// --------------------------------------------------
// reflex variables
// --------------------------------------------------

// Prefix
// -------------------------
// You can optionally namespace reflex classes to avoid clashing with other css frameworks
// e.g. setting $reflex-prefix as "reflex-" here would cause col-sm-6 to become reflex-col-sm-6
// Example: $reflex-prefix: reflex-;
// By default this is an empty string
$reflex-prefix: "dj-" !default;


// Legacy support
// -------------------------
// Reflex adds a lot of css propertie to support older browsers that don't support flexbox layout.
// If you prefer to use this as a purely flexbox grid without legacy support then set this to false
$legacy-support: false !default;

// Responsive visibility helpers
// -------------------------
// Reflex has some generic visibility classes to hide elements at different breakpoints.
// If you want to disable these then set this to false
$visibility-helpers: false !default;

// Grid
// -------------------------
$reflex-columns: 12 !default; // number of columns

// Breakpoints
// -------------------------
$reflex-xs: 576px !default;
$reflex-sm: 768px !default;
$reflex-md: 992px !default;
$reflex-lg: 1200px !default;
$reflex-xlg: 1600px !default;

$reflex-xxs-max:    ($reflex-xs - 1);
$reflex-xs-max:     ($reflex-sm - 1);
$reflex-sm-max:     ($reflex-md - 1);
$reflex-md-max:     ($reflex-lg - 1);
$reflex-lg-max:     ($reflex-xlg - 1);

// Spacing
// -------------------------
$reflex-grid-spacing:       0 !default;
