.chat-history-block-header {
  display: flex;
  margin-top: rem(16);
  padding: rem(12);
  align-items: center;
  font-size: rem(20);

  .dj-rating {
    margin-left: auto;
  }

  i {
    border: 2px solid $dj-color-gray;
    margin-left: rem(22);
    cursor: pointer;
  }
}

.chat-history-block {
  &--left {
    width: rem(340);
    .record-list {
      margin-bottom: rem(20);
      max-height: 45rem;
      overflow: auto;

      li {
        background: $dj-color-gray;
        padding: rem(20);
        cursor: pointer;
        &:not(:last-child) {
          border-bottom: 2px solid $bg-dark-gray;
        }

        &:first-child {
          border-top-left-radius: 6px;
          border-top-right-radius: 6px;
        }

        &:last-child {
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        &.active,
        &:hover {
          background: $blue;
          [class^="record"] {
            color: #fff;
          }
        }

        .record {
          &-date {
            font-size: 1rem;
            font-family: "Raleway Bold", sans-serif;
            color: $dj-color-middle-gray;
          }

          &-name {
            font-size: 1rem;
            font-family: "Raleway SemiBold", sans-serif;
            color: $dj-black-3;
            padding: rem(17) 0 rem(12);
          }

          &-text {
            font-size: 1rem;
            font-family: "Raleway SemiBold", sans-serif;
            color: $dj-color-middle-gray;
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  &--right {
    width: calc(100% - 27.85714rem);
    margin-left: auto;

    .admin-history & {
      margin-top: rem(40);
    }

    .spinner-cont {
      height: rem(300);
    }

    &-inner {
      padding: rem(20);
      border-radius: 6px;
      background: $dj-color-gray;
      min-height: rem(330);
      max-height: 45rem;
      overflow: auto;
    }

    .message-block {
      margin-bottom: rem(30);
    }
  }

  .message-block--operator {
    .message-block-content {
      padding: rem(10) 0 0 rem(10);
    }

    .message-text {
      background: #fff;
    }

    .message-time {
      margin-left: auto;
    }
  }

  .message-block--own {
    justify-content: flex-end;
    .message-block-content {
      padding: rem(10) rem(10) 0 0;
      order: 1;
    }

    .user-pic-block {
      order: 2;
    }

    .message-text {
      background: $bg-dark-gray;
    }

    .user-name {
      text-align: right;
    }
  }

  .message-block--system {
    margin-bottom: 0;
    .user-pic-block,
    .user-name,
    .message-time {
      display: none;
    }

    .message-block-content {
      max-width: 100%;
      flex-grow: 1;
    }

    .message-text {
      text-align: center;
      font-style: italic;
    }
  }

  .message-block-content {
    font-size: 1rem;
    font-family: "Raleway SemiBold", sans-serif;
    color: $dj-black-3;
    max-width: 70%;
  }

  .message-text {
    padding: rem(20);
    border-radius: 7px;
    margin: rem(15) 0 rem(5);
    line-height: 1.35;
  }

  .message-time {
    font-size: rem(12);
    font-family: 'Raleway', sans-serif;
  }

  .history-action {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-family: "Raleway Bold", sans-serif;
    text-transform: uppercase;
    color: $dj-black-3;
    margin-bottom: rem(15);
    line-height: rem(26);
    &.align-right {
      margin-left: auto;
    }
  }
}

.admin-history {
  .dj-rating {
    pointer-events: none;
  }

  .chat-history-block--right {
    .dj-select {
      width: calc(100% - 85px);
    }
  }
}

.chat-history-block-convo {
  border: 2px solid $dj-color-orange;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
  .chat-history-block-header {
    background: $bg-dark-gray;
    margin: 0;
    padding: rem(20);
    i {
      border: 0;
    }

    .user-data {
      label {
        display: block;
        font-size: rem(14);
        font-family: "Raleway SemiBold", sans-serif;
        margin-bottom: rem(15);
      }

      span {
        font-size: rem(18);
        font-family: "Raleway SemiBold", sans-serif;
      }
    }

    .dj-rating {
      width: rem(200);
    }

    .dj-rating__star:before {
      font-size: 2rem;
    }
  }

  .chat-history-block--right-inner {
    border-radius: 0;
  }
}

.message-block-content {
  .dj-rating {
    width: 9rem;
  }
}