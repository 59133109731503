.contact-form:not(.p-0) {
  padding: rem(40) rem(95);
}

.contact-item {
  padding-bottom: rem(20);
}

.quotation-contact-block {
  .title-text {
    color: #333;
  }
}
