.profile-block {
  .block-gray {
    padding: rem(33) rem(53) rem(50);
  }

  .right-block {
    padding: rem(25) 0 0 rem(30);
    width: 100%;
  }

  .user-data-block {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
    grid-gap: 2vw;
  }

  .data-block {
    font-size: rem(16);
    & > label {
      font-family: "Raleway Bold", sans-serif;
      color: $dj-black-1;
      display: block;
      line-height: rem(22);
    }

    & > span {
      font-family: "Raleway SemiBoldItalic", sans-serif;
      color: $dj-black-3;
    }
  }
}