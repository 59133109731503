.upload-files {
    border-radius: 5px;
    background: #fff;
    .preview {
        margin-bottom: 70px;
    }
}

.upload-files-header {
    background: rgba(23, 162, 184, .8);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    color: #fff;
    font-size: 40px;
    margin: 0;
    padding: 25px 0;
    .material-icons {
        font-size: 40px;
    }
}

.upload-files-body {
    text-align: center;
    padding: 70px 0 30px;
    border: 2px dashed rgba(23, 162, 184, .8);
    transition: background-color .15s linear;
    background: #fff;
    &.on-drag {
        background: rgba(0, 0, 0, .03);
    }
}

.excel-img {
    width: 120px;
    height: 120px;
    display: block;
    margin: 0 auto 30px;
}

.upload-files-slim {
    text-align: center;
    padding: 70px 0;
    border: 2px dashed $light-blue;
    transition: background-color .15s linear;
    background: #fff;

    #wf_forum_content & {
        padding: 30px 0;
        border-radius: 10px;
        label {
            font-size: 14px;
            font-family: "Raleway Bold", sans-serif;
            color: $dj-black-1;
            text-transform: uppercase;
            margin: 0;
        }
    }

    .progress {
        font-size: 24px;
        margin-top: 15px;
    }

    &.on-drag {
        background: rgba(0, 0, 0, .03);
    }

    label {
        color: #808080;
        font-size: 28px;
        cursor: pointer;
    }
}

.upload-files-list {
    text-align: left;
    li {
        display: inline-flex;
        justify-content: space-between;
        margin: 8px;
        background-color: $bg-dark-gray;
        font-size: 16px;
        font-family: "Raleway SemiBold", sans-serif;
        color: $dj-black-1;
        padding: 14px;
        border-radius: 6px;
        width: 265px;

        &.loading {
            animation: blinker 2s linear infinite;
        }
    }

    span {
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        line-height: 25px;

        &:hover {
            text-decoration: underline;
        }
    }

    a {
        display: flex;
        text-decoration: none;
        .icon-close {
            font-size: 25px;
            color: $light-blue;
        }
    }
}

.file-upload-btn {
    cursor: pointer;
    span {
        display: inline-block;
        line-height: rem(22);
        border-bottom: 2px solid $dj-black-2;
    }

    &:hover {
        color: $dark-orange;
        span {
            border-color: $dark-orange;
        }
    }
}

@keyframes blinker {
    50% { opacity: 0; }
}
