.quotation-summary {
  color: $dj-black-2;

  .title {
    margin-top: rem(20);
    margin-bottom: rem(30);
    font-size: rem(16);
    font-family: "Raleway Bold", sans-serif;
    text-transform: uppercase;
  }

  .price-cell {
    width: rem(150);
    margin-left: auto;
    font-size: rem(16);
    font-family: "Raleway SemiBold", sans-serif;

    &_big {
      font-size: 25px;
    }
  }

  .box {
    margin: rem(15) 0 rem(30) 0;
    background-color: $dj-color-gray;
    padding: rem(20) rem(25);

    h4 {
      font-size: 1rem;
      font-family: "Raleway Bold", sans-serif;
      margin: rem(10) 0 rem(10) 0;
      text-transform: uppercase;
    }

    span {
      width: 100%;
    }
  }

  .logistics {
    .section {
      width: 50%;

      div {
        margin: rem(5) rem(5) rem(5) 0;
        font-size: rem(16);
        font-family: "Raleway SemiBold", sans-serif;
        overflow: hidden;
      }

      &.centered {
        i {
          font-size: 40px;
        }
      }
    }
  }

  .selected-items {
    @include flex-wrap;

    h4 {
      width: 100%;
    }

    .static-word {
      font-size: 1rem;
      font-family: "Raleway Bold", sans-serif;
      text-transform: uppercase;
    }

    .box-row {
      width: 100%;
      flex-direction: row;
      margin: rem(10) 0 rem(10);
    }

    .section {
      width: 40%;
      min-width: rem(150);
      font-size: rem(16);
      font-family: "Raleway SemiBold", sans-serif;
    }

    .totals-row {
      .section,
      .price-cell {
        font-size: rem(16);
        font-family: "Raleway Bold", sans-serif;
      }

      .static-word {
        font-size: 1rem;
      }
    }
  }

  .options-totals {
    padding: 0 !important;
    @include flex-wrap;

    &_row {
      font-size: 1rem;
      font-family: "Raleway Bold", sans-serif;
      text-transform: uppercase;
      padding: rem(20) rem(25);
      height: rem(30);
      @include align-items(center);
    }

    > :nth-child(even) {
      background-color: $dj-color-white;
    }
  }

  .price-totals {
    width: 55%;
    border: solid 2px $dj-color-orange;
    border-radius: rem(10);
    margin-bottom: rem(15);
    margin-left: auto;

    &_row {
      font-size: 1rem;
      font-family: "Raleway Bold", sans-serif;
      text-transform: uppercase;
      padding: rem(20) 0 rem(20) 0;
      height: rem(30);
      @include align-items(center);
    }
  }

  .quotation-terms {
    @include flex-justify-content(center);
    @include flex-direction(column);
    margin: rem(40) 0 rem(20) 0;

    &_row {
      font-size: rem(16);
      font-family: "Raleway SemiBold", sans-serif;
      margin: rem(10) 0 rem(10) 0;
      @include align-items(center);
    }
  }
}

.quotation-summary-back {
  font-size: 1rem;
  cursor: pointer;
  margin-right: auto;
  align-self: center;
  margin-bottom: 0;

}
