.tooltip {
    display: block !important;
    z-index: 10000;
    box-shadow: -0.2px 6px 13px 0 rgba(0, 0, 0, 0.24);
    font-family: 'Raleway SemiBold', sans-serif;
    font-size: 14px;
    //width: rem(300);
    border: solid 2px $dj-color-gray !important;
    border-radius: 16px;
  
    .tooltip-inner {
      max-width: 18rem;
      background: white;
      color: black;
      border-radius: 16px;
      padding: 20px;
      text-align: left;
    }
  
    .tooltip-arrow {
      width: 0;
      height: 0;
      //border-style: solid;
      position: absolute;
      margin: 14px;
      border: 1px solid white;
      z-index: 1;
    }
  
    &[x-placement^="top"] {
      margin-bottom: 14px;
  
      .tooltip-arrow {
        border-width: 14px 14px 0 14px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -14px;
        left: calc(50% - 14px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  
    &[x-placement^="bottom"] {
      margin-top: 14px;
  
      .tooltip-arrow {
        border-width: 0 14px 14px 14px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        top: -14px;
        left: calc(50% - 14px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  
    &[x-placement^="right"] {
      margin-left: 14px;
  
      .tooltip-arrow {
        border-width: 14px 14px 14px 0;
        border-left-color: transparent !important;
        border-top-color: transparent !important;
        border-bottom-color: transparent !important;
        left: -14px;
        top: calc(50% - 14px);
        margin-left: 0;
        margin-right: 0;
      }
    }
  
    &[x-placement^="left"] {
      margin-right: 14px;
  
      .tooltip-arrow {
        border-width: 14px 0 14px 14px;
        border-top-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        right: -14px;
        top: calc(50% - 14px);
        margin-left: 0;
        margin-right: 0;
      }
    }
  
    &.popover {
      max-width: rem(300);
      border-color: transparent;
    }
  
    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      -webkit-transition: opacity .15s, visibility .15s;
      -o-transition: opacity .15s, visibility .15s;
      transition: opacity .15s, visibility .15s;
    }
  
    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      -webkit-transition: opacity .15s;
      -o-transition: opacity .15s;
      transition: opacity .15s;
    }
  }
