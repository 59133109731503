.icon-arrow-menu {
  font-size: rem(32);
  margin-right: rem(-10);
}

.icon-arrow-menu-up {
  font-size: rem(32);
}

.icon-search {
  .search-block & {
    font-size: rem(34);
    vertical-align: middle;
    margin-right: rem(10);
  }

  .borderless-search & {
    color: $dj-color-middle-gray;
    font-size: rem(34);
    vertical-align: middle;
    margin-left: rem(10);
  }

  .dj-tr-fold__location & {
    font-size: rem(33);
  }

  .dj-tr-additional &,
  .dj-tr-fold__c-name & {
    font-size: rem(32);
  }
}

.icon-notification {
  .notifications-icon-header & {
    font-size: rem(33);
    position: relative;
    &.notify {
      span {
        display: inline-block;
        font-family: "Raleway Bold", sans-serif;
        top: rem(3);
        left: rem(20);
        position: absolute;
        color: rgba(255, 255, 255, .9);
        font-size: .71429rem;
        background: #c11e53;
        padding: rem(2) rem(3);
        border-radius: 25px;
        min-width: rem(15);
        text-align: center;
        min-height: rem(15);
      }
    }
  }

  .dj-tr-options & {
    font-size: rem(40);
  }
}

.icon-cotation,
.icon-tracking,
.icon-reporting,
.icon-gestion {
  .home-block-item & {
    position: absolute;
    color: #fff;
    opacity: .75;
  }
}

.icon-cotation {
  .home-block-item & {
    right: rem(-25);
    bottom: rem(-25);
    font-size: rem(115);
  }

  .quotation-icon-block & {
    position: absolute;
    color: $dj-color-gray;
    font-size: rem(150);
    left: rem(-90);
    top: rem(-85);
    z-index: -1;
  }
}

.icon-tracking {
  .home-block-item & {
    right: rem(-55);
    bottom: rem(-45);
    font-size: rem(150);
  }

  .dj-tr-fold__location & {
    font-size: rem(33);
  }

  .navigation .nav-link-tracking & {
    font-size: rem(35);
    margin-left: rem(1);
  }
}

.icon-reporting {
  .home-block-item & {
    right: rem(-60);
    bottom: rem(-60);
    font-size: rem(165);
  }

  .navigation .nav-link-reporting & {
    font-size: rem(36);
    margin-left: rem(-1);
  }
}

.icon-plus {
  .add-icon & {
    font-size: 33px;
    color: $blue;
    position: absolute;
    top: -4.5px;
    left: -5px;
  }

  .dj-status-icon--first & {
    color: $red;
    transform: rotate(135deg);
    font-size: rem(28);
  }
}

.icon-add,
.icon-remove {
  .button-row & {
    font-size: rem(33);
    color: $bg-btn-sm-blue;
  }

  :disabled & {
    color: $bg-btn-disabled;
  }
}

.icon-document {
  .btn--sticky-1 & {
    color: $dj-black-2;
    font-size: rem(23);
    margin-right: rem(10);
  }

  .navigation .nav-link-document & {
    font-size: rem(34);
    margin-left: rem(5);
  }
}

.icon-add {
  .btn--sticky-2 & {
    color: $dj-black-2;
    font-size: rem(30);
    margin-right: rem(10);
  }

  .btn--orange-user & {
    font-size: 26px;
  }
}

.icon-avion {
  .type-img-container & {
    font-size: rem(36);
    padding-top: rem(11);
    padding-left: rem(8);
  }

  .type-col & {
    font-size: rem(40);

    @media (max-width: 1680px) {
      font-size: rem(23);
    }
  }
}

.icon-bateau {
  .type-img-container & {
    font-size: rem(36);
    padding-top: rem(11);
    padding-left: rem(4);
  }

  .type-col & {
    font-size: rem(40);

    @media (max-width: 1680px) {
      font-size: rem(30);
    }
  }
}

.icon-camion,
.icon-elevateur {
  .type-img-container & {
    font-size: rem(36);
    padding-top: rem(10);
    padding-left: rem(6);
  }

  .type-col & {
    font-size: rem(40);

    @media (max-width: 1680px) {
      font-size: rem(30);
    }
  }
}

.icon-camion {
  .type-col & {
    font-size: rem(40);

    @media (max-width: 1680px) {
      font-size: rem(30);
    }
  }
}

.icon-douane {
  .type-img-container & {
    font-size: rem(38);
    padding-top: rem(8);
    padding-left: rem(3);
  }

  .type-col & {
    font-size: rem(40);

    @media (max-width: 1680px) {
      font-size: rem(30);
    }
  }
}

.icon-disconnect {
  .dropdown-item & {
    font-size: rem(23);
    display: inline-block;
    vertical-align: top;
    margin-right: rem(17);
    margin-top: rem(12);
  }
}

.icon-chatbot {
  .contact-button-round & {
    color: #fff;
    font-size: rem(40);
    display: inline-block;
  }

  .dj-chat-header & {
    font-size: rem(60);
  }

  .chat-history-icon-header & {
    font-size: rem(28);
  }
}

.icon-forme {
  .status-col & {
    font-size: rem(30);

    @media (max-width: 1680px) {
      font-size: rem(20);
    }
  }
}

.icon-time {
  .dj-tr-status & {
    font-size: rem(34);
  }

  .dj-status-icon--first & {
    color: $light-blue;
  }
}

.icon-mail {
  .dj-tr-additional & {
    font-size: rem(24);
  }

  .history-action & {
    font-size: rem(18);
    margin-right: rem(7);
  }
}

.icon-document {
  .dj-tr-additional &,
  .dj-tr-fold__c-name & {
    font-size: rem(32);
  }
}

.icon-aide {
  .dj-tr-additional & {
    font-size: rem(30);
  }

  .options-grid & {
    font-size: rem(20);
    &:hover {
      color: $light-orange;
    }
  }
}

.icon-close {
  .dj-tr-fold__control & {
    font-size: rem(40);
    color: $bg-dark-gray;
  }

  .dj-popup__close & {
    color: $bg-btn-sm-blue !important;
    font-size: rem(36);
  }

  .dj-chat-header & {
    color: #fff;
    font-size: rem(40);
  }
}

.icon-arrow-menu-up:before {
  content: "\e906";
  display: block;
  transform: rotate(180deg);
}

.icon-reply {
  .dj-chat__send & {
    font-size: rem(30);
  }

  .btn--orange & {
    font-size: rem(30);
  }
}

.edit-user-btn .icon-refresh {
  font-size: rem(30);
}

.icon-paperplane {
  .dj-chat__send & {
    font-size: rem(30);
  }
}

.icon-modification {
  .edit-user-btn & {
    font-size: rem(30);
  }

  .reporting-graph & {
    font-size: rem(30);
  }

  .dj-btn--transparent & {
    font-size: rem(30);
  }
}

.icon-gestion {
  .navigation .nav-link-gestion & {
    font-size: rem(44);
    margin-left: rem(-2);
  }

  .home-block-item & {
    right: rem(-65);
    bottom: rem(-100);
    font-size: rem(250);
  }

  .forum-icon-block & {
    position: absolute;
    color: $dj-color-gray;
    font-size: rem(250);
    left: rem(-115);
    top: rem(-135);
    z-index: -1;
  }
}

.icon-admin {
  .forum-nav & {
    font-size: rem(40);
    margin-right: rem(7);
  }

  .navigation .nav-link-admin & {
    font-size: rem(38);
    margin-left: rem(1);
  }
}

.icon-attach {
  .file-upload-btn & {
    font-size: rem(36);
  }
}

.icon-home {
  .forum-breadcrumb & {
    font-size: rem(18);
  }
}

.icon-arrow-back {
  .btn--back & {
    font-size: rem(30);
    margin-right: 5px;
  }
}

.icon-delete {
  .history-action & {
    font-size: rem(26);
    margin-right: rem(5);
  }
}

.icon-drag-and-drop {
  .reporting-item & {
    font-size: rem(26);
    opacity: .3;
  }
}

.icon-tchat {
  .chat-history-icon-header & {
    font-size: rem(25);
  }

  .dj-btn.dj-btn--transparent & {
    font-size: rem(20);
    margin-right: 10px;
  }

  .navigation .nav-link-tchat & {
    font-size: rem(27);
    margin-left: rem(4);
  }
}

.icon-reply {
  .navigation .nav-link-reply & {
    font-size: rem(38);
    margin-left: rem(1);
  }
}

.icon-refresh {
  .dj-btn--transparent & {
    font-size: rem(36);
  }
}

.icon-signal {
  .dj-alert-warning & {
    color: $light-orange;
    font-size: rem(30);
    margin-right: 1rem;
  }
}

.icon-check {
  .banned-user-cell & {
    color: $light-orange;
    font-size: rem(30);
    margin-left: rem(-5);
  }
}

.icon-custom-tower {
  background: url('../../public/images/tower.svg') 0 0 no-repeat;
  display: inline-block;
  width: rem(32);
  height: rem(32);
  margin-left: rem(2);
}

.icon-round {
  .forum-checkbox & {
    font-weight: bold;
  }
}

.hint-message {
  color: $dj-black-1;
  font-family: "Raleway Bold", sans-serif;
  font-size: rem(14);
  line-height: rem(50);
  border-radius: 50%;
  background: $light-orange;
  text-transform: uppercase;
  cursor: help;
  width: rem(28);
  height: rem(28);
  margin-left: 10px;
  margin-bottom: 0.5rem;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
