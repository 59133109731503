.h-100 {
  height: 100%;
}

.w-10 {
  width: 10rem;
}

.w-14 {
  width: 14rem;
}

.font-md {
  font-size: 1.5rem;
}

.font-2r {
  font-size: 2rem;
}

.font-25 {
  font-size: rem(25);
}

.is-invalid {
  border: 2px solid $border-error !important;
}

.content-box {
  box-sizing: content-box !important;
}

.material-icons.md-48 {
  font-size: 48px;
}

.font-50 {
  font-size: 50px;
}

.font-100 {
  font-size: 100px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.fz-085 {
  font-size: .85rem;
}

.opacity50 {
  opacity: .5;
}

.rotate-180 {
  transform: rotate(180deg);
}

.invert {
  filter: invert(1);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-medium {
  font-family: "Raleway Medium", sans-serif;
}

.text-bold {
  font-family: "Raleway Bold", sans-serif;
}

.text-bold-italic {
  font-family: "Raleway BoldItalic", sans-serif;
}

.text-italic {
  font-style: italic;
}

.text-semibold {
  font-family: "Raleway SemiBold", sans-serif;
}

.font-14 {
  font-size: 14px;
}

.font-default {
  font-size: rem(14);
}

.font-16 {
  font-size: rem(16);
  line-height: normal;
}

.font-18 {
  font-size: 1.3rem;
  line-height: normal;
}

.font-20 {
  font-size: rem(20);
  line-height: normal;
}

.link--blue {
  color: $blue;
  text-decoration: none;
  &:hover {
    color: $blue;
  }

  &_bordered {
    border-bottom: 1px solid;
  }
}

.blue-tc {
  color: $blue;
}

.light-blue-tc {
  color: $light-blue;
}

.green-tc {
  color: $green;
}

.red-tc {
  color: $red;
}

.gray-tc {
  color: $gray;
}

.orange-tc {
  color: $light-orange;
}

.font-20-rs {
  font-size: rem(20);
}

.flex-grow {
  flex-grow: 1;
}

.d-inline-block {
  display: inline-block;
}

.line-height-md {
  line-height: 1.3rem;
}

.lh-15 {
  line-height: 1.5;
}

.focused {
  border: 2px solid transparent;
  &:focus-within {
    border-color: $light-orange;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.p-20 {
  padding: rem(20);
}

.mb-20 {
  margin-bottom: rem(20);
}

.mt-20 {
  margin-top: rem(20);
}

.dj-mb5 {
  margin-bottom: 5px;
}

.dj-mb15 {
  margin-bottom: rem(15) !important;
}

.tc-2 {
  color: $dj-black-1 !important;
}

.tc-3 {
  color: $dj-black-2 !important;
}

.dj-underline {
  border-bottom: 1px solid;
}

.dj-break {
  word-break: break-all;
}

.dj-nowrap {
  white-space: nowrap;
}

.no-underline {
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.dj-pt-sm {
  padding-top: 0.5rem;
}

.link--underline {
  padding-bottom: 3px;

  &.tc-white {
    color: #fff;
    border-bottom: 2px solid;
  }

  &.tc-black {
    color: $dj-black-1;
    border-bottom: 2px solid;
  }

  &:hover {
    text-decoration: none;
    color: $light-orange;
  }
}

.link--error {
  color: $red;
  border-bottom: 1px solid;
  &:hover {
    text-decoration: none;
    color: $dark-orange;
  }
}

.content-block--text {
  font-size: 14px;
  font-family: "Raleway Medium", sans-serif;
  line-height: 1.5rem;
  p {
    margin: 0;
  }
}

.dj-alert-warning {
  background: $dj-black-2;
  border-radius: 10px;
  padding: rem(20px);
  color: #fff;
  font-family: "Raleway SemiBold", sans-serif;
  display: flex;
  line-height: 1.35;
}

.w-100 {
  width: 100%;
}

.dj-ml-auto {
  margin-left: auto;
}

.dj-py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.orange-hover:hover {
  color: $dark-orange !important;
}

.custom-hr {
  margin: 30px 0;
  &:after {
    content: '';
    width: 70%;
    display: block;
    margin: 0 auto;
    height: 1px;
    background: -moz-linear-gradient(left, #f0f0ee 0%, rgba(153,153,153,1) 50%, #f0f0ee 100%);
    background: -webkit-linear-gradient(left, #f0f0ee 0%, rgba(153,153,153,1) 50%, #f0f0ee 100%);
    background: linear-gradient(to right, #f0f0ee 0%, rgba(153,153,153,1) 50%, #f0f0ee 100%);
  }
}

.no-overflow {
  overflow: visible !important;
  overflow-wrap: break-word;
}
