.news-feed {
    width: 65%;
    background: #129ecd;
    padding: rem(24) rem(10);
    border-radius: 10px;
    color: #fff;
    font-family: "Raleway Bold", sans-serif;
    &.single-record {
        .VueCarousel .VueCarousel-navigation .VueCarousel-navigation-button {
            opacity: .5;
            cursor: default;
        }
    }

    .news-label {
        color: #fff;
        opacity: .7;
    }

    .VueCarousel {
        margin: 0 rem(85);
        .VueCarousel-inner {
            align-items: center;
        }
        .VueCarousel-navigation {
            .VueCarousel-navigation-button {
                color: #fff;
                background: rgba(255, 255, 255, .3);
                border-radius: 50%;
                padding: 0 !important;
                width: 28px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                font-size: 0;
                &:before {
                    content: "\e906";
                    font: normal normal normal 22px 'Djump-icons';
                    color: #fff;
                    display: inline-block;
                    vertical-align: middle;
                }

                &.VueCarousel-navigation-prev {
                    left: rem(-45);
                    &:before {
                        transform: rotate(90deg);
                    }
                }
                &.VueCarousel-navigation-next {
                    right: rem(-45);
                    &:before {
                        transform: rotate(270deg);
                    }
                }
            }
        }
    }

    .VueCarousel-slide {
        line-height: 1.3rem;
        padding: 0 3px;
    }

    .carousel-block {
        position: relative;
        width: 100%;
    }

    .rss-message {
        font-size: rem(18);
        justify-content: center;
    }
}

.news-date {
    color: #fff;
    opacity: .7;
    font-size: 75%;
}

.news-title {
    font-size: rem(16);
    display: block;
    margin-bottom: 10px;
}

.news-desc-inner {
    margin-bottom: 15px;
    font-family: "Raleway SemiBold", sans-serif;
    height: 35px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    p:last-child {
        display: none;
    }
}

.news-link {
    border-bottom: 2px solid;
    text-transform: uppercase;
    color: #fff;
    display: inline-block;
    line-height: 1.4rem;
    &:hover {
        color: $header-menu-active;
        text-decoration: none;
    }
}
