.kibana-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

.iframe-container {
    position: relative;
    overflow: hidden;
    height: calc(100vh - 215px);
    //padding-top: 56.25%;
    //
    //@media (max-width: 768px) {
    //    padding-top: 120%;
    //}
}

