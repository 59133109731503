%ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dj-form-control {
  display: block;
  width: 100%;
  height: $dj-input-height;
  padding: $dj-input-padding-y $dj-input-padding-x;
  line-height: $dj-input-lineheight;
  color: $dj-input-color;
  background-color: $dj-input-bg;
  background-clip: padding-box;
  border: 2px solid transparent;
  font-size: rem(16);
  font-family: "Raleway SemiBold", sans-serif;

  &:focus {
    border-color: $light-orange;
  }

  .focused & {
    border: none;
  }

  &--transparent {
    background: transparent;
  }

  &--medium {
    height: 36px;
  }

  &--large {
    height: rem(50);
  }

  &--bordered {
    border: 1px solid $dj-color-middle-gray;
  }

  &--italic {
    &::placeholder {
      font-family: "Raleway SemiBoldItalic", sans-serif;
    }
  }

  &::placeholder {
    color: $dj-color-middle-gray;
  }

  &--invalid {
    @extend %dj-invalid;
  }

  &--fixed-gray {
    background-color: $dj-color-gray;
    width: rem(254);
  }
}

.dj-form-control-readonly {
  display: block;
  background: $bg-dark-gray;
  border: 0;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
  &::placeholder {
    font-style: italic;
  }

  @at-root input[type="text"]#{&} {
    height: 40px;
  }

  @at-root textarea#{&} {
    height: rem(200);
  }
}

.dj-textarea {
  display: block;
  width: 100%;
  height: rem(200);
  padding: $dj-input-padding-y $dj-input-padding-x;
  font-size: rem(16);
  color: $dj-input-color;
  background-color: $dj-input-bg;
  background-clip: padding-box;
  border: 2px solid transparent;

  &:focus {
    border-color: $light-orange;
  }

  &::placeholder {
    font-family: "Raleway SemiBoldItalic", sans-serif;
    color: $dj-color-middle-gray;
  }
}

.dj-select {
  font-family: $dj-font-bold;
  align-items: center;
  display: flex;
  width: 100%;
  height: $dj-input-height;
  padding: $dj-input-padding-y $dj-input-padding-x;
  font-size: rem(16);
  line-height: $dj-input-lineheight;
  color: $dj-input-color;
  background-color: $dj-input-bg;
  background-clip: padding-box;
  border: 2px solid $bg-color-dark-grey;
  position: relative;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */

  &__arrow {
    margin-left: auto;
    pointer-events: none;
    margin-top: rem(3);
  }

  &__arrow-open {
    transform: rotate(180deg);
  }

  &__placeholder {
    color: $dj-color-middle-gray;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: -2px;
    background-color: $dj-color-white;
    width: 100%;
    border: 2px solid $light-orange;
    z-index: 1;
    box-sizing: content-box;
    max-height: rem(300);
    overflow: auto;

    li {
      align-items: center;
      display: flex;
      min-height: $dj-input-height;
      padding: $dj-input-padding-y $dj-input-padding-x;
      white-space: normal;
      line-height: normal;
    }

    li:not(:last-child) {
      border-bottom: 2px solid $light-orange;
    }
  }

  &--dark {
    color: $dj-color-white;
    background-color: $dj-color-dark-blue;
    border: 2px solid $dj-color-dark-blue;
  }

  &--dark &__dropdown {
    background-color: $dj-color-dark-blue;
    border: 2px solid $dj-color-dark-blue;

    li {
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 5%;
        width: 90%;
        height: 1px;
        background: rgba(240, 240, 238, 0.6);
      }
      &:not(:last-child) {
        border-bottom: 2px solid $dj-color-dark-blue;
      }
    }
  }

  &--tall &__dropdown {
    max-height: rem(800);
  }

  &--overflow {
    .selected-value {
      width: 90%;
      @extend %ellipsis;
    }
  }

  &--50pct {
    width: 50%;
  }

  &--opened:not(&--dark) {
    border-color: $light-orange;
  }

  &--disabled {
    opacity: 0.4;
  }

  .calendar-reporting & {
    height: rem(47);
    width: rem(350);
  }
}

.dj-datepicker {
  &.mx-datepicker-range {
    width: 100%;
  }
  .mx-datepicker-popup {
    max-width: 496px;
  }
  .mx-input-wrapper .mx-input {
    display: block;
    width: 100%;
    height: $dj-input-height;
    padding: $dj-input-padding-y $dj-input-padding-x;
    font-size: 1rem;
    line-height: $dj-input-lineheight;
    color: $dj-input-color;
    background-color: $dj-input-bg;
    background-clip: padding-box;
    border: 0;
  }
}

.dj-search-label {
  align-items: center;
  color: $dj-color-middle-gray;
  display: flex;
  font-family: $dj-font-bold;
  font-size: rem(16);
  text-transform: uppercase;

  i {
    font-size: rem(26);
    margin-right: rem(12);
  }
}

#app .dj-datepicker-icon.mx-datepicker {
  height: 40px;
  width: 50px;

  input {
    display: none;
  }

  .mx-input-append {
    background: transparent;
    width: 42px;
    height: 42px;
    top: rem(-4);
    right: rem(4);
  }
}

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.dj-user-table {
  width: 100%;
  table-layout: fixed;

  thead {
    color: $dj-black-1;
    font-family: $dj-font-bold;
    text-transform: uppercase;
    font-width: normal;
  }

  tbody {
    background-color: $dj-color-gray;
    border-radius: rem(10);
    font-family: $dj-font-semibold;
  }

  tbody > tr:not(:last-child) {
    border-bottom: 3px solid $bg-dark-gray;
  }

  th {
    padding: rem(12) rem(14);
    vertical-align: middle;
    font-weight: normal;
  }

  th:first-child {
    padding-left: rem(37);
  }

  td {
    padding: rem(25) rem(14);
    overflow: hidden;
    text-overflow: ellipsis;
    & > p {
      margin: 0;
    }

    &.action-cell {
      position: relative;
      padding-right: 50px;
    }
  }

  td:first-child {
    position: relative;
    padding-left: rem(37);
  }

  td:first-child:before {
    content: '\e907';
    font-size: rem(20);
    font-family: 'Djump-icons';
    position: absolute;
    top: 50%;
    left: 0;
    color: $green;
    transform: translateY(-50%);
  }

  td.dj-user-table--user-inactive:first-child:before {
    color: $red;
  }

  td > button {
    float: right;
    vertical-align: middle;
    margin-right: rem(25);
    &.edit-user-btn {
      margin: 0;
      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);
    }
  }

  &__icon {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-family: $dj-font-bold;
    text-transform: uppercase;
  }

  &__icon:hover {
    color: $light-blue;
  }
}
