.home-nav {
  justify-content: space-between;
  padding-top: 25px;
}

.home-block-item {
  .home-nav & {
    height: rem(150);
    width: 30%;
  }
  font-size: rem(16);
  font-family: 'Raleway Bold', sans-serif;
  text-transform: uppercase;
}

.home-faq-block {
  margin-left: auto;
  width: calc(100% - 70%);
  min-height: rem(130);
  font-size: 18px;
}

.logo-pic {
  margin-bottom: 25px;
  max-width: 100%;
}

.home-text {
  font-family: 'Raleway SemiBold', sans-serif;
  font-size: rem(16);
  color: #333;
  margin: 0 auto rem(25);
  line-height: 1.5rem;
}

.home-status-icon {
  margin-top: rem(-3);
}

.home-link {
  &:hover {
    opacity: .25;
  }
}