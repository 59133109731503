.dj-chat {
  font-size: 14px;
  background: $dj-color-gray;

  &.operator-chat {
    background: transparent;
  }

  &.sm {
    width: rem(450);
    position: fixed;
    bottom: rem(30);
    right: rem(30);
    z-index: 10;
    border-radius: 10px;
    box-shadow: -0.2px 6px 13px 0 rgba(0, 0, 0, 0.24);
  }

  &-header {
    height: rem(100);
    background: $light-blue;
    color: #fff;

    .sm & {
      border-radius: 10px 10px 0 0;
    }

    .operator-chat & {
      background: transparent;
      padding-top: 60px;
      height: auto;
    }
  }

  &-header-inner {
    .operator-chat & {
      flex-direction: column;
      width: 250px;
      margin-left: 50px;
    }
  }

  &-content {
    height: 100%;
    .sm & {
      height: 70vh;
      border-radius: 0 0 10px 10px;
    }
  }

  &-close {
    cursor: pointer;
    margin-top: rem(15);
  }

  &__messages {
    padding: rem(20);
    overflow: auto;
    height: 100%;
    .sm & {
      height: calc(100% - 5.7rem);
    }

    .operator-chat & {
      display: flex;
      flex-direction: column;
    }
  }

  &__message-block {
    margin-bottom: rem(30);
    &--own {
      .message-block {
        justify-content: flex-end;
      }
      .dj-chat {
        &__user-pic {
          order: 2;
        }

        &__message-content {
          order: 1;
          padding: rem(10) rem(10) 0 0;
        }

        &__user-name {
          text-align: right;
        }

        &__time {
          margin-left: 0;
        }
      }
    }

    &--system {
      margin-bottom: 0;
      .message-block {
        justify-content: flex-end;
      }
      .dj-chat {
        &__user-pic,
        &__user-name {
          display: none;
        }

        &__message-content {
          flex: 0 0 100%;
          opacity: .65;
          padding: 0;
        }

        &__message {
          background: transparent;
          text-align: center;
          font-family: "Raleway SemiBoldItalic", sans-serif;
          margin: 0;
          padding: rem(20) 0;
        }

        &__time {
          display: none;
        }
      }
    }
  }

  &__message {
    background: #fff;
    border-radius: 7px;
    padding: rem(20);
    margin: rem(15) 0 rem(5);
    line-height: 1.35;
    color: $dj-black-2;
    .dj-chat__message-block--own & {
      background: $bg-dark-gray;
    }
  }

  &__message-content {
    font-size: 14px;
    font-family: 'Raleway SemiBold',sans-serif;
    color: $dj-black-3;
    padding: rem(10) 0 0 rem(10);
  }

  &__time {
    font-size: 12px;
    margin-left: auto;
    font-family: 'Raleway', sans-serif;
  }

  &__textarea {
    height: 80px;
    position: relative;
    padding: 0 45px 0 0;
    background: $bg-dark-gray;
    width: 100%;
    overflow: hidden;
    .sm & {
      border-radius: 0 0 10px 10px;
    }

    textarea {
      border: 0;
      width: 100%;
      height: 100%;
      padding: rem(20);
      resize: none;
      background: transparent;
      font-family: "Raleway Bold", sans-serif;
    }
  }

  &__send {
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
  }

  &__notification {
    .operator-chat & {
      display: flex;
      margin-top: auto;
      font-size: 1rem;
    }
  }

  &__delay {
    margin-top: auto;
    font-size: 1rem;
    text-align: center;
  }
}