/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

*:focus {
  outline: none;
}

body {
  line-height: 1;
}

a {
  text-decoration: none;
}

img {
  vertical-align: middle;
  border-style: none;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea:focus, input:focus {
  outline: none;
}

.multiple.search .delete.icon {
  font-style: normal;
}
.multiple.search .delete.icon:before {
  content: "x";
}

.mx-shortcuts-wrapper .mx-shortcuts.active {
  color: #419dec;
}

.list-circle-inside {
  list-style: circle;
  margin-bottom: 16px;
  padding-left: 40px;
}

button {
  background: transparent;
  border: 0;
}

.modal-open > .modal-backdrop {
  display: block;
}

.modal {
  display: block;
  z-index: 2;
}

.modal-backdrop {
  display: none;
  z-index: 1;
  opacity: 0.5;
}

.dropdown {
  white-space: nowrap;
}

button:focus {
  outline: none;
}

.dj-f10 {
  font-size: 0.7142857143rem;
}

.dj-f11 {
  font-size: 0.7857142857rem;
}

.dj-error-msg, .dj-f12 {
  font-size: 0.8571428571rem;
}

.dj-f13 {
  font-size: 0.9285714286rem;
}

.dj-error, .dj-f14 {
  font-size: 1rem;
}

.dj-f15 {
  font-size: 1.0714285714rem;
}

.dj-f16 {
  font-size: 1.1428571429rem;
}

.dj-f17 {
  font-size: 1.2142857143rem;
}

.dj-f18 {
  font-size: 1.2857142857rem;
}

.dj-f19 {
  font-size: 1.3571428571rem;
}

.dj-f20 {
  font-size: 1.4285714286rem;
}

.dj-f21 {
  font-size: 1.5rem;
}

.dj-f22 {
  font-size: 1.5714285714rem;
}

.dj-f23 {
  font-size: 1.6428571429rem;
}

.dj-f24 {
  font-size: 1.7142857143rem;
}

.dj-f25 {
  font-size: 1.7857142857rem;
}

.dj-f26 {
  font-size: 1.8571428571rem;
}

.dj-f27 {
  font-size: 1.9285714286rem;
}

.dj-f28 {
  font-size: 2rem;
}

.dj-f29 {
  font-size: 2.0714285714rem;
}

.dj-f30 {
  font-size: 2.1428571429rem;
}

strong, b, .dj-fb {
  font-family: "Raleway Bold";
}

i {
  font-family: "Raleway SemiBoldItalic";
}

.dj-tdn {
  text-decoration: none;
}

.dj-tdu {
  text-decoration: underline;
}

.dj-tdu-2 {
  border-bottom: 2px solid #222222;
}

.dj-f-r {
  float: right;
}

.dj-ttu {
  text-transform: uppercase;
}

.dj-tc {
  text-align: center;
}

.dj-ch {
  cursor: pointer;
}

.dj-pos-rel {
  position: relative;
}

.dj-pos-abs {
  position: absolute;
}
.dj-pos-abs--vcenter {
  top: 50%;
  transform: translateY(-50%);
}
.dj-pos-abs--l10 {
  left: 0.7142857143rem;
}

.dj-container-icon {
  color: #f0f0ee;
  position: absolute;
  font-size: 18.2857142857rem;
  left: 0;
  top: 0;
  z-index: -1;
}
.dj-container-icon.dj-container-icon--admin {
  font-size: 15rem;
  opacity: 0.75;
}
.dj-container-icon--reporting {
  left: -9.2857142857rem;
  top: -7.5714285714rem;
}
.dj-container-icon--tracking {
  left: -9.2857142857rem;
  top: -6.5714285714rem;
}
.dj-container-icon--user, .dj-container-icon--admin {
  left: -7.5rem;
  top: -9.6428571429rem;
}
.dj-container-icon--hover:hover [class^=icon-],
.dj-container-icon--hover:hover .dj-link {
  color: #ed9636;
}

.dj-bg-white {
  background-color: #ffffff;
}

.dj-dn {
  display: none;
}

.dj-w100pct {
  width: 100%;
}

.dj-m-1 {
  margin: 1rem !important;
}

.dj-mt-1,
.dj-my-1 {
  margin-top: 1rem !important;
}

.dj-mr-1,
.dj-mx-1 {
  margin-right: 1rem !important;
}

.dj-mb-1,
.dj-my-1 {
  margin-bottom: 1rem !important;
}

.dj-ml-1,
.dj-mx-1 {
  margin-left: 1rem !important;
}

.dj-m-2 {
  margin: 2rem !important;
}

.dj-mt-2,
.dj-my-2 {
  margin-top: 2rem !important;
}

.dj-mr-2,
.dj-mx-2 {
  margin-right: 2rem !important;
}

.dj-mb-2,
.dj-my-2 {
  margin-bottom: 2rem !important;
}

.dj-ml-2,
.dj-mx-2 {
  margin-left: 2rem !important;
}

.dj-m-3 {
  margin: 3rem !important;
}

.dj-mt-3,
.dj-my-3 {
  margin-top: 3rem !important;
}

.dj-mr-3,
.dj-mx-3 {
  margin-right: 3rem !important;
}

.dj-mb-3,
.dj-my-3 {
  margin-bottom: 3rem !important;
}

.dj-ml-3,
.dj-mx-3 {
  margin-left: 3rem !important;
}

.dj-m-4 {
  margin: 4rem !important;
}

.dj-mt-4,
.dj-my-4 {
  margin-top: 4rem !important;
}

.dj-mr-4,
.dj-mx-4 {
  margin-right: 4rem !important;
}

.dj-mb-4,
.dj-my-4 {
  margin-bottom: 4rem !important;
}

.dj-ml-4,
.dj-mx-4 {
  margin-left: 4rem !important;
}

.dj-m-5 {
  margin: 5rem !important;
}

.dj-mt-5,
.dj-my-5 {
  margin-top: 5rem !important;
}

.dj-mr-5,
.dj-mx-5 {
  margin-right: 5rem !important;
}

.dj-mb-5,
.dj-my-5 {
  margin-bottom: 5rem !important;
}

.dj-ml-5,
.dj-mx-5 {
  margin-left: 5rem !important;
}

.dj-m-0 {
  margin: 0 !important;
}

.dj-mt-0,
.dj-my-0 {
  margin-top: 0 !important;
}

.dj-mr-0,
.dj-mx-0 {
  margin-right: 0 !important;
}

.dj-mb-0,
.dj-my-0 {
  margin-bottom: 0 !important;
}

.dj-ml-0,
.dj-mx-0 {
  margin-left: 0 !important;
}

.dj-p-1 {
  padding: 1rem !important;
}

.dj-pt-1,
.dj-py-1 {
  padding-top: 1rem !important;
}

.dj-pr-1,
.dj-px-1 {
  padding-right: 1rem !important;
}

.dj-pb-1,
.dj-py-1 {
  padding-bottom: 1rem !important;
}

.dj-pl-1,
.dj-px-1 {
  padding-left: 1rem !important;
}

.dj-p-2 {
  padding: 2rem !important;
}

.dj-pt-2,
.dj-py-2 {
  padding-top: 2rem !important;
}

.dj-pr-2,
.dj-px-2 {
  padding-right: 2rem !important;
}

.dj-pb-2,
.dj-py-2 {
  padding-bottom: 2rem !important;
}

.dj-pl-2,
.dj-px-2 {
  padding-left: 2rem !important;
}

.dj-p-3 {
  padding: 3rem !important;
}

.dj-pt-3,
.dj-py-3 {
  padding-top: 3rem !important;
}

.dj-pr-3,
.dj-px-3 {
  padding-right: 3rem !important;
}

.dj-pb-3,
.dj-py-3 {
  padding-bottom: 3rem !important;
}

.dj-pl-3,
.dj-px-3 {
  padding-left: 3rem !important;
}

.dj-p-4 {
  padding: 4rem !important;
}

.dj-pt-4,
.dj-py-4 {
  padding-top: 4rem !important;
}

.dj-pr-4,
.dj-px-4 {
  padding-right: 4rem !important;
}

.dj-pb-4,
.dj-py-4 {
  padding-bottom: 4rem !important;
}

.dj-pl-4,
.dj-px-4 {
  padding-left: 4rem !important;
}

.dj-p-5 {
  padding: 5rem !important;
}

.dj-pt-5,
.dj-py-5 {
  padding-top: 5rem !important;
}

.dj-pr-5,
.dj-px-5 {
  padding-right: 5rem !important;
}

.dj-pb-5,
.dj-py-5 {
  padding-bottom: 5rem !important;
}

.dj-pl-5,
.dj-px-5 {
  padding-left: 5rem !important;
}

.dj-p-0 {
  padding: 0 !important;
}

.dj-pt-0,
.dj-py-0 {
  padding-top: 0 !important;
}

.dj-pr-0,
.dj-px-0 {
  padding-right: 0 !important;
}

.dj-pb-0,
.dj-py-0 {
  padding-bottom: 0 !important;
}

.dj-pl-0,
.dj-px-0 {
  padding-left: 0 !important;
}

.dj-mx-a {
  margin-right: auto;
  margin-left: auto;
}

.dj-ml-a {
  margin-left: auto;
}

.dj-my-a {
  margin-top: auto;
  margin-bottom: auto;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.flex-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-self-center {
  align-self: center !important;
}

.flex-item {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.d-flex > .flex-item-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.mt-1 {
  margin-top: 1px;
}

.mb-1 {
  margin-bottom: 1px;
}

.ml-1 {
  margin-left: 1px;
}

.mr-1 {
  margin-right: 1px;
}

.d-flex > .flex-item-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.mt-2 {
  margin-top: 2px;
}

.mb-2 {
  margin-bottom: 2px;
}

.ml-2 {
  margin-left: 2px;
}

.mr-2 {
  margin-right: 2px;
}

.d-flex > .flex-item-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.mt-3 {
  margin-top: 3px;
}

.mb-3 {
  margin-bottom: 3px;
}

.ml-3 {
  margin-left: 3px;
}

.mr-3 {
  margin-right: 3px;
}

.d-flex > .flex-item-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.mt-4 {
  margin-top: 4px;
}

.mb-4 {
  margin-bottom: 4px;
}

.ml-4 {
  margin-left: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.d-flex > .flex-item-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.d-flex > .flex-item-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.mt-6 {
  margin-top: 6px;
}

.mb-6 {
  margin-bottom: 6px;
}

.ml-6 {
  margin-left: 6px;
}

.mr-6 {
  margin-right: 6px;
}

.d-flex > .flex-item-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.mt-7 {
  margin-top: 7px;
}

.mb-7 {
  margin-bottom: 7px;
}

.ml-7 {
  margin-left: 7px;
}

.mr-7 {
  margin-right: 7px;
}

.d-flex > .flex-item-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.mt-8 {
  margin-top: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mr-8 {
  margin-right: 8px;
}

.d-flex > .flex-item-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.mt-9 {
  margin-top: 9px;
}

.mb-9 {
  margin-bottom: 9px;
}

.ml-9 {
  margin-left: 9px;
}

.mr-9 {
  margin-right: 9px;
}

.d-flex > .flex-item-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.d-flex > .flex-item-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.mt-11 {
  margin-top: 11px;
}

.mb-11 {
  margin-bottom: 11px;
}

.ml-11 {
  margin-left: 11px;
}

.mr-11 {
  margin-right: 11px;
}

.d-flex > .flex-item-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.mt-12 {
  margin-top: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.mr-12 {
  margin-right: 12px;
}

.gutter-1 {
  margin: 0 1rem;
}

.dj-container,
.dj-container-full {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0;
  padding-left: 0;
}
.dj-container .dj-grid,
.dj-container-full .dj-grid {
  margin-right: 0;
  margin-left: 0;
}

.dj-grid {
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  position: relative;
  list-style-type: none;
}
.dj-grid::before, .dj-grid::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

[class*=dj-col-] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  vertical-align: top;
  padding: 0;
}
[class*=dj-col-]::before, [class*=dj-col-]::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
[class*=dj-col-] .dj-grid {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: 0;
}

.dj-col-12 {
  width: 100%;
}

.dj-col-11 {
  width: 91.6666666667%;
}

.dj-col-10 {
  width: 83.3333333333%;
}

.dj-col-9 {
  width: 75%;
}

.dj-col-8 {
  width: 66.6666666667%;
}

.dj-col-7 {
  width: 58.3333333333%;
}

.dj-col-6 {
  width: 50%;
}

.dj-col-5 {
  width: 41.6666666667%;
}

.dj-col-4 {
  width: 33.3333333333%;
}

.dj-col-3 {
  width: 25%;
}

.dj-col-2 {
  width: 16.6666666667%;
}

.dj-col-1 {
  width: 8.3333333333%;
}

@media (min-width: 576px) {
  .dj-col-xs-12 {
    width: 100%;
  }
  .dj-col-xs-11 {
    width: 91.6666666667%;
  }
  .dj-col-xs-10 {
    width: 83.3333333333%;
  }
  .dj-col-xs-9 {
    width: 75%;
  }
  .dj-col-xs-8 {
    width: 66.6666666667%;
  }
  .dj-col-xs-7 {
    width: 58.3333333333%;
  }
  .dj-col-xs-6 {
    width: 50%;
  }
  .dj-col-xs-5 {
    width: 41.6666666667%;
  }
  .dj-col-xs-4 {
    width: 33.3333333333%;
  }
  .dj-col-xs-3 {
    width: 25%;
  }
  .dj-col-xs-2 {
    width: 16.6666666667%;
  }
  .dj-col-xs-1 {
    width: 8.3333333333%;
  }
}
@media (min-width: 768px) {
  .dj-col-sm-12 {
    width: 100%;
  }
  .dj-col-sm-11 {
    width: 91.6666666667%;
  }
  .dj-col-sm-10 {
    width: 83.3333333333%;
  }
  .dj-col-sm-9 {
    width: 75%;
  }
  .dj-col-sm-8 {
    width: 66.6666666667%;
  }
  .dj-col-sm-7 {
    width: 58.3333333333%;
  }
  .dj-col-sm-6 {
    width: 50%;
  }
  .dj-col-sm-5 {
    width: 41.6666666667%;
  }
  .dj-col-sm-4 {
    width: 33.3333333333%;
  }
  .dj-col-sm-3 {
    width: 25%;
  }
  .dj-col-sm-2 {
    width: 16.6666666667%;
  }
  .dj-col-sm-1 {
    width: 8.3333333333%;
  }
}
@media (min-width: 992px) {
  .dj-col-md-12 {
    width: 100%;
  }
  .dj-col-md-11 {
    width: 91.6666666667%;
  }
  .dj-col-md-10 {
    width: 83.3333333333%;
  }
  .dj-col-md-9 {
    width: 75%;
  }
  .dj-col-md-8 {
    width: 66.6666666667%;
  }
  .dj-col-md-7 {
    width: 58.3333333333%;
  }
  .dj-col-md-6 {
    width: 50%;
  }
  .dj-col-md-5 {
    width: 41.6666666667%;
  }
  .dj-col-md-4 {
    width: 33.3333333333%;
  }
  .dj-col-md-3 {
    width: 25%;
  }
  .dj-col-md-2 {
    width: 16.6666666667%;
  }
  .dj-col-md-1 {
    width: 8.3333333333%;
  }
}
.dj-col-auto {
  -ms-flex: 1 0 0px;
  -webkit-flex: 1 0 0px;
  flex: 1 0 0px;
}

@media (min-width: 576px) {
  .dj-col-xs-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
  }
}
@media (min-width: 768px) {
  .dj-col-sm-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
  }
}
@media (min-width: 992px) {
  .dj-col-md-auto {
    -ms-flex: 1 0 0px;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
  }
}
.dj-order-12 {
  -ms-flex-order: 12;
  -webkit-order: 12;
  order: 12;
}

.dj-order-11 {
  -ms-flex-order: 11;
  -webkit-order: 11;
  order: 11;
}

.dj-order-10 {
  -ms-flex-order: 10;
  -webkit-order: 10;
  order: 10;
}

.dj-order-9 {
  -ms-flex-order: 9;
  -webkit-order: 9;
  order: 9;
}

.dj-order-8 {
  -ms-flex-order: 8;
  -webkit-order: 8;
  order: 8;
}

.dj-order-7 {
  -ms-flex-order: 7;
  -webkit-order: 7;
  order: 7;
}

.dj-order-6 {
  -ms-flex-order: 6;
  -webkit-order: 6;
  order: 6;
}

.dj-order-5 {
  -ms-flex-order: 5;
  -webkit-order: 5;
  order: 5;
}

.dj-order-4 {
  -ms-flex-order: 4;
  -webkit-order: 4;
  order: 4;
}

.dj-order-3 {
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}

.dj-order-2 {
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}

.dj-order-1 {
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}

.dj-order-0 {
  -ms-flex-order: 0;
  -webkit-order: 0;
  order: 0;
}

.dj-offset-11 {
  margin-left: 91.6666666667%;
}

.dj-offset-10 {
  margin-left: 83.3333333333%;
}

.dj-offset-9 {
  margin-left: 75%;
}

.dj-offset-8 {
  margin-left: 66.6666666667%;
}

.dj-offset-7 {
  margin-left: 58.3333333333%;
}

.dj-offset-6 {
  margin-left: 50%;
}

.dj-offset-5 {
  margin-left: 41.6666666667%;
}

.dj-offset-4 {
  margin-left: 33.3333333333%;
}

.dj-offset-3 {
  margin-left: 25%;
}

.dj-offset-2 {
  margin-left: 16.6666666667%;
}

.dj-offset-1 {
  margin-left: 8.3333333333%;
}

.dj-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.dj-no-wrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.dj-no-wrap [class*=dj-col-] {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}

.dj-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.dj-direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.dj-direction-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.dj-direction-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.dj-direction-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.dj-align-start {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.dj-align-end {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.dj-align-center {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.dj-align-baseline {
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.dj-align-content-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

.dj-align-content-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}
.dj-align-content-end [class*=dj-col-] {
  vertical-align: bottom;
}

.dj-align-content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}

.dj-align-content-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between;
}

.dj-align-content-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around;
}

.dj-align-self-stretch {
  -ms-flex-item-align: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}

.dj-align-self-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.dj-align-self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  vertical-align: bottom;
}

.dj-align-self-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  vertical-align: middle;
}

.dj-align-self-baseline {
  -ms-flex-item-align: baseline;
  -webkit-align-self: baseline;
  align-self: baseline;
  vertical-align: baseline;
}

.dj-justify-start {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.dj-justify-end {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.dj-justify-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.dj-justify-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.dj-justify-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.dj-grid-bleed [class*=dj-col-] {
  padding: 0;
}

.dj-col-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.dj-col-grid.dj-direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.dj-col-bleed {
  padding: 0;
}

.dj-col-bleed-x {
  padding: 0 0;
}

.dj-col-bleed-y {
  padding: 0 0;
}

.dj-flex-img {
  display: block;
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  height: auto;
  width: 100%;
}

.dj-flex-footer {
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
}
.dj-flex-footer > :last-child {
  margin-bottom: 0;
}

.dj-grid-margin-auto > [class*=dj-col-] {
  margin: auto;
}

.dj-col-margin-auto {
  margin: auto;
}

.h-100 {
  height: 100%;
}

.w-10 {
  width: 10rem;
}

.w-14 {
  width: 14rem;
}

.font-md {
  font-size: 1.5rem;
}

.font-2r {
  font-size: 2rem;
}

.font-25 {
  font-size: 1.7857142857rem;
}

.is-invalid {
  border: 2px solid #c11e53 !important;
}

.content-box {
  box-sizing: content-box !important;
}

.material-icons.md-48 {
  font-size: 48px;
}

.font-50 {
  font-size: 50px;
}

.font-100 {
  font-size: 100px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.fz-085 {
  font-size: 0.85rem;
}

.opacity50 {
  opacity: 0.5;
}

.rotate-180 {
  transform: rotate(180deg);
}

.invert {
  filter: invert(1);
}

.text-uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

.text-medium {
  font-family: "Raleway Medium", sans-serif;
}

.text-bold {
  font-family: "Raleway Bold", sans-serif;
}

.text-bold-italic {
  font-family: "Raleway BoldItalic", sans-serif;
}

.text-italic {
  font-style: italic;
}

.text-semibold {
  font-family: "Raleway SemiBold", sans-serif;
}

.font-14 {
  font-size: 14px;
}

.font-default {
  font-size: 1rem;
}

.font-16 {
  font-size: 1.1428571429rem;
  line-height: normal;
}

.font-18 {
  font-size: 1.3rem;
  line-height: normal;
}

.font-20 {
  font-size: 1.4285714286rem;
  line-height: normal;
}

.link--blue {
  color: #128caf;
  text-decoration: none;
}
.link--blue:hover {
  color: #128caf;
}
.link--blue_bordered {
  border-bottom: 1px solid;
}

.blue-tc {
  color: #128caf;
}

.light-blue-tc {
  color: #0c8db8;
}

.green-tc {
  color: #1cb09c;
}

.red-tc {
  color: #c11e53;
}

.gray-tc {
  color: #999;
}

.orange-tc {
  color: #f39819;
}

.font-20-rs {
  font-size: 1.4285714286rem;
}

.flex-grow {
  flex-grow: 1;
}

.d-inline-block {
  display: inline-block;
}

.line-height-md {
  line-height: 1.3rem;
}

.lh-15 {
  line-height: 1.5;
}

.focused {
  border: 2px solid transparent;
}
.focused:focus-within {
  border-color: #f39819;
}

.cursor-pointer {
  cursor: pointer;
}

.p-20 {
  padding: 1.4285714286rem;
}

.mb-20 {
  margin-bottom: 1.4285714286rem;
}

.mt-20 {
  margin-top: 1.4285714286rem;
}

.dj-mb5 {
  margin-bottom: 5px;
}

.dj-mb15 {
  margin-bottom: 1.0714285714rem !important;
}

.tc-2 {
  color: #222222 !important;
}

.tc-3 {
  color: #333333 !important;
}

.dj-underline {
  border-bottom: 1px solid;
}

.dj-break {
  word-break: break-all;
}

.dj-nowrap {
  white-space: nowrap;
}

.no-underline:hover {
  text-decoration: none;
  color: inherit;
}

.dj-pt-sm {
  padding-top: 0.5rem;
}

.link--underline {
  padding-bottom: 3px;
}
.link--underline.tc-white {
  color: #fff;
  border-bottom: 2px solid;
}
.link--underline.tc-black {
  color: #222222;
  border-bottom: 2px solid;
}
.link--underline:hover {
  text-decoration: none;
  color: #f39819;
}

.link--error {
  color: #c11e53;
  border-bottom: 1px solid;
}
.link--error:hover {
  text-decoration: none;
  color: #e48500;
}

.content-block--text {
  font-size: 14px;
  font-family: "Raleway Medium", sans-serif;
  line-height: 1.5rem;
}
.content-block--text p {
  margin: 0;
}

.dj-alert-warning {
  background: #333333;
  border-radius: 10px;
  padding: 1.4285714286rem;
  color: #fff;
  font-family: "Raleway SemiBold", sans-serif;
  display: flex;
  line-height: 1.35;
}

.w-100 {
  width: 100%;
}

.dj-ml-auto {
  margin-left: auto;
}

.dj-py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.orange-hover:hover {
  color: #e48500 !important;
}

.custom-hr {
  margin: 30px 0;
}
.custom-hr:after {
  content: "";
  width: 70%;
  display: block;
  margin: 0 auto;
  height: 1px;
  background: -moz-linear-gradient(left, #f0f0ee 0%, rgb(153, 153, 153) 50%, #f0f0ee 100%);
  background: -webkit-linear-gradient(left, #f0f0ee 0%, rgb(153, 153, 153) 50%, #f0f0ee 100%);
  background: linear-gradient(to right, #f0f0ee 0%, rgb(153, 153, 153) 50%, #f0f0ee 100%);
}

.no-overflow {
  overflow: visible !important;
  overflow-wrap: break-word;
}

.spinner {
  animation: spin 1s linear infinite;
  border: 3px solid #e1e1e0;
  border-top-color: #128caf;
  border-radius: 50%;
  height: 75px;
  width: 75px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.dj-tabs {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 11rem);
  width: calc(100% - 300px);
}
.dj-tabs.full-width {
  width: 100%;
}
.dj-tabs__tab {
  cursor: pointer;
  order: -1;
  width: auto;
  padding: 20px 35px;
  position: relative;
  margin: 0 2px 0 0;
  font-family: "Raleway SemiBold", sans-serif;
  font-size: 14px;
}
.dj-tabs__tab:hover {
  background: rgba(240, 240, 238, 0.5);
}
.dj-tabs__notify {
  position: absolute;
  top: 0.4285714286rem;
  right: 0.4285714286rem;
  width: 0.5714285714rem;
  height: 0.5714285714rem;
  border-radius: 0.5714285714rem;
  background-color: #c11e53;
}
.dj-tabs__content {
  background: #f0f0ee;
  width: 100%;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-out;
}

input[data-flex-tab] {
  display: none;
  visibility: hidden;
}
input[data-flex-tab]:checked + .dj-tabs__tab {
  background: #f0f0ee;
  font-family: "Raleway Bold", sans-serif;
  pointer-events: none;
}
input[data-flex-tab]:checked + .dj-tabs__tab + .dj-tabs__content {
  height: calc(100% - 136px);
  opacity: 1;
  transition: opacity 0.3s ease-in;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  color: #222;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}

.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.dj-modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.dj-modal__container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}
.dj-modal__container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.dj-modal__content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.dj-modal__overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: 0.45;
}

.dj-popup {
  position: relative;
  padding: 30px;
  padding-top: 60px;
  max-width: 500px;
  margin: 20px auto;
  width: 444px;
  height: auto;
  max-height: 95vh;
  overflow: auto;
  border-radius: 10px;
  box-shadow: -0.2px 6px 13px 0 rgba(0, 0, 0, 0.24);
  background-color: #f0f0ee;
}
.dj-popup--lg {
  max-width: 64.2857142857rem;
  width: 57.1428571429rem;
}
.dj-popup--md {
  width: 35.7142857143rem;
}
.dj-popup--pom {
  width: 50rem;
  max-width: none;
}
.dj-popup--overflow .dj-popup__body {
  padding: 0;
  height: 70vh;
  overflow: auto;
}
.dj-popup--overflow .dj-popup__body-inner {
  padding-left: 65px;
  padding-right: 65px;
}
.dj-popup__header {
  text-align: center;
}
.dj-popup__body {
  padding-left: 65px;
  padding-right: 65px;
}
.dj-popup--pom .dj-popup__body {
  padding: 0 45px;
}
.dj-popup__title {
  font-family: "Raleway SemiBold";
  font-size: 1.7857142857rem;
  margin-bottom: 2.8571428571rem;
}
.dj-popup__close {
  position: absolute;
  top: 12px;
  right: 8px;
  cursor: pointer;
}

.dropdown-notification {
  border: 1px solid #96c8da;
  width: auto;
  display: block;
  border-radius: 4px;
  position: absolute;
  background-color: white;
  z-index: 1;
}
.dropdown-notification .dropdown-item {
  display: block;
  width: 100%;
  padding: 0.85rem 1.2rem;
  clear: both;
  font-weight: normal;
  color: #222222;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-notification .dropdown-item:hover {
  cursor: pointer;
  text-decoration: none;
  background-color: #f8f9fa;
}

.dj-select--overflow .selected-value {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dj-form-control {
  display: block;
  width: 100%;
  height: 50px;
  padding: 10px 10px;
  line-height: 50px;
  color: #333333;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 2px solid transparent;
  font-size: 1.1428571429rem;
  font-family: "Raleway SemiBold", sans-serif;
}
.dj-form-control:focus {
  border-color: #f39819;
}
.focused .dj-form-control {
  border: none;
}
.dj-form-control--transparent {
  background: transparent;
}
.dj-form-control--medium {
  height: 36px;
}
.dj-form-control--large {
  height: 3.5714285714rem;
}
.dj-form-control--bordered {
  border: 1px solid #b2b2b2;
}
.dj-form-control--italic::placeholder {
  font-family: "Raleway SemiBoldItalic", sans-serif;
}
.dj-form-control::placeholder {
  color: #b2b2b2;
}
.dj-form-control--fixed-gray {
  background-color: #f0f0ee;
  width: 18.1428571429rem;
}

.dj-form-control-readonly {
  display: block;
  background: #e1e1e0;
  border: 0;
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  font-size: 1rem;
}
.dj-form-control-readonly::placeholder {
  font-style: italic;
}
input[type=text].dj-form-control-readonly {
  height: 40px;
}

textarea.dj-form-control-readonly {
  height: 14.2857142857rem;
}

.dj-textarea {
  display: block;
  width: 100%;
  height: 14.2857142857rem;
  padding: 10px 10px;
  font-size: 1.1428571429rem;
  color: #333333;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 2px solid transparent;
}
.dj-textarea:focus {
  border-color: #f39819;
}
.dj-textarea::placeholder {
  font-family: "Raleway SemiBoldItalic", sans-serif;
  color: #b2b2b2;
}

.dj-select {
  font-family: "Raleway Bold";
  align-items: center;
  display: flex;
  width: 100%;
  height: 50px;
  padding: 10px 10px;
  font-size: 1.1428571429rem;
  line-height: 50px;
  color: #333333;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 2px solid #c4c4c4;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}
.dj-select__arrow {
  margin-left: auto;
  pointer-events: none;
  margin-top: 0.2142857143rem;
}
.dj-select__arrow-open {
  transform: rotate(180deg);
}
.dj-select__placeholder {
  color: #b2b2b2;
}
.dj-select__dropdown {
  position: absolute;
  top: 100%;
  left: -2px;
  background-color: #ffffff;
  width: 100%;
  border: 2px solid #f39819;
  z-index: 1;
  box-sizing: content-box;
  max-height: 21.4285714286rem;
  overflow: auto;
}
.dj-select__dropdown li {
  align-items: center;
  display: flex;
  min-height: 50px;
  padding: 10px 10px;
  white-space: normal;
  line-height: normal;
}
.dj-select__dropdown li:not(:last-child) {
  border-bottom: 2px solid #f39819;
}
.dj-select--dark {
  color: #ffffff;
  background-color: #0a6987;
  border: 2px solid #0a6987;
}
.dj-select--dark .dj-select__dropdown {
  background-color: #0a6987;
  border: 2px solid #0a6987;
}
.dj-select--dark .dj-select__dropdown li {
  position: relative;
}
.dj-select--dark .dj-select__dropdown li:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 5%;
  width: 90%;
  height: 1px;
  background: rgba(240, 240, 238, 0.6);
}
.dj-select--dark .dj-select__dropdown li:not(:last-child) {
  border-bottom: 2px solid #0a6987;
}
.dj-select--tall .dj-select__dropdown {
  max-height: 57.1428571429rem;
}
.dj-select--overflow .selected-value {
  width: 90%;
}
.dj-select--50pct {
  width: 50%;
}
.dj-select--opened:not(.dj-select--dark) {
  border-color: #f39819;
}
.dj-select--disabled {
  opacity: 0.4;
}
.calendar-reporting .dj-select {
  height: 3.3571428571rem;
  width: 25rem;
}

.dj-datepicker.mx-datepicker-range {
  width: 100%;
}
.dj-datepicker .mx-datepicker-popup {
  max-width: 496px;
}
.dj-datepicker .mx-input-wrapper .mx-input {
  display: block;
  width: 100%;
  height: 50px;
  padding: 10px 10px;
  font-size: 1rem;
  line-height: 50px;
  color: #333333;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 0;
}

.dj-search-label {
  align-items: center;
  color: #b2b2b2;
  display: flex;
  font-family: "Raleway Bold";
  font-size: 1.1428571429rem;
  text-transform: uppercase;
}
.dj-search-label i {
  font-size: 1.8571428571rem;
  margin-right: 0.8571428571rem;
}

#app .dj-datepicker-icon.mx-datepicker {
  height: 40px;
  width: 50px;
}
#app .dj-datepicker-icon.mx-datepicker input {
  display: none;
}
#app .dj-datepicker-icon.mx-datepicker .mx-input-append {
  background: transparent;
  width: 42px;
  height: 42px;
  top: -0.2857142857rem;
  right: 0.2857142857rem;
}

input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.dj-user-table {
  width: 100%;
  table-layout: fixed;
}
.dj-user-table thead {
  color: #222222;
  font-family: "Raleway Bold";
  text-transform: uppercase;
  font-width: normal;
}
.dj-user-table tbody {
  background-color: #f0f0ee;
  border-radius: 0.7142857143rem;
  font-family: "Raleway SemiBold";
}
.dj-user-table tbody > tr:not(:last-child) {
  border-bottom: 3px solid #e1e1e0;
}
.dj-user-table th {
  padding: 0.8571428571rem 1rem;
  vertical-align: middle;
  font-weight: normal;
}
.dj-user-table th:first-child {
  padding-left: 2.6428571429rem;
}
.dj-user-table td {
  padding: 1.7857142857rem 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dj-user-table td > p {
  margin: 0;
}
.dj-user-table td.action-cell {
  position: relative;
  padding-right: 50px;
}
.dj-user-table td:first-child {
  position: relative;
  padding-left: 2.6428571429rem;
}
.dj-user-table td:first-child:before {
  content: "\e907";
  font-size: 1.4285714286rem;
  font-family: "Djump-icons";
  position: absolute;
  top: 50%;
  left: 0;
  color: #1cb09c;
  transform: translateY(-50%);
}
.dj-user-table td.dj-user-table--user-inactive:first-child:before {
  color: #c11e53;
}
.dj-user-table td > button {
  float: right;
  vertical-align: middle;
  margin-right: 1.7857142857rem;
}
.dj-user-table td > button.edit-user-btn {
  margin: 0;
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}
.dj-user-table__icon {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: "Raleway Bold";
  text-transform: uppercase;
}
.dj-user-table__icon:hover {
  color: #0c8db8;
}

.dj-btn {
  background: transparent;
  border: 2px solid #0c8db8;
  border-radius: 28px;
  color: #0c8db8;
  font-family: "Raleway Bold";
  padding: 14px 26px;
  text-transform: uppercase;
  white-space: nowrap;
}
.dj-btn:hover, .dj-btn--active {
  color: #ffffff;
  background: #0c8db8;
  cursor: pointer;
}
.dj-btn-pink {
  color: #c11e53;
  border-color: #c11e53;
}
.dj-btn-pink:hover, .dj-btn-pink--active {
  color: #ffffff;
  background: #c11e53;
}
.dj-btn-green {
  color: #1cb09c;
  border-color: #1cb09c;
}
.dj-btn-green:hover, .dj-btn-green--active {
  color: #ffffff;
  background: #1cb09c;
}
.dj-btn-gray {
  color: #999;
  border-color: #999;
}
.dj-btn-gray:hover, .dj-btn-gray--active {
  color: #ffffff;
  background: #999;
}
.dj-btn--transparent {
  background-color: transparent;
  border: none;
  color: #222222;
  display: flex;
  align-items: center;
}
.dj-btn--transparent.tc-white {
  color: #fff;
}
.dj-btn--transparent.tc-white span {
  border-color: #fff;
  color: #fff;
}
.dj-btn--transparent span {
  border-bottom: 2px solid #222222;
  padding-bottom: 3px;
  text-transform: uppercase;
  color: #222222;
}
.dj-btn--transparent span a {
  color: inherit;
}
.dj-btn--transparent span a:hover {
  text-decoration: none;
}
.dj-btn--transparent [class^=icon-] {
  margin-right: 5px;
}
.dj-btn--transparent:hover, .dj-btn--transparent--active {
  background: inherit;
  color: #f39819;
  cursor: pointer;
  text-decoration: none;
}
.dj-btn--transparent:hover span, .dj-btn--transparent--active span {
  color: #f39819;
  border-bottom: 2px solid #f39819;
}
.dj-btn--transparent:hover span a, .dj-btn--transparent--active span a {
  color: #f39819;
}
.dj-btn--bg-white {
  background-color: #ffffff;
}

.dj-btn--bg-white.dj-btn--active {
  color: #ffffff;
  background: #0c8db8;
  cursor: pointer;
}

.dj-btn--orange, .btn--orange {
  color: #222222;
  font-family: "Raleway Bold", sans-serif;
  font-size: 1rem;
  line-height: 3.5714285714rem;
  padding: 0 3.2142857143rem;
  border-radius: 25px;
  background: #f39819;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
}
.dj-btn--orange:hover, .btn--orange:hover {
  background: #e48500;
  color: #222222;
  text-decoration: none;
}
.dj-btn--orange:disabled, .btn--orange:disabled {
  background: #b2b2b2;
  cursor: default;
}
.dj-btn--orange.btn--orange-md, .btn--orange.btn--orange-md {
  min-width: 14.2857142857rem;
}
.dj-btn--orange.btn--orange-lg, .btn--orange.btn--orange-lg {
  min-width: 17.8571428571rem;
}
.dj-btn--orange.btn--orange-sm, .btn--orange.btn--orange-sm {
  padding: 0 2.1428571429rem;
}
.dj-btn--orange.btn--orange-outline, .btn--orange.btn--orange-outline {
  background: transparent;
  line-height: 3.2857142857rem;
  border: 2px solid #f39819;
}
.dj-btn--orange.btn--orange-outline:hover, .btn--orange.btn--orange-outline:hover {
  background: transparent;
}
.dj-btn--orange-user, .btn--orange-user {
  padding-left: 43px;
  padding-right: 20px;
}

.btn--white {
  color: #128caf;
  font-family: "Raleway Bold", sans-serif;
  font-size: 14px;
  line-height: 45px;
  padding: 0 30px;
  border-radius: 25px;
  background: #fff;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
}
.btn--white:disabled {
  background: #b2b2b2;
  cursor: default;
}

.dj-btn--square {
  display: flex;
  justify-content: center;
  border-radius: 0.7142857143rem;
  border: 2px solid #e48500;
  color: #222222;
  font-family: "Raleway Bold", sans-serif;
  font-size: 1.1428571429rem;
  line-height: 3.5714285714rem;
  padding: 0 3.2142857143rem;
  background: #f0f0ee;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  width: 18.2142857143rem;
}
.dj-btn--square:hover, .dj-btn--square--active {
  background: #e48500;
  color: #222222;
  text-decoration: none;
  cursor: pointer;
}
.dj-btn--square:disabled {
  background: #b2b2b2;
}
.dj-btn--square.chat-rating-green {
  border-color: #1cb09c;
  color: #1cb09c;
  background-color: #fff;
}
.dj-btn--square.chat-rating-green:hover {
  background-color: #1cb09c;
  color: #fff;
}
.dj-btn--square.chat-rating-orange {
  border-color: #e48500;
  color: #e48500;
  background-color: #fff;
}
.dj-btn--square.chat-rating-orange:hover {
  background-color: #e48500;
  color: #fff;
}
.dj-btn--square.chat-rating-red {
  border-color: #dc3545;
  color: #dc3545;
  background-color: #fff;
}
.dj-btn--square.chat-rating-red:hover {
  background-color: #dc3545;
  color: #fff;
}

.add-icon {
  border: 2px solid #ed9636;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  font-size: 0;
}
.add-icon.rotated {
  transform: rotate(45deg);
}

.btn--back {
  display: inline-flex;
  align-items: center;
  color: #222222;
  font-family: "Raleway Bold", sans-serif;
  border-bottom: 2px solid #222222;
  margin-bottom: 1.4285714286rem;
  text-transform: uppercase;
}
.btn--back:hover {
  color: #e48500;
  border-bottom: 2px solid #e48500;
  text-decoration: none;
}

.dj-checkbox-checked {
  position: relative;
}
.dj-checkbox-checked .icon-check {
  position: absolute;
  left: -0.2142857143rem;
  top: -0.2857142857rem;
  font-size: 2.1428571429rem;
}

.btn--sticky-1 {
  display: flex;
  align-items: center;
  border: 2px solid #e48500;
  position: fixed;
  right: -2px;
  top: 12.1428571429rem;
  background: #f0f0ee;
  border-radius: 35px 0 0 35px;
  padding: 1.4285714286rem;
  color: #333333;
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  cursor: pointer;
  width: 15rem;
}

.btn--sticky-2 {
  display: flex;
  align-items: center;
  position: fixed;
  right: -2px;
  top: 7.8571428571rem;
  background: #f39819;
  border-radius: 35px 0 0 35px;
  padding: 1.4285714286rem;
  color: #333333;
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  cursor: pointer;
  width: 15rem;
}
.btn--sticky-2:hover {
  background: #e48500;
}

.tooltip {
  display: block !important;
  z-index: 10000;
  box-shadow: -0.2px 6px 13px 0 rgba(0, 0, 0, 0.24);
  font-family: "Raleway SemiBold", sans-serif;
  font-size: 14px;
  border: solid 2px #f0f0ee !important;
  border-radius: 16px;
}
.tooltip .tooltip-inner {
  max-width: 18rem;
  background: white;
  color: black;
  border-radius: 16px;
  padding: 20px;
  text-align: left;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  position: absolute;
  margin: 14px;
  border: 1px solid white;
  z-index: 1;
}
.tooltip[x-placement^=top] {
  margin-bottom: 14px;
}
.tooltip[x-placement^=top] .tooltip-arrow {
  border-width: 14px 14px 0 14px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -14px;
  left: calc(50% - 14px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^=bottom] {
  margin-top: 14px;
}
.tooltip[x-placement^=bottom] .tooltip-arrow {
  border-width: 0 14px 14px 14px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -14px;
  left: calc(50% - 14px);
  margin-top: 0;
  margin-bottom: 0;
}
.tooltip[x-placement^=right] {
  margin-left: 14px;
}
.tooltip[x-placement^=right] .tooltip-arrow {
  border-width: 14px 14px 14px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -14px;
  top: calc(50% - 14px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[x-placement^=left] {
  margin-right: 14px;
}
.tooltip[x-placement^=left] .tooltip-arrow {
  border-width: 14px 0 14px 14px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -14px;
  top: calc(50% - 14px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip.popover {
  max-width: 21.4285714286rem;
  border-color: transparent;
}
.tooltip[aria-hidden=true] {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.15s, visibility 0.15s;
  -o-transition: opacity 0.15s, visibility 0.15s;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden=false] {
  visibility: visible;
  opacity: 1;
  -webkit-transition: opacity 0.15s;
  -o-transition: opacity 0.15s;
  transition: opacity 0.15s;
}

.dj-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dj-pagination__prev {
  align-items: center;
  background-color: transparent;
  display: flex;
  font-family: "Raleway Bold";
  height: 2.8571428571rem;
  justify-content: center;
  margin-right: 0.5714285714rem;
  width: 2.8571428571rem;
}
.dj-pagination__prev i {
  transform: rotate(90deg);
}
.dj-pagination__next {
  align-items: center;
  background-color: transparent;
  display: flex;
  font-family: "Raleway Bold";
  height: 2.8571428571rem;
  justify-content: center;
  width: 2.8571428571rem;
}
.dj-pagination__next i {
  transform: rotate(-90deg);
}
.dj-pagination__page {
  align-items: center;
  background-color: #f0f0ee;
  border: 2px solid #ed9636;
  border-radius: 0.7142857143rem;
  display: flex;
  font-family: "Raleway Bold";
  height: 2.8571428571rem;
  justify-content: center;
  margin-right: 0.5714285714rem;
  width: 2.8571428571rem;
}
.dj-pagination__gap {
  align-items: center;
  border: 2px solid transparent;
  border-radius: 0.7142857143rem;
  display: flex;
  font-family: "Raleway Bold";
  height: 2.8571428571rem;
  justify-content: center;
  margin-right: 0.5714285714rem;
  width: 1.1428571429rem;
}
.dj-pagination__page--active {
  background-color: #ed9636;
}
.dj-pagination--disabled {
  pointer-events: none;
  color: #b2b2b2;
  cursor: not-allowed;
}
.dj-pagination--disabled i {
  color: #b2b2b2;
}
.dj-pagination a {
  cursor: pointer;
}
.dj-pagination i {
  margin-right: 0;
}

.block-gray {
  background-color: #f0f0ee;
  border-radius: 10px;
  display: block;
  color: inherit;
}
.home-block-item .block-gray {
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
.home-block-item .block-gray:hover:not(.items-list) {
  color: inherit;
  text-decoration: none;
}
.home-block-item .block-gray:hover:not(.items-list) [class^=icon-] {
  opacity: 0.25;
}
.home-block-item .block-gray span {
  position: relative;
  z-index: 1;
}
.block-gray:not(.borderless) {
  border: 2px solid #ed9636;
}
.block-gray.border-dashed {
  border-style: dashed;
}
.block-gray:not(.custom-indent):not(.items-list) {
  padding: 2.1428571429rem;
}
.block-gray.with-title {
  display: block;
}
.block-gray.with-title .title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 2px solid #ed9636;
  height: 4.2857142857rem;
  width: 100%;
  position: relative;
  padding: 0 2.8571428571rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "Raleway Bold", sans-serif;
}
.block-gray.with-title .title:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1.4285714286rem;
  background: #ed9636;
  border-top-left-radius: 10px;
}
.block-gray.with-title .content {
  padding: 2.1428571429rem;
  width: 100%;
  height: calc(100% - 4.28571rem);
}
.block-gray.with-title .content.number {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ed9636;
  font-size: 4.6428571429rem;
  font-family: "Raleway SemiBold", sans-serif;
}
.block-gray.hover:hover {
  background: #f39819;
  box-shadow: 2.3px 2px 7px 0 rgba(0, 0, 0, 0.21);
  cursor: pointer;
}
.block-gray.quotation-base {
  padding: 2.8571428571rem 2.8571428571rem 1.4285714286rem;
}
.block-gray.mb-sm {
  margin-bottom: 1.2142857143rem;
}
.block-gray.items-list {
  padding: 1.4285714286rem;
  flex-direction: column;
  align-items: self-start;
  text-transform: none;
  font-family: "Raleway SemiBold", sans-serif;
  justify-content: flex-start;
}
.block-gray.items-list .title {
  align-self: center;
  text-transform: uppercase;
  margin-bottom: 0.7142857143rem;
}
.block-gray.items-list .content {
  flex-direction: column;
  height: 14.2857142857rem;
  justify-content: space-between;
}
.block-gray.items-list .content a {
  padding: 0.7142857143rem 0;
}
@media (max-width: 1680px) {
  .block-gray.items-list .content a {
    padding: 0;
  }
}
.block-gray.items-list .no-items {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.block-gray.items-list a {
  color: #222222;
}

.dj-anchors__header {
  background: #f0f0ee;
  border-radius: 10px;
  padding: 3.2142857143rem 5.3571428571rem;
}
.dj-anchors__header li {
  font-family: "Raleway SemiBold", sans-serif;
  font-size: 16px;
  color: #333333;
  position: relative;
  padding-left: 20px;
}
.dj-anchors__header li:not(:last-child) {
  margin-bottom: 1.0714285714rem;
}
.dj-anchors__header li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  width: 10px;
  height: 10px;
  background: #f39819;
  border-radius: 50%;
}
.dj-anchors__header li a {
  color: inherit;
  line-height: 1.5rem;
}
.dj-anchors__header li a:hover {
  color: inherit;
  text-decoration: none;
  border-bottom: 2px solid #f39819;
}
.dj-anchors__content {
  padding: 0 3.2142857143rem 0 5.3571428571rem;
  color: #333333;
}
.dj-anchors__content-block {
  padding-bottom: 2rem;
}
.dj-anchors__content-block > * {
  font-size: 14px;
  font-family: "Raleway Medium", sans-serif;
  line-height: 1.5rem;
  margin: 0;
}
.dj-anchors__content-block > *:not(:last-child):not(h5):not(.subtitle) {
  margin-bottom: 2rem;
}
.dj-anchors__content-block > h5 {
  margin: 1.0714285714rem 0;
  font-family: "Raleway Bold", sans-serif;
  font-size: 16px;
}
.dj-anchors__content-block > .subtitle {
  margin: 1.0714285714rem 0;
  font-family: "Raleway SemiBold", sans-serif;
}
.dj-anchors__content-block ul li {
  position: relative;
  padding-left: 25px;
}
.dj-anchors__content-block ul li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 5px;
  width: 10px;
  height: 10px;
  background: #333333;
  border-radius: 50%;
}
.dj-anchors__content-block ul li:not(:last-child) {
  margin-bottom: 1.4285714286rem;
}

.blue-list-indent {
  margin-bottom: 1rem;
  display: flex;
}
.blue-list-indent .blue-block {
  color: #0c8db8;
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  width: 100px;
  margin-right: 14px;
}
.blue-list-indent .cont-block {
  font-family: "Raleway SemiBoldItalic", sans-serif;
  width: calc(100% - 114px);
}

html {
  font-size: 14px;
}

body {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  color: #222;
}

aside {
  width: 15rem;
  background: #f0f0ee;
  box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.11);
}

#app.with-aside {
  width: calc(100% - 14rem);
}

.logo {
  padding: 10px;
  width: 100%;
}

.navigation {
  padding-top: 2.1428571429rem;
}
.navigation .nav-item {
  display: flex;
  height: 5rem;
  position: relative;
  align-items: center;
}
.navigation .nav-item.active:before, .navigation .nav-item:hover:before {
  content: "\e907";
  font-size: 5.7142857143rem;
  font-family: "Djump-icons";
  position: absolute;
  top: 3px;
  left: -5px;
  color: #c11e53;
}
.navigation .nav-item.active .nav-link, .navigation .nav-item:hover .nav-link {
  color: #c11e53;
}
.navigation .nav-item.active [class^=icon-], .navigation .nav-item:hover [class^=icon-] {
  color: #fff;
}
.navigation .nav-item.active .icon-custom-tower, .navigation .nav-item:hover .icon-custom-tower {
  filter: invert(100%);
}
.navigation .nav-item.active:before, .navigation .nav-item:hover:before {
  color: #1cb09c;
}
.navigation .nav-item.active .nav-link, .navigation .nav-item:hover .nav-link {
  color: #1cb09c;
}
.navigation .nav-select {
  display: flex;
  height: 5rem;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding: 0.7142857143rem;
  margin-bottom: 2.1428571429rem;
  margin-top: -1.4285714286rem;
}
.navigation .nav-select .header-select {
  width: 100%;
}
.navigation .nav-link {
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "Raleway ExtraBold", sans-serif;
  color: #222;
  display: flex;
  align-items: center;
  padding: 0.7142857143rem 0 0;
  line-height: normal;
}
.navigation .nav-link [class^=icon-] {
  position: relative;
  display: inline-block;
  font-size: 2.4285714286rem;
  margin-top: -0.7142857143rem;
}

.secondary-links {
  position: relative;
  margin: 1.4285714286rem 0 0;
  padding: 1.4285714286rem 0 0;
}
.secondary-links:before {
  content: "";
  width: calc(100% - 3rem);
  height: 2px;
  background: #b2b2b2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.secondary-links li {
  padding: 0.7142857143rem 1.4285714286rem;
}
.secondary-links a {
  font-family: "Raleway BoldItalic", sans-serif;
  font-size: 1.1428571429rem;
  color: #b2b2b2;
}
.secondary-links a:hover {
  text-decoration: none;
  color: #f39819;
}

.main-header {
  background: #128caf;
  padding: 0 2.8571428571rem;
  height: 74px;
  font-size: 1.1428571429rem;
}
.main-header .header-inner,
.main-header [class^=icon-] {
  color: #fff;
}
.main-header .dropdown-inner {
  display: none;
  background: #128caf;
}
.main-header .dropdown-inner.show {
  display: block;
  position: absolute;
  z-index: 11;
  top: 100%;
  left: 0;
  min-width: 5.7142857143rem;
}
.main-header .dropdown-inner.user {
  width: 28.5714285714rem;
}
.main-header .dropdown-inner.user .dropdown-item {
  line-height: 3.5714285714rem;
}
.main-header .dropdown-inner .dropdown-item {
  padding: 1rem 1.4285714286rem 1rem 1.9285714286rem;
  font-family: "Raleway Bold", sans-serif;
  font-size: 1.1428571429rem;
  line-height: 1.4285714286rem;
  color: #fff;
  background: transparent;
}
.main-header .dropdown-inner .dropdown-item:not(:last-child) {
  border-bottom: 2px solid rgba(13, 95, 119, 0.35);
}
.main-header .dropdown-inner a.dropdown-item:hover {
  color: #094b5e;
  cursor: pointer;
}
.main-header .dropdown-inner a.dropdown-item:hover [class^=icon-] {
  color: #094b5e;
}
.main-header .dropdown-inner .header-select-label {
  width: 7.1428571429rem;
}
.main-header .dropdown-inner .header-select {
  flex-grow: 1;
  max-width: 18.0714285714rem;
}
.main-header .dropdown-inner .header-select .dj-select__dropdown li:hover span {
  border-color: #fff;
}
.main-header .dropdown-inner .header-select .dj-select__dropdown li span {
  padding-top: 4px;
  border-bottom: 2px solid transparent;
}
.main-header .user-pic {
  border-radius: 50%;
}
.main-header .user-name {
  font-family: "Raleway SemiBold", sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 28.5714285714rem;
}

.search-block {
  width: 40%;
}
.search-block input {
  background: transparent;
  border: 0;
  color: #fff;
  font-size: 1.1428571429rem;
  font-family: "Raleway Bold", sans-serif;
  width: 80%;
}
.search-block input::placeholder {
  color: #fff;
}

body:not(.modal-open) .main-header {
  z-index: 1;
}
body.menu-open {
  overflow: hidden;
}
body.menu-open .main-header {
  position: fixed;
  width: 100%;
}
body.with-iframe {
  overflow: hidden;
}
body.with-iframe #content {
  padding: 0;
}
body.with-iframe aside {
  position: relative;
  z-index: 1;
}

.nav-link {
  cursor: pointer;
  padding: 0;
}

.icons-nav {
  width: 60%;
}
.icons-nav > .nav-item {
  font-family: "Raleway Bold", sans-serif;
  height: 100%;
  margin: 0;
}
.icons-nav > .nav-item:not(.user-block) {
  width: 5.7142857143rem;
}
.icons-nav > .nav-item.user-block .nav-link {
  padding: 0 2rem;
}
.icons-nav > .nav-item.sm-block {
  width: 5rem;
}
.icons-nav > .nav-item > .nav-link {
  height: 100%;
  border-right: 2px solid rgba(13, 95, 119, 0.35);
  border-bottom: 2px solid transparent;
}
.icons-nav > .nav-item > .nav-link.opened {
  border-bottom-color: rgba(13, 95, 119, 0.35);
  background: #094b5e;
}

#content {
  min-height: calc(100vh - 74px);
  padding: 2.8571428571rem;
}

.table-fixed {
  table-layout: fixed;
}

.error-msg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.mobile-menu-icon {
  width: 34px;
  height: 22px;
  position: relative;
  cursor: pointer;
}
.mobile-menu-icon span {
  position: absolute;
  height: 3px;
  width: 34px;
  background: #fff;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}
.mobile-menu-icon span:nth-child(1) {
  top: 0;
}
.mobile-menu-icon span:nth-child(2) {
  top: 9px;
}
.mobile-menu-icon span:nth-child(3) {
  top: 18px;
}
.menu-open .mobile-menu-icon span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 3px;
}
.menu-open .mobile-menu-icon span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.menu-open .mobile-menu-icon span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 20px;
  left: 3px;
}

.custom-checkbox {
  display: none;
}
.custom-checkbox + label {
  cursor: pointer;
}
.custom-checkbox + label:before {
  font-family: "Material Icons";
  content: "\e835";
  display: inline-block;
  vertical-align: middle;
  font-size: 30px;
}
.custom-checkbox:checked + label:before {
  content: "\e834";
  color: #c11e53;
}

.custom-checkbox-icon {
  display: none;
}
*:not(.disabled-clear-bg) > .custom-checkbox-icon:disabled + label {
  cursor: default;
}
*:not(.disabled-clear-bg) > .custom-checkbox-icon:disabled + label:before {
  content: "\e943";
  color: #c4c4c4;
  font-size: 2.1428571429rem;
  left: -0.0714285714rem;
  top: -0.4285714286rem;
}
*:not(.disabled-clear-bg) > .custom-checkbox-icon:disabled + label span {
  color: #c4c4c4;
}
.disabled-clear-bg .custom-checkbox-icon:disabled + label {
  cursor: default;
}
.disabled-clear-bg .custom-checkbox-icon:disabled + label:before, .disabled-clear-bg .custom-checkbox-icon:disabled + label:after {
  opacity: 0.5;
}
.custom-checkbox-icon + label {
  cursor: pointer;
  position: relative;
  padding-left: 3.5714285714rem;
}
.custom-checkbox-icon + label:before {
  font-family: "Djump-icons" !important;
  content: "\e940";
  font-size: 1.7857142857rem;
  color: #f39819;
  position: absolute;
  left: 0;
  top: -0.3571428571rem;
}
.custom-checkbox-icon:checked + label:after {
  content: "\e914";
  font-family: "Djump-icons" !important;
  color: #f39819;
  position: absolute;
  left: -0.2142857143rem;
  top: -0.5rem;
  font-size: 2.5rem;
}

.custom-input {
  cursor: pointer;
}
.custom-input input {
  display: none;
}
.custom-input input:checked + i {
  color: #c11e53;
}
.custom-input input:checked + svg {
  fill: #c11e53;
}
.custom-input input:checked + .badge {
  opacity: 1;
}
.custom-input input:not(:checked) + .badge {
  background: #000;
  color: #fff;
}
.custom-input i {
  -webkit-transition: color 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out;
}
.custom-input .badge {
  opacity: 0.5;
}

.input-group-disabled {
  background: #e9ecef;
}

#app .mx-datepicker:not(.dj-datepicker-icon) {
  width: 100%;
}
#app .mx-datepicker .mx-input {
  border: 0;
  padding-left: 0;
  font-family: "Raleway SemiBold", sans-serif;
  font-size: 1.1428571429rem;
  color: #333;
  height: 3.2857142857rem;
}
#app .mx-datepicker .mx-input-append {
  cursor: pointer;
  top: 0;
}
#app .mx-datepicker .mx-input-append:after {
  content: "\e908";
  font-family: "Djump-icons";
  font-size: 2.5rem;
  color: #128caf;
  font-weight: normal;
}
#app .mx-datepicker .mx-calendar-icon {
  display: none;
}
#app .mx-datepicker-popup {
  border-color: #f0f0ee;
  border-radius: 10px;
}

.date-picker-full .mx-datepicker {
  width: 100%;
}
.mx-datepicker .mx-input {
  border-color: #ced4da;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 38px;
}
.calendar-customized .mx-datepicker .mx-input {
  background-color: #f0f0ee;
}
.calendar-customized .mx-datepicker .mx-input-append {
  background-color: #f0f0ee;
}
.calendar-reporting .mx-datepicker .mx-input-append:after {
  line-height: 2.6428571429rem;
}

.fade-enter-active, .fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.additional-emails {
  left: auto;
  right: 0;
  padding: 18px;
}
.additional-emails ul {
  margin-top: 12px;
  margin-bottom: 12px;
}
.additional-emails li {
  font-size: 16px;
}
.additional-emails a {
  align-items: center;
  border-radius: 50%;
  background-color: #f0f0ee;
  display: inline-flex;
  height: 16px;
  justify-content: center;
  width: 16px;
}

.alt-loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  z-index: 2;
}

.loop {
  animation: spin 2s linear infinite;
}

.link--bordered {
  display: inline-block;
  border-bottom: 2px solid #222;
  line-height: 1.4285714286rem;
  color: #222222;
}
.link--bordered:hover, .link--bordered.active {
  cursor: pointer;
  color: #f39819;
  border-color: #f39819;
  text-decoration: none;
}
.link--bordered:not(.lowercase) {
  text-transform: uppercase;
}
.link--bordered:not(.sm) {
  font-family: "Raleway Bold", sans-serif;
  line-height: 1.7142857143rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dj-error {
  color: #c11e53;
}

.dj-error-msg {
  color: #c11e53;
  margin-top: -1rem;
  margin-bottom: 1rem;
}
.dj-error-msg--line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dj-error-msg--top-indent {
  margin-top: -0.8571428571rem;
  line-height: 1rem;
}
.dj-error-msg--offset1 {
  margin-top: -1rem;
  margin-bottom: 1rem;
}
.dj-error-msg--offset2 {
  margin-top: -2rem;
  margin-bottom: 2rem;
}
.dj-error-msg--offset3 {
  margin-top: -3rem;
  margin-bottom: 3rem;
}

.dj-invalid, .dj-form-control--invalid {
  border: 2px solid #c11e53;
}

.logo-aside {
  display: block;
  padding-top: 10px;
}

.mobile-info {
  display: flex;
  height: calc(100vh - 135px);
  align-items: center;
  justify-content: center;
  font: 1.5rem "Raleway SemiBold";
  text-align: center;
  opacity: 0.7;
}

.carnet img {
  width: auto;
  height: 800px;
}

.jours img {
  width: 100%;
  height: auto;
}

.dimensions img {
  width: 75%;
  height: auto;
}

.terms-content {
  font-family: "Raleway SemiBold", sans-serif;
  line-height: 1.5;
}

.hidden {
  display: none !important;
}

.pom-actions {
  display: flex;
  justify-content: space-between;
  padding: 4rem 0 2rem;
}

.icon-arrow-menu {
  font-size: 2.2857142857rem;
  margin-right: -0.7142857143rem;
}

.icon-arrow-menu-up {
  font-size: 2.2857142857rem;
}

.search-block .icon-search {
  font-size: 2.4285714286rem;
  vertical-align: middle;
  margin-right: 0.7142857143rem;
}
.borderless-search .icon-search {
  color: #b2b2b2;
  font-size: 2.4285714286rem;
  vertical-align: middle;
  margin-left: 0.7142857143rem;
}
.dj-tr-fold__location .icon-search {
  font-size: 2.3571428571rem;
}
.dj-tr-additional .icon-search, .dj-tr-fold__c-name .icon-search {
  font-size: 2.2857142857rem;
}

.notifications-icon-header .icon-notification {
  font-size: 2.3571428571rem;
  position: relative;
}
.notifications-icon-header .icon-notification.notify span {
  display: inline-block;
  font-family: "Raleway Bold", sans-serif;
  top: 0.2142857143rem;
  left: 1.4285714286rem;
  position: absolute;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.71429rem;
  background: #c11e53;
  padding: 0.1428571429rem 0.2142857143rem;
  border-radius: 25px;
  min-width: 1.0714285714rem;
  text-align: center;
  min-height: 1.0714285714rem;
}
.dj-tr-options .icon-notification {
  font-size: 2.8571428571rem;
}

.home-block-item .icon-cotation,
.home-block-item .icon-tracking,
.home-block-item .icon-reporting,
.home-block-item .icon-gestion {
  position: absolute;
  color: #fff;
  opacity: 0.75;
}

.home-block-item .icon-cotation {
  right: -1.7857142857rem;
  bottom: -1.7857142857rem;
  font-size: 8.2142857143rem;
}
.quotation-icon-block .icon-cotation {
  position: absolute;
  color: #f0f0ee;
  font-size: 10.7142857143rem;
  left: -6.4285714286rem;
  top: -6.0714285714rem;
  z-index: -1;
}

.home-block-item .icon-tracking {
  right: -3.9285714286rem;
  bottom: -3.2142857143rem;
  font-size: 10.7142857143rem;
}
.dj-tr-fold__location .icon-tracking {
  font-size: 2.3571428571rem;
}
.navigation .nav-link-tracking .icon-tracking {
  font-size: 2.5rem;
  margin-left: 0.0714285714rem;
}

.home-block-item .icon-reporting {
  right: -4.2857142857rem;
  bottom: -4.2857142857rem;
  font-size: 11.7857142857rem;
}
.navigation .nav-link-reporting .icon-reporting {
  font-size: 2.5714285714rem;
  margin-left: -0.0714285714rem;
}

.add-icon .icon-plus {
  font-size: 33px;
  color: #128caf;
  position: absolute;
  top: -4.5px;
  left: -5px;
}
.dj-status-icon--first .icon-plus {
  color: #c11e53;
  transform: rotate(135deg);
  font-size: 2rem;
}

.button-row .icon-add,
.button-row .icon-remove {
  font-size: 2.3571428571rem;
  color: #129ecd;
}
:disabled .icon-add,
:disabled .icon-remove {
  color: #c4c4c4;
}

.btn--sticky-1 .icon-document {
  color: #333333;
  font-size: 1.6428571429rem;
  margin-right: 0.7142857143rem;
}
.navigation .nav-link-document .icon-document {
  font-size: 2.4285714286rem;
  margin-left: 0.3571428571rem;
}

.btn--sticky-2 .icon-add {
  color: #333333;
  font-size: 2.1428571429rem;
  margin-right: 0.7142857143rem;
}
.btn--orange-user .icon-add {
  font-size: 26px;
}

.type-img-container .icon-avion {
  font-size: 2.5714285714rem;
  padding-top: 0.7857142857rem;
  padding-left: 0.5714285714rem;
}
.type-col .icon-avion {
  font-size: 2.8571428571rem;
}
@media (max-width: 1680px) {
  .type-col .icon-avion {
    font-size: 1.6428571429rem;
  }
}

.type-img-container .icon-bateau {
  font-size: 2.5714285714rem;
  padding-top: 0.7857142857rem;
  padding-left: 0.2857142857rem;
}
.type-col .icon-bateau {
  font-size: 2.8571428571rem;
}
@media (max-width: 1680px) {
  .type-col .icon-bateau {
    font-size: 2.1428571429rem;
  }
}

.type-img-container .icon-camion,
.type-img-container .icon-elevateur {
  font-size: 2.5714285714rem;
  padding-top: 0.7142857143rem;
  padding-left: 0.4285714286rem;
}
.type-col .icon-camion,
.type-col .icon-elevateur {
  font-size: 2.8571428571rem;
}
@media (max-width: 1680px) {
  .type-col .icon-camion,
.type-col .icon-elevateur {
    font-size: 2.1428571429rem;
  }
}

.type-col .icon-camion {
  font-size: 2.8571428571rem;
}
@media (max-width: 1680px) {
  .type-col .icon-camion {
    font-size: 2.1428571429rem;
  }
}

.type-img-container .icon-douane {
  font-size: 2.7142857143rem;
  padding-top: 0.5714285714rem;
  padding-left: 0.2142857143rem;
}
.type-col .icon-douane {
  font-size: 2.8571428571rem;
}
@media (max-width: 1680px) {
  .type-col .icon-douane {
    font-size: 2.1428571429rem;
  }
}

.dropdown-item .icon-disconnect {
  font-size: 1.6428571429rem;
  display: inline-block;
  vertical-align: top;
  margin-right: 1.2142857143rem;
  margin-top: 0.8571428571rem;
}

.contact-button-round .icon-chatbot {
  color: #fff;
  font-size: 2.8571428571rem;
  display: inline-block;
}
.dj-chat-header .icon-chatbot {
  font-size: 4.2857142857rem;
}
.chat-history-icon-header .icon-chatbot {
  font-size: 2rem;
}

.status-col .icon-forme {
  font-size: 2.1428571429rem;
}
@media (max-width: 1680px) {
  .status-col .icon-forme {
    font-size: 1.4285714286rem;
  }
}

.dj-tr-status .icon-time {
  font-size: 2.4285714286rem;
}
.dj-status-icon--first .icon-time {
  color: #0c8db8;
}

.dj-tr-additional .icon-mail {
  font-size: 1.7142857143rem;
}
.history-action .icon-mail {
  font-size: 1.2857142857rem;
  margin-right: 0.5rem;
}

.dj-tr-additional .icon-document, .dj-tr-fold__c-name .icon-document {
  font-size: 2.2857142857rem;
}

.dj-tr-additional .icon-aide {
  font-size: 2.1428571429rem;
}
.options-grid .icon-aide {
  font-size: 1.4285714286rem;
}
.options-grid .icon-aide:hover {
  color: #f39819;
}

.dj-tr-fold__control .icon-close {
  font-size: 2.8571428571rem;
  color: #e1e1e0;
}
.dj-popup__close .icon-close {
  color: #129ecd !important;
  font-size: 2.5714285714rem;
}
.dj-chat-header .icon-close {
  color: #fff;
  font-size: 2.8571428571rem;
}

.icon-arrow-menu-up:before {
  content: "\e906";
  display: block;
  transform: rotate(180deg);
}

.dj-chat__send .icon-reply {
  font-size: 2.1428571429rem;
}
.btn--orange .icon-reply {
  font-size: 2.1428571429rem;
}

.edit-user-btn .icon-refresh {
  font-size: 2.1428571429rem;
}

.dj-chat__send .icon-paperplane {
  font-size: 2.1428571429rem;
}

.edit-user-btn .icon-modification {
  font-size: 2.1428571429rem;
}
.reporting-graph .icon-modification {
  font-size: 2.1428571429rem;
}
.dj-btn--transparent .icon-modification {
  font-size: 2.1428571429rem;
}

.navigation .nav-link-gestion .icon-gestion {
  font-size: 3.1428571429rem;
  margin-left: -0.1428571429rem;
}
.home-block-item .icon-gestion {
  right: -4.6428571429rem;
  bottom: -7.1428571429rem;
  font-size: 17.8571428571rem;
}
.forum-icon-block .icon-gestion {
  position: absolute;
  color: #f0f0ee;
  font-size: 17.8571428571rem;
  left: -8.2142857143rem;
  top: -9.6428571429rem;
  z-index: -1;
}

.forum-nav .icon-admin {
  font-size: 2.8571428571rem;
  margin-right: 0.5rem;
}
.navigation .nav-link-admin .icon-admin {
  font-size: 2.7142857143rem;
  margin-left: 0.0714285714rem;
}

.file-upload-btn .icon-attach {
  font-size: 2.5714285714rem;
}

.forum-breadcrumb .icon-home {
  font-size: 1.2857142857rem;
}

.btn--back .icon-arrow-back {
  font-size: 2.1428571429rem;
  margin-right: 5px;
}

.history-action .icon-delete {
  font-size: 1.8571428571rem;
  margin-right: 0.3571428571rem;
}

.reporting-item .icon-drag-and-drop {
  font-size: 1.8571428571rem;
  opacity: 0.3;
}

.chat-history-icon-header .icon-tchat {
  font-size: 1.7857142857rem;
}
.dj-btn.dj-btn--transparent .icon-tchat {
  font-size: 1.4285714286rem;
  margin-right: 10px;
}
.navigation .nav-link-tchat .icon-tchat {
  font-size: 1.9285714286rem;
  margin-left: 0.2857142857rem;
}

.navigation .nav-link-reply .icon-reply {
  font-size: 2.7142857143rem;
  margin-left: 0.0714285714rem;
}

.dj-btn--transparent .icon-refresh {
  font-size: 2.5714285714rem;
}

.dj-alert-warning .icon-signal {
  color: #f39819;
  font-size: 2.1428571429rem;
  margin-right: 1rem;
}

.banned-user-cell .icon-check {
  color: #f39819;
  font-size: 2.1428571429rem;
  margin-left: -0.3571428571rem;
}

.icon-custom-tower {
  background: url("../../public/images/tower.svg") 0 0 no-repeat;
  display: inline-block;
  width: 2.2857142857rem;
  height: 2.2857142857rem;
  margin-left: 0.1428571429rem;
}

.forum-checkbox .icon-round {
  font-weight: bold;
}

.hint-message {
  color: #222222;
  font-family: "Raleway Bold", sans-serif;
  font-size: 1rem;
  line-height: 3.5714285714rem;
  border-radius: 50%;
  background: #f39819;
  text-transform: uppercase;
  cursor: help;
  width: 2rem;
  height: 2rem;
  margin-left: 10px;
  margin-bottom: 0.5rem;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}

.home-nav {
  justify-content: space-between;
  padding-top: 25px;
}

.home-block-item {
  font-size: 1.1428571429rem;
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
}
.home-nav .home-block-item {
  height: 10.7142857143rem;
  width: 30%;
}

.home-faq-block {
  margin-left: auto;
  width: 30%;
  min-height: 9.2857142857rem;
  font-size: 18px;
}

.logo-pic {
  margin-bottom: 25px;
  max-width: 100%;
}

.home-text {
  font-family: "Raleway SemiBold", sans-serif;
  font-size: 1.1428571429rem;
  color: #333;
  margin: 0 auto 1.7857142857rem;
  line-height: 1.5rem;
}

.home-status-icon {
  margin-top: -0.2142857143rem;
}

.home-link:hover {
  opacity: 0.25;
}

.cookies-accept-block {
  background: #333333;
  padding: 1.7857142857rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.menu-open .cookies-accept-block {
  display: none;
}
.cookies-accept-block .cookies-info {
  margin-right: 3.5714285714rem;
  max-width: 57.1428571429rem;
  line-height: 1.5;
}

.cookies-page-actions-block {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
  margin: 0 auto;
}

.quotation-list-block {
  font-family: "Raleway SemiBold", sans-serif;
  line-height: normal;
}

.quotation-list-body {
  padding: 2.1428571429rem 0;
}
.quotation-list-body .item-type i {
  font-size: 2.8571428571rem;
}
.quotation-list-body .item-ref span {
  display: block;
  word-break: break-all;
}
.quotation-list-body .item-date {
  position: relative;
  display: inline-block;
  padding-right: 2.5714285714rem;
  cursor: pointer;
  width: 12.5rem;
}
.quotation-list-body .item-date:after {
  content: "";
  font: normal normal normal 2.2857142857rem "Djump-icons";
  position: absolute;
  right: 0;
  top: -8px;
}

.quotation-item-details {
  background: #e1e1e0;
  border-radius: 0 0 10px 10px;
}

.borderless-search {
  background: #f0f0ee;
}
.borderless-search input {
  font-family: "Raleway Bold", sans-serif;
  font-size: 1.1428571429rem;
}
.borderless-search input::placeholder {
  color: #b2b2b2;
}

.quotation-filter {
  position: relative;
  display: inline-block;
  padding-right: 2.5714285714rem;
  cursor: pointer;
  color: #128caf;
  width: 12.5rem;
}
.quotation-filter:after {
  content: "\e906";
  font: normal normal normal 2.2857142857rem "Djump-icons";
  position: absolute;
  right: 0;
  top: -8px;
}
.quotation-filter.active:after {
  transform: rotate(180deg);
}

.filter-icon {
  border: 2px solid #fff;
  -webkit-transition: border-color;
  -o-transition: border-color;
  transition: border-color;
}
.filter-icon.filtered {
  border-color: #c11e53;
}

.bg-gradient-blue {
  background: -webkit-linear-gradient(top, #f0f9ff 0%, #cbebff 47%, #a1dbff 100%);
  background: linear-gradient(top, #f0f9ff 0%, #cbebff 47%, #a1dbff 100%);
}

.tracking-filter-block .customs-icon {
  width: 35px;
  height: 35px;
  margin-top: 5px;
}
.tracking-filter-block .material-icons {
  color: #000;
  font-size: 34px;
}
.tracking-filter-block .lifter-img {
  width: 30px;
  height: 30px;
}

.card-header.tracking-item {
  padding: 0.5rem 1.25rem;
  font-size: 0.85rem;
}
.card-header.tracking-item .material-icons {
  font-size: 28px;
}

.timeline {
  position: relative;
}
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: #a1dbff;
  top: 0;
  bottom: 0;
  left: 0;
  margin-left: -3px;
}
.timeline__item {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
}
.timeline__item::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  left: -12px;
  background-color: white;
  border: 4px solid #ff9f55;
  top: 20px;
  border-radius: 50%;
  z-index: 1;
}
.timeline__item::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid #8db255;
  border-width: 10px 10px 10px 0;
  border-color: transparent #8db255 transparent transparent;
}
.timeline__item--yellow::before {
  border: medium solid #e2b13c;
  border-width: 10px 10px 10px 0;
  border-color: transparent #e2b13c transparent transparent;
}
.timeline__content {
  padding: 20px 30px;
  background-color: #8db255;
  position: relative;
  border-radius: 6px;
}
.timeline__content--yellow {
  background-color: #e2b13c;
}

.tracking {
  display: flex;
  width: 100%;
  padding: 8px 20px;
}
.tracking__column-1, .tracking__column-2, .tracking__column-3, .tracking__column-4, .tracking__column-5, .tracking__column-6, .tracking__column-7, .tracking__column-8 {
  align-items: center;
  display: flex;
  width: 10%;
}
.tracking__column-1 {
  width: 8%;
}
.tracking__column-1 span, .tracking__column-2 span {
  margin-left: 10px;
}
.tracking__column-2 {
  width: 12%;
}
.tracking__column-3 {
  width: 12%;
  flex-direction: column;
}
.tracking__column-4, .tracking__column-5 {
  flex-direction: column;
  align-items: flex-start;
  width: 20%;
}
.tracking__column-6, .tracking__column-7 {
  width: 8%;
}
.tracking__column-8 {
  width: 14%;
}
@media (max-width: 768px) {
  .tracking {
    flex-wrap: wrap;
  }
  .tracking__column-1, .tracking__column-2, .tracking__column-3, .tracking__column-4, .tracking__column-5, .tracking__column-6, .tracking__column-7, .tracking__column-8 {
    flex-grow: 1;
    width: 100%;
    margin-top: 10px;
  }
  .tracking__column-1 {
    width: 40%;
    justify-content: space-around;
  }
  .tracking__column-2 {
    width: 60%;
    justify-content: space-around;
  }
  .tracking__column-3 {
    justify-content: flex-start;
  }
  .tracking__column-3 span:first-child {
    margin-right: 10px;
  }
  .tracking__column-4, .tracking__column-5 {
    flex-direction: column;
    margin-top: 20px;
  }
  .tracking__column-6, .tracking__column-7 {
    width: 50%;
    justify-content: space-around;
    margin-top: 20px;
  }
}
@media (min-width: 769px) and (max-width: 1600px) {
  .tracking {
    flex-wrap: nowrap;
  }
  .tracking__column-1, .tracking__column-2, .tracking__column-3, .tracking__column-4, .tracking__column-5, .tracking__column-6, .tracking__column-7, .tracking__column-8 {
    align-items: center;
    display: flex;
    width: 10%;
    margin-right: 10px;
  }
  .tracking__column-1 {
    width: 8%;
  }
  .tracking__column-1, .tracking__column-2 {
    flex-direction: column;
  }
  .tracking__column-2 {
    width: 12%;
  }
  .tracking__column-3 {
    width: 12%;
    flex-direction: column;
  }
  .tracking__column-4, .tracking__column-5 {
    flex-direction: column;
    align-items: flex-start;
    width: 18%;
  }
  .tracking__column-6, .tracking__column-7 {
    width: 6%;
  }
  .tracking__column-8 {
    flex-wrap: wrap;
    width: 18%;
  }
}

@media (max-width: 768px) {
  .hidden-sm {
    display: none !important;
  }
}
.tracking-suboption {
  margin-left: -1.0714285714rem;
  font-weight: bold;
  margin-bottom: -0.3571428571rem;
}

.tracking-map {
  width: 100%;
  height: 28.5714285714rem;
}

.tracking-column-rows {
  width: 95%;
  padding-left: 1.0714285714rem;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 16px;
  padding-left: 50%;
}

.lds-ellipsis div {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.noloader {
  opacity: 0;
}

.loader-middle {
  padding-left: 50%;
  margin-left: -23px;
}

.tracking-item-actions-block .btn {
  padding: 0.375rem 0.5rem;
}

.results-count {
  font-size: 90%;
}

.editr .editr--content {
  background-color: #ffffff;
}

.tracking-filter-reset {
  display: flex;
  align-items: center;
  border-left: 1px solid #b2b2b2;
  cursor: pointer;
}

.autocomplete-reset {
  display: flex;
  align-items: center;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.3571428571rem;
  transition: box-shadow 0.1s ease, width 0.1s ease;
  border-left: none;
  cursor: pointer;
}

.tracking-enterprise {
  width: 28.5714285714rem;
  margin-bottom: 1.4285714286rem;
}

.tracking-delivery-date {
  color: #c11e53;
}

.enterprise-autocomplete {
  font-size: 1.4285714286rem;
  font-family: "Raleway SemiBold", sans-serif;
}

.cgv .enterprise-autocomplete {
  font-size: 1rem;
}

.ui.dropdown .menu {
  position: absolute;
  top: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.kibana-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  height: calc(100vh - 215px);
}

.dj-dashboard {
  display: block;
  max-width: 1012px;
  width: 100%;
}

.dj-panel {
  background-color: #f0f0ee;
  border: solid 2px #ed9636;
  border-radius: 10px;
  margin-bottom: 1rem;
  position: relative;
}
.dj-panel--gray {
  border-color: #b2b2b2;
}

.dj-panel__container-common {
  padding: 0.8571428571rem 1.5rem;
}

.dj-panel__container-tr-fold {
  padding-top: 0;
}

.dj-panel__container-tr-status-text {
  padding-left: 60px;
  padding-top: 12px;
  padding-bottom: 34px;
  padding-right: 12px;
}

.dj-panel-2 {
  background-color: #1cb09c;
  color: #ffffff;
  font-family: "Raleway Bold";
  padding: 1.4285714286rem;
  margin-bottom: 1.4285714286rem;
}
.dj-panel-2__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.4285714286rem;
}
.dj-panel-2__body {
  text-transform: uppercase;
}
.dj-panel-2--blue {
  background-color: #0c8db8;
}
.dj-panel-2--red {
  background-color: #c11e53;
}

.dj-form-group--inline {
  display: flex;
  align-items: center;
}

.dj-status-icon {
  background-color: #ffffff;
  border-radius: 50%;
  padding: 0.4285714286rem;
  position: relative;
  width: 2.4285714286rem;
  height: 2.4285714286rem;
  transform: translateY(-20%);
}
.dj-status-icon i {
  color: #1cb09c;
  position: absolute;
  top: 0.1428571429rem;
  left: 0.2142857143rem;
}
.dj-status-icon i.icon-check {
  top: 0.2857142857rem;
}
.dj-status-icon--blue i {
  color: #0c8db8;
}

.dj-tr-filter__input {
  display: none;
}
.dj-tr-filter__label {
  display: inline-block;
  margin-bottom: 0;
  cursor: pointer;
  width: 11.4285714286rem;
  text-align: center;
}

.dj-tr-info__title {
  color: #222222;
  text-transform: uppercase;
  font-family: "Raleway Bold";
}
.dj-tr-info__header {
  margin-bottom: 1rem;
}

.dj-tr-info__c-name {
  font-family: "Raleway Bold";
  color: #333333;
  text-transform: uppercase;
  padding-right: 15px;
}

.dj-tr-info__c-name--1 {
  width: 7%;
}

.dj-tr-info__c-name--2 {
  width: 7%;
}

.dj-tr-info__c-name--3 {
  width: 9%;
}

.dj-tr-info__c-name--4 {
  width: 15%;
}

.dj-tr-info__c-name--5 {
  width: 15%;
}

.dj-tr-info__c-name--6 {
  width: 18%;
}

.dj-tr-info__c-name--7 {
  width: 18%;
}

.dj-tr-info__c-name--8 {
  width: 5%;
}

.dj-tr-info__c-name--9 {
  width: 5%;
}

.dj-tr-fold {
  position: relative;
}

.dj-tr-fold__header {
  position: relative;
  min-height: 90px;
  cursor: pointer;
}

.dj-tr-fold__location {
  margin-right: 55px;
  cursor: pointer;
  font-family: "Raleway Bold";
  text-transform: uppercase;
}

.dj-tr-fold__control {
  position: absolute;
  cursor: pointer;
}

.dj-tr-fold__control--toggle {
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.dj-tr-fold__control--toggle svg {
  width: 17px;
}

.dj-tr-fold__control--fold {
  text-align: center;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dj-tr-fold__c-name {
  white-space: nowrap;
  padding-right: 15px;
  line-height: 1.5rem;
}

.dj-tr-fold__c-name--1 {
  width: 7%;
}

.dj-tr-fold__c-name--2 {
  width: 7%;
}

.dj-tr-fold__c-name--3 {
  width: 9%;
}

.dj-tr-fold__c-name--4 {
  width: 15%;
}

.dj-tr-fold__c-name--5 {
  width: 15%;
}

.dj-tr-fold__c-name--6 {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 18%;
}

.dj-tr-fold__c-name--7 {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 18%;
}

.dj-tr-fold__c-name--8 {
  width: 5%;
}

.dj-tr-fold__c-name--9 {
  width: 5%;
  padding-left: 1.7857142857rem;
}

.dj-tr-fold__footer {
  height: 68px;
  position: relative;
}
.dj-tr-fold__footer--hide {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.dj-tr-fold__body {
  background-color: #e1e1e0;
  padding: 30px;
  transition: all 0.5s ease;
}

.dj-tr-fold__body--hide {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.dj-tr-fold__left {
  padding-right: 20px;
}

.dj-tr-status {
  position: relative;
}

.dj-tr-status__strip {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1.1428571429rem;
  width: 3px;
  background-color: #ffffff;
}

.dj-tr-status__strip--first {
  top: 40%;
}

.dj-tr-status__strip--last {
  height: 1.4285714286rem;
}
.dj-tr-status__strip--last.dj-tr-status__strip--first {
  height: 0;
}

.dj-tr-status__left {
  display: flex;
  align-items: center;
}

.dj-tr-status-text__header {
  padding: 25px 18px 25px 24px;
  border-left: 36px solid #ed9636;
  margin: -12px 0px 33px -60px;
  border-bottom: 3px solid #ed9636;
  text-align: center;
  border-top-left-radius: 5px;
}
.dj-tr-status-text__header--gray {
  border-color: #b2b2b2;
}
.dj-tr-status-text__title {
  font-size: 1rem;
  font-family: "Raleway Bold";
  color: #222222;
  text-transform: uppercase;
}
.dj-tr-status-text__body {
  min-height: 11.4285714286rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dj-tr-status-text__body div {
  font-size: 4.6428571429rem;
  font-family: "Raleway SemiBold";
}
.dj-tr-status-text__row {
  position: relative;
  border-bottom: 2px solid #b2b2b2;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.dj-tr-status-text__row:after {
  content: " ";
  position: absolute;
  width: 24px;
  left: -24px;
  bottom: -2px;
  border-bottom: 2px solid #b2b2b2;
}
.dj-tr-status-text__row:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.dj-tr-status-text__row:last-of-type:after {
  display: none;
}
.dj-tr-status-text__text {
  font-weight: bold;
  font-size: 1rem;
  color: #222222;
}

.dj-tr-fold__right {
  padding-left: 20px;
}

.dj-tr-package-info {
  margin-bottom: 2.8571428571rem;
}
.dj-tr-package-info__title {
  font-size: 1.1428571429rem;
  font-family: "Raleway Bold";
  display: block;
  margin-bottom: 1.2857142857rem;
  text-transform: uppercase;
}
.dj-tr-package-info__receiver {
  font-family: "Raleway SemiBold";
  text-transform: uppercase;
  display: block;
}
.dj-tr-package-info__text {
  font-family: "Raleway SemiBold";
  display: block;
  margin-bottom: 0.5714285714rem;
}

.dj-tr-options__option {
  margin-left: -0.7142857143rem;
  display: flex;
  align-items: center;
  margin-bottom: 3.2857142857rem;
}

.dj-tr-additional__item {
  font-size: 1rem;
  font-family: "Raleway Bold", sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: 1.5714285714rem;
  text-transform: uppercase;
}
.dj-tr-additional__item-emails {
  margin-bottom: 1.2857142857rem;
}
.dj-tr-additional__item i {
  margin-right: 0.7142857143rem;
}
.dj-tr-additional__item--disabled {
  color: #b2b2b2;
}
.dj-tr-additional__item .dj-error-msg {
  text-transform: none;
  margin: 0.3571428571rem 0 0;
}

.dj-link {
  color: #333333;
  text-decoration: none;
  background-color: transparent;
}
.dj-link:hover {
  color: #ed9636;
  text-decoration: underline;
}
.dj-link--no_underline:hover {
  color: #ed9636;
  text-decoration: none;
}
.dj-link--underlined {
  position: relative;
}
.dj-link--underlined::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -2px;
  left: 0;
  height: 2px;
  background-color: #333333;
}
.dj-link--underlined:hover {
  text-decoration: none;
}
.dj-link--underlined:hover::after {
  background-color: #ed9636;
}
.dj-link--bold {
  font-family: "Raleway Bold";
}
.dj-link--warning {
  color: #c11e53;
  text-decoration: none;
}

.dj-export-excel {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.dj-e-list {
  list-style: none;
  font-size: 1rem;
  font-weight: normal;
}
.dj-e-list__item {
  margin-bottom: 0.6428571429rem;
}
.dj-e-list__remove {
  cursor: pointer;
}
.dj-e-list__icon-remove {
  font-size: 2rem;
  vertical-align: middle;
  color: #ffffff;
}
.dj-e-list__text {
  font-family: "Raleway SemiBold";
}

.dj-search-tracking {
  margin-right: 2.1428571429rem;
}
.dj-search-tracking i {
  background-color: #ffffff;
  color: #b2b2b2;
  font-size: 2.5714285714rem;
  height: 50px;
  display: flex;
  align-items: center;
}
.dj-search-tracking .dj-form-control {
  font-family: "Raleway Bold", sans-serif;
}
.dj-search-tracking:not(.w-auto) {
  width: calc(100% - 560px);
}

.dj-select-tracking .dj-select {
  margin-right: 1.7857142857rem;
}
.dj-select-tracking .dj-select.l-indent {
  margin-left: 1.7857142857rem;
}
.dj-select-tracking .dj-select.l-indent.max-w {
  width: calc(100% - 50px);
}
.dj-select-tracking label {
  width: 17.8571428571rem;
  color: #b2b2b2;
  font-family: "Raleway Bold", sans-serif;
  font-size: 1.1428571429rem;
  text-align: right;
}

.tr-doc-disabled {
  color: #b2b2b2;
}

.forced-notification-mail-input label {
  color: #b2b2b2;
  font-family: "Raleway Bold", sans-serif;
  font-size: 1.1428571429rem;
  text-align: right;
}

.notifications-icon-header {
  position: relative;
  justify-content: center;
}
.notifications-open .notifications-icon-header {
  background: #094b5e;
}

.notifications {
  color: #333333;
}
.notifications > * {
  padding: 2.1428571429rem 16rem 1.7142857143rem 2.1428571429rem;
}
.notifications > *:hover {
  box-shadow: 6.8px 5.9px 7px 0 rgba(0, 0, 0, 0.1);
}
.notifications .notification-icon {
  width: 5rem;
  margin-top: -0.7142857143rem;
}
.notifications .notification-icon [class^=icon-] {
  color: #f39819;
  font-size: 2.7142857143rem;
}
.notifications .notification-title {
  display: block;
  color: inherit;
}
.notifications .notification-title:hover {
  text-decoration: none;
  color: inherit;
}
.notifications .notification-text {
  font-family: "Raleway SemiBold", sans-serif;
  padding: 1.0714285714rem 0 1.4285714286rem;
  margin: 0;
}
.notifications .notification-date {
  font-family: "Raleway SemiBold", sans-serif;
}

.account-block {
  width: 650px;
}
.account-block .error-msg {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.account-success {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  height: 300px;
}
.account-success .success-msg {
  color: #73AF55;
  font-size: 1.5rem;
}
.account-success .check-img {
  width: 150px;
  margin: 0 0 40px;
}
.account-success .path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.account-success .path.circle {
  -webkit-animation: dash 1.1s ease-in-out;
  animation: dash 1.1s ease-in-out;
}
.account-success .path.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check 1.1s 0.55s ease-in-out forwards;
  animation: dash-check 1.1s 0.55s ease-in-out forwards;
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}
.upload-files {
  border-radius: 5px;
  background: #fff;
}
.upload-files .preview {
  margin-bottom: 70px;
}

.upload-files-header {
  background: rgba(23, 162, 184, 0.8);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  text-align: center;
  color: #fff;
  font-size: 40px;
  margin: 0;
  padding: 25px 0;
}
.upload-files-header .material-icons {
  font-size: 40px;
}

.upload-files-body {
  text-align: center;
  padding: 70px 0 30px;
  border: 2px dashed rgba(23, 162, 184, 0.8);
  transition: background-color 0.15s linear;
  background: #fff;
}
.upload-files-body.on-drag {
  background: rgba(0, 0, 0, 0.03);
}

.excel-img {
  width: 120px;
  height: 120px;
  display: block;
  margin: 0 auto 30px;
}

.upload-files-slim {
  text-align: center;
  padding: 70px 0;
  border: 2px dashed #0c8db8;
  transition: background-color 0.15s linear;
  background: #fff;
}
#wf_forum_content .upload-files-slim {
  padding: 30px 0;
  border-radius: 10px;
}
#wf_forum_content .upload-files-slim label {
  font-size: 14px;
  font-family: "Raleway Bold", sans-serif;
  color: #222222;
  text-transform: uppercase;
  margin: 0;
}
.upload-files-slim .progress {
  font-size: 24px;
  margin-top: 15px;
}
.upload-files-slim.on-drag {
  background: rgba(0, 0, 0, 0.03);
}
.upload-files-slim label {
  color: #808080;
  font-size: 28px;
  cursor: pointer;
}

.upload-files-list {
  text-align: left;
}
.upload-files-list li {
  display: inline-flex;
  justify-content: space-between;
  margin: 8px;
  background-color: #e1e1e0;
  font-size: 16px;
  font-family: "Raleway SemiBold", sans-serif;
  color: #222222;
  padding: 14px;
  border-radius: 6px;
  width: 265px;
}
.upload-files-list li.loading {
  animation: blinker 2s linear infinite;
}
.upload-files-list span {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  line-height: 25px;
}
.upload-files-list span:hover {
  text-decoration: underline;
}
.upload-files-list a {
  display: flex;
  text-decoration: none;
}
.upload-files-list a .icon-close {
  font-size: 25px;
  color: #0c8db8;
}

.file-upload-btn {
  cursor: pointer;
}
.file-upload-btn span {
  display: inline-block;
  line-height: 1.5714285714rem;
  border-bottom: 2px solid #333333;
}
.file-upload-btn:hover {
  color: #e48500;
}
.file-upload-btn:hover span {
  border-color: #e48500;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.api-exposure .code {
  background: rgba(244, 244, 244, 0.45);
  border-left: 2px solid #F1F1F1;
  color: #666666;
  font-size: 90%;
  white-space: pre;
}
.api-exposure .api-key-label {
  white-space: nowrap;
  margin: 0;
  align-self: center;
}
.api-exposure .table {
  font-size: 0.8571428571rem;
}
.api-exposure .table td {
  font-family: "Raleway SemiBold", sans-serif;
}
.api-exposure .table th {
  font-family: "Raleway Bold", sans-serif;
}

/*

github.com style (c) Vasily Polovnyov <vast@whiteants.net>

*/
.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #333;
  background: #f8f8f8;
}

.hljs-comment,
.hljs-quote {
  color: #998;
  font-style: italic;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
  color: #333;
  font-weight: bold;
}

.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
  color: #008080;
}

.hljs-string,
.hljs-doctag {
  color: #d14;
}

.hljs-title,
.hljs-section,
.hljs-selector-id {
  color: #900;
  font-weight: bold;
}

.hljs-subst {
  font-weight: normal;
}

.hljs-type,
.hljs-class .hljs-title {
  color: #458;
  font-weight: bold;
}

.hljs-tag,
.hljs-name,
.hljs-attribute {
  color: #000080;
  font-weight: normal;
}

.hljs-regexp,
.hljs-link {
  color: #009926;
}

.hljs-symbol,
.hljs-bullet {
  color: #990073;
}

.hljs-built_in,
.hljs-builtin-name {
  color: #0086b3;
}

.hljs-meta {
  color: #999;
  font-weight: bold;
}

.hljs-deletion {
  background: #fdd;
}

.hljs-addition {
  background: #dfd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

.news-feed {
  width: 65%;
  background: #129ecd;
  padding: 1.7142857143rem 0.7142857143rem;
  border-radius: 10px;
  color: #fff;
  font-family: "Raleway Bold", sans-serif;
}
.news-feed.single-record .VueCarousel .VueCarousel-navigation .VueCarousel-navigation-button {
  opacity: 0.5;
  cursor: default;
}
.news-feed .news-label {
  color: #fff;
  opacity: 0.7;
}
.news-feed .VueCarousel {
  margin: 0 6.0714285714rem;
}
.news-feed .VueCarousel .VueCarousel-inner {
  align-items: center;
}
.news-feed .VueCarousel .VueCarousel-navigation .VueCarousel-navigation-button {
  color: #fff;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  padding: 0 !important;
  width: 28px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  font-size: 0;
}
.news-feed .VueCarousel .VueCarousel-navigation .VueCarousel-navigation-button:before {
  content: "\e906";
  font: normal normal normal 22px "Djump-icons";
  color: #fff;
  display: inline-block;
  vertical-align: middle;
}
.news-feed .VueCarousel .VueCarousel-navigation .VueCarousel-navigation-button.VueCarousel-navigation-prev {
  left: -3.2142857143rem;
}
.news-feed .VueCarousel .VueCarousel-navigation .VueCarousel-navigation-button.VueCarousel-navigation-prev:before {
  transform: rotate(90deg);
}
.news-feed .VueCarousel .VueCarousel-navigation .VueCarousel-navigation-button.VueCarousel-navigation-next {
  right: -3.2142857143rem;
}
.news-feed .VueCarousel .VueCarousel-navigation .VueCarousel-navigation-button.VueCarousel-navigation-next:before {
  transform: rotate(270deg);
}
.news-feed .VueCarousel-slide {
  line-height: 1.3rem;
  padding: 0 3px;
}
.news-feed .carousel-block {
  position: relative;
  width: 100%;
}
.news-feed .rss-message {
  font-size: 1.2857142857rem;
  justify-content: center;
}

.news-date {
  color: #fff;
  opacity: 0.7;
  font-size: 75%;
}

.news-title {
  font-size: 1.1428571429rem;
  display: block;
  margin-bottom: 10px;
}

.news-desc-inner {
  margin-bottom: 15px;
  font-family: "Raleway SemiBold", sans-serif;
  height: 35px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.news-desc-inner p:last-child {
  display: none;
}

.news-link {
  border-bottom: 2px solid;
  text-transform: uppercase;
  color: #fff;
  display: inline-block;
  line-height: 1.4rem;
}
.news-link:hover {
  color: #094b5e;
  text-decoration: none;
}

.forum-icon-block {
  position: relative;
}

.forum-nav {
  background: #f0f0ee;
  border: 2px solid #f39819;
  border-radius: 10px;
  font-family: "Raleway Bold", sans-serif;
  font-size: 1.1428571429rem;
  line-height: 3.7857142857rem;
  color: #333333;
  text-align: center;
  text-transform: uppercase;
}
.forum-nav:hover {
  color: #333333;
  text-decoration: none;
  background: #f39819;
}

.forum-active {
  color: #333333;
  text-decoration: none;
  background: #f39819;
}

.dj-thread-list {
  color: #222222;
}
.dj-thread-list__link, .dj-thread-list__link:hover {
  color: #222222;
  text-decoration: none;
}
.dj-thread-list b {
  text-transform: uppercase;
}
.dj-thread-list__header-cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dj-thread-list__header-cell--left {
  justify-content: left;
}
.dj-thread-list__item {
  border: 2px solid #e48500;
  background: #f0f0ee;
  border-radius: 0.7142857143rem;
}
.dj-thread-list__item-desc {
  font-family: "Raleway SemiBold", sans-serif;
}
.dj-thread-list__item-cell {
  font-family: "Raleway SemiBold", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.dj-thread-list__item-cell--left {
  justify-content: left;
  text-align: left;
}

.forum-subject-respond {
  padding: 1.5714285714rem 1.5714285714rem 0;
  margin-bottom: 1.4285714286rem;
}
.forum-subject-respond .subject-block {
  background: #e1e1e0;
  padding: 1.4285714286rem;
  font-size: 1rem;
  color: #333333;
  font-family: "Raleway SemiBold", sans-serif;
}
.forum-subject-respond .subject-block p {
  line-height: 1.4285714286rem;
}
.forum-subject-respond .subject-block p:empty {
  display: none;
}
.forum-subject-respond .subject-name {
  margin-bottom: 1.4285714286rem;
  font-family: "Raleway Bold", sans-serif;
  font-size: 1.1428571429rem;
  text-transform: uppercase;
}
.forum-subject-respond .reply-btn {
  padding: 0 2.1428571429rem;
  margin: 0.7142857143rem 0;
}
.forum-subject-respond .reply-btn:hover {
  text-decoration: none;
}

.forum-post-message {
  background: #fff;
  padding: 1.7142857143rem;
  margin-top: 1rem;
}
.forum-post-message p {
  line-height: 1.4285714286rem;
}

.forum-post-actions.actions-top-indent {
  margin-top: -0.4285714286rem;
}
.forum-post-actions a:hover {
  text-decoration: none;
}
.forum-post-actions [class^=icon-] {
  color: #333333;
  font-size: 2.4285714286rem;
  margin-left: 1.1428571429rem;
}

textarea.wf_textarea_post {
  border: 0;
  padding: 1.4285714286rem;
  color: #333333;
  font-size: 1.1428571429rem;
  font-family: "Raleway SemiBold", sans-serif;
  margin-bottom: 1.4285714286rem;
}

.wf_new_post #wf_smiley {
  margin: 1.1428571429rem 0;
}

.forum-breadcrumb {
  background: transparent;
  font-family: "Raleway SemiBold", sans-serif;
  padding-left: 0;
}
.forum-breadcrumb .breadcrumb-item {
  color: #333333;
  text-transform: uppercase;
}
.forum-breadcrumb .breadcrumb-item.active {
  color: #333333;
}
.forum-breadcrumb .breadcrumb-item a {
  color: inherit;
}
.forum-breadcrumb .breadcrumb-item:last-child {
  font-family: "Raleway Bold", sans-serif;
}
.forum-breadcrumb a:hover {
  text-decoration: none;
}

.forum-thread-admin-controls a {
  color: #333333;
}
.forum-thread-admin-controls a:hover {
  color: inherit;
  text-decoration: none;
}
.forum-thread-admin-controls [class^=icon-] {
  color: #333333;
  font-size: 2.4285714286rem;
}
.forum-thread-admin-controls .admin-controls-icon-block {
  margin: 0.7142857143rem;
}

.forum-post-form label {
  font-size: 1.1428571429rem;
  color: #333333;
  font-family: "Raleway SemiBold", sans-serif;
}
.forum-post-form .form-control {
  border: 0;
  background: #f0f0ee;
  border-radius: 0;
  font-size: 1.1428571429rem;
  color: #333333;
  padding: 1.0714285714rem;
  font-family: "Raleway SemiBold", sans-serif;
}
.forum-post-form textarea {
  min-height: 250px;
}
.forum-post-form .custom-checkbox-icon + label {
  padding-left: 2.5rem;
}

.forum-admin-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.forum-admin-block h4 {
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Raleway Bold", sans-serif;
  margin: 0 1.4285714286rem;
}
.forum-admin-block .forum-admin-block__content {
  margin: 1.4285714286rem;
  background-color: #f0f0ee;
  border-radius: 0.7142857143rem;
  flex-wrap: wrap;
  padding: 1.0714285714rem;
}
.forum-admin-block .forum-admin-block__content a {
  margin: 1.0714285714rem;
}

.forum-admin-container {
  flex-direction: row;
}

.forum-white-link {
  border-radius: 0.7142857143rem;
  background-color: #ffffff;
  font-family: "Raleway Bold", sans-serif;
  line-height: 3.7857142857rem;
  color: #333333;
  text-transform: uppercase;
  padding: 0 1.4285714286rem;
}
.forum-white-link:hover {
  color: #333333;
  text-decoration: none;
  background: #f39819;
}
.forum-white-link:hover .forum-white-link__arrow {
  color: #ffffff;
}
.forum-white-link .forum-white-link__arrow {
  justify-content: center;
  align-items: center;
  line-height: 3.7857142857rem;
  font-size: 2.1428571429rem;
  color: #ed9636;
  transform: rotate(180deg);
}

#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #1c8fb9;
  -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
  z-index: 1001;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #c87e18;
  -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #c11e53;
  -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #fff;
  z-index: 1000;
  -webkit-transform: translateX(0); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0); /* IE 9 */
  transform: translateX(0); /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right {
  right: 0;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%); /* IE 9 */
  transform: translateX(-100%); /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%); /* IE 9 */
  transform: translateX(100%); /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%); /* IE 9 */
  transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

.contact-button-round {
  background: #128caf;
  position: fixed;
  bottom: 0.7142857143rem;
  right: 0.7142857143rem;
  width: 5rem;
  height: 4.6428571429rem;
  padding: 0;
  cursor: pointer;
  border-radius: 15px;
  z-index: 1;
}

.livezilla-button {
  bottom: 5.8571428571rem;
}

.form-button {
  bottom: 10rem;
}

.chat-hidden {
  display: none !important;
}

.tooltip-button {
  display: none;
  position: fixed;
  bottom: 1.4285714286rem;
  right: 7.1428571429rem;
  height: 3.5714285714rem;
  border-radius: 25px;
  border: solid 2px #e3e2da;
  background-color: #ffffff;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  color: #333333;
  padding: 0 1.4285714286rem;
  z-index: 1;
}
.tooltip-button.active, .tooltip-button:hover {
  box-shadow: 0 0 5.3px 1.8px rgba(0, 0, 0, 0.11);
}

.contact-option {
  background: #f39819;
  position: fixed;
  right: 0.7142857143rem;
  border-radius: 25px;
  box-shadow: 0 0 5.3px 1.8px rgba(0, 0, 0, 0.11);
  color: #333333;
  text-align: center;
  padding: 0 1.7857142857rem;
  font-size: 1rem;
  line-height: 3.5714285714rem;
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
  z-index: 1;
}
.contact-option:hover {
  cursor: pointer;
  background: #e48500;
}

#contact-details {
  height: 10rem;
}

.dj-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dj-toggle__inner {
  width: 4.2857142857rem;
  height: 2.1428571429rem;
  background-color: #b2b2b2;
  border-radius: 20px;
  position: relative;
}
.dj-toggle__inner--on {
  background-color: #ed9636;
}
.dj-toggle__switch {
  width: 1.8571428571rem;
  height: 1.8571428571rem;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: 50%;
  transform: translateY(-50%);
  animation: dj-slide-left 0.3s;
}
.dj-toggle__switch--on {
  left: auto;
  right: 2px;
  animation: dj-slide-right 0.3s;
}
.dj-toggle__label {
  font-family: "Raleway Bold";
  font-size: 0.8571428571rem;
  text-transform: uppercase;
  margin-right: 0.8571428571rem;
}
.dj-toggle__label--right {
  margin-left: 0.8571428571rem;
}
.dj-toggle__label--off {
  color: #b2b2b2;
}

@keyframes dj-slide-right {
  0% {
    left: 2px;
    right: auto;
  }
  100% {
    left: 28px;
    right: 2px;
  }
}
@keyframes dj-slide-left {
  0% {
    left: 28px;
    right: 2px;
  }
  100% {
    left: 2px;
    right: auto;
  }
}
.reporting-graph text, .reporting-graph g g text {
  font-family: "Raleway Bold", sans-serif !important;
}

.reporting-block .dj-panel {
  background: transparent;
  border: 0;
  min-height: 25rem;
}
.reporting-block .dj-tr-status-text__header {
  border-top-left-radius: 10px;
  margin-left: -12px;
  border-left-width: 20px;
}
.reporting-block .dj-tr-status-text__body {
  min-height: 15.3571428571rem;
}
.reporting-block .dj-tr-status-text__title {
  font-size: 1.1428571429rem;
  position: relative;
}
.reporting-block .dj-tr-status-text__title [class^=icon-] {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.reporting-block .dj-tr-status-text {
  padding: 0.7142857143rem 0 0.7142857143rem 0.7142857143rem;
  overflow: hidden;
  border-radius: 5px;
}

.reporting-graph svg > g:last-child > g:last-child {
  pointer-events: none;
}

.reporting-item,
.reporting-graph {
  transition: transform 0.3s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 99%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border: 2px solid #ed9636;
  border-radius: 10px;
  background: #ffffff;
}

.reporting-item {
  background-color: #f0f0ee;
  padding: 0;
}
.reporting-item:hover:not(.hovered) {
  border-radius: 10px;
  transition-delay: 0.1s;
}
.reporting-item:hover:not(.hovered):after {
  border-style: solid;
  width: 0;
  border-width: 20px;
  border-color: #ed9636 #FFF #FFF #ed9636;
  border-radius: 10px 0 0 0;
}
.reporting-item:after {
  content: "";
  position: absolute;
  bottom: -2px;
  right: -2px;
  border-width: 0;
  transition: border-width 0.1s;
}
.reporting-item.hovered {
  border-style: dashed;
  box-shadow: 2.3px 2px 24px 0 rgba(0, 0, 0, 0.15);
}

.reporting-graph {
  transform: rotateY(180deg);
  overflow: hidden;
  padding: 0.7142857143rem 0 0.7142857143rem 0.7142857143rem;
}

.clicked .reporting-item {
  transform: rotateY(180deg);
}
.clicked .reporting-graph {
  transform: none;
}

.edit-chart-block .edit-row {
  margin-bottom: 2.1428571429rem;
}
.edit-chart-block .edit-row > label {
  font-family: "Raleway Bold", sans-serif;
  font-size: 1.1428571429rem;
  color: #333333;
  margin-bottom: 1.0714285714rem;
  text-transform: uppercase;
  display: block;
}
.edit-chart-block .edit-options {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1.0714285714rem;
}
.edit-chart-block .edit-options .edit-option {
  margin-right: 3.5714285714rem;
  margin-bottom: 1.4285714286rem;
}
.edit-chart-block .edit-options .edit-option label {
  font-family: "Raleway SemiBold", sans-serif;
  font-size: 1.1428571429rem;
  color: #333333;
}
.edit-chart-block .actions-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 1.0714285714rem;
}

.modal-confirm-txt {
  font-family: "Raleway SemiBold", sans-serif;
  font-size: 1.1428571429rem;
  color: #333333;
  line-height: 1.5rem;
}

.subscription-section {
  margin-top: 5rem;
  font-size: 16px;
}
.subscription-section img {
  margin-bottom: 3.5714285714rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.subscription-section .title {
  text-transform: uppercase;
}

.dj-chat {
  font-size: 14px;
  background: #f0f0ee;
}
.dj-chat.operator-chat {
  background: transparent;
}
.dj-chat.sm {
  width: 32.1428571429rem;
  position: fixed;
  bottom: 2.1428571429rem;
  right: 2.1428571429rem;
  z-index: 10;
  border-radius: 10px;
  box-shadow: -0.2px 6px 13px 0 rgba(0, 0, 0, 0.24);
}
.dj-chat-header {
  height: 7.1428571429rem;
  background: #0c8db8;
  color: #fff;
}
.sm .dj-chat-header {
  border-radius: 10px 10px 0 0;
}
.operator-chat .dj-chat-header {
  background: transparent;
  padding-top: 60px;
  height: auto;
}
.operator-chat .dj-chat-header-inner {
  flex-direction: column;
  width: 250px;
  margin-left: 50px;
}
.dj-chat-content {
  height: 100%;
}
.sm .dj-chat-content {
  height: 70vh;
  border-radius: 0 0 10px 10px;
}
.dj-chat-close {
  cursor: pointer;
  margin-top: 1.0714285714rem;
}
.dj-chat__messages {
  padding: 1.4285714286rem;
  overflow: auto;
  height: 100%;
}
.sm .dj-chat__messages {
  height: calc(100% - 5.7rem);
}
.operator-chat .dj-chat__messages {
  display: flex;
  flex-direction: column;
}
.dj-chat__message-block {
  margin-bottom: 2.1428571429rem;
}
.dj-chat__message-block--own .message-block {
  justify-content: flex-end;
}
.dj-chat__message-block--own .dj-chat__user-pic {
  order: 2;
}
.dj-chat__message-block--own .dj-chat__message-content {
  order: 1;
  padding: 0.7142857143rem 0.7142857143rem 0 0;
}
.dj-chat__message-block--own .dj-chat__user-name {
  text-align: right;
}
.dj-chat__message-block--own .dj-chat__time {
  margin-left: 0;
}
.dj-chat__message-block--system {
  margin-bottom: 0;
}
.dj-chat__message-block--system .message-block {
  justify-content: flex-end;
}
.dj-chat__message-block--system .dj-chat__user-pic, .dj-chat__message-block--system .dj-chat__user-name {
  display: none;
}
.dj-chat__message-block--system .dj-chat__message-content {
  flex: 0 0 100%;
  opacity: 0.65;
  padding: 0;
}
.dj-chat__message-block--system .dj-chat__message {
  background: transparent;
  text-align: center;
  font-family: "Raleway SemiBoldItalic", sans-serif;
  margin: 0;
  padding: 1.4285714286rem 0;
}
.dj-chat__message-block--system .dj-chat__time {
  display: none;
}
.dj-chat__message {
  background: #fff;
  border-radius: 7px;
  padding: 1.4285714286rem;
  margin: 1.0714285714rem 0 0.3571428571rem;
  line-height: 1.35;
  color: #333333;
}
.dj-chat__message-block--own .dj-chat__message {
  background: #e1e1e0;
}
.dj-chat__message-content {
  font-size: 14px;
  font-family: "Raleway SemiBold", sans-serif;
  color: #454545;
  padding: 0.7142857143rem 0 0 0.7142857143rem;
}
.dj-chat__time {
  font-size: 12px;
  margin-left: auto;
  font-family: "Raleway", sans-serif;
}
.dj-chat__textarea {
  height: 80px;
  position: relative;
  padding: 0 45px 0 0;
  background: #e1e1e0;
  width: 100%;
  overflow: hidden;
}
.sm .dj-chat__textarea {
  border-radius: 0 0 10px 10px;
}
.dj-chat__textarea textarea {
  border: 0;
  width: 100%;
  height: 100%;
  padding: 1.4285714286rem;
  resize: none;
  background: transparent;
  font-family: "Raleway Bold", sans-serif;
}
.dj-chat__send {
  position: absolute;
  top: 10px;
  right: 5px;
  cursor: pointer;
}
.operator-chat .dj-chat__notification {
  display: flex;
  margin-top: auto;
  font-size: 1rem;
}
.dj-chat__delay {
  margin-top: auto;
  font-size: 1rem;
  text-align: center;
}

.chat-history-block-header {
  display: flex;
  margin-top: 1.1428571429rem;
  padding: 0.8571428571rem;
  align-items: center;
  font-size: 1.4285714286rem;
}
.chat-history-block-header .dj-rating {
  margin-left: auto;
}
.chat-history-block-header i {
  border: 2px solid #f0f0ee;
  margin-left: 1.5714285714rem;
  cursor: pointer;
}

.chat-history-block--left {
  width: 24.2857142857rem;
}
.chat-history-block--left .record-list {
  margin-bottom: 1.4285714286rem;
  max-height: 45rem;
  overflow: auto;
}
.chat-history-block--left .record-list li {
  background: #f0f0ee;
  padding: 1.4285714286rem;
  cursor: pointer;
}
.chat-history-block--left .record-list li:not(:last-child) {
  border-bottom: 2px solid #e1e1e0;
}
.chat-history-block--left .record-list li:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.chat-history-block--left .record-list li:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.chat-history-block--left .record-list li.active, .chat-history-block--left .record-list li:hover {
  background: #128caf;
}
.chat-history-block--left .record-list li.active [class^=record], .chat-history-block--left .record-list li:hover [class^=record] {
  color: #fff;
}
.chat-history-block--left .record-list li .record-date {
  font-size: 1rem;
  font-family: "Raleway Bold", sans-serif;
  color: #b2b2b2;
}
.chat-history-block--left .record-list li .record-name {
  font-size: 1rem;
  font-family: "Raleway SemiBold", sans-serif;
  color: #454545;
  padding: 1.2142857143rem 0 0.8571428571rem;
}
.chat-history-block--left .record-list li .record-text {
  font-size: 1rem;
  font-family: "Raleway SemiBold", sans-serif;
  color: #b2b2b2;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chat-history-block--right {
  width: calc(100% - 27.85714rem);
  margin-left: auto;
}
.admin-history .chat-history-block--right {
  margin-top: 2.8571428571rem;
}
.chat-history-block--right .spinner-cont {
  height: 21.4285714286rem;
}
.chat-history-block--right-inner {
  padding: 1.4285714286rem;
  border-radius: 6px;
  background: #f0f0ee;
  min-height: 23.5714285714rem;
  max-height: 45rem;
  overflow: auto;
}
.chat-history-block--right .message-block {
  margin-bottom: 2.1428571429rem;
}
.chat-history-block .message-block--operator .message-block-content {
  padding: 0.7142857143rem 0 0 0.7142857143rem;
}
.chat-history-block .message-block--operator .message-text {
  background: #fff;
}
.chat-history-block .message-block--operator .message-time {
  margin-left: auto;
}
.chat-history-block .message-block--own {
  justify-content: flex-end;
}
.chat-history-block .message-block--own .message-block-content {
  padding: 0.7142857143rem 0.7142857143rem 0 0;
  order: 1;
}
.chat-history-block .message-block--own .user-pic-block {
  order: 2;
}
.chat-history-block .message-block--own .message-text {
  background: #e1e1e0;
}
.chat-history-block .message-block--own .user-name {
  text-align: right;
}
.chat-history-block .message-block--system {
  margin-bottom: 0;
}
.chat-history-block .message-block--system .user-pic-block,
.chat-history-block .message-block--system .user-name,
.chat-history-block .message-block--system .message-time {
  display: none;
}
.chat-history-block .message-block--system .message-block-content {
  max-width: 100%;
  flex-grow: 1;
}
.chat-history-block .message-block--system .message-text {
  text-align: center;
  font-style: italic;
}
.chat-history-block .message-block-content {
  font-size: 1rem;
  font-family: "Raleway SemiBold", sans-serif;
  color: #454545;
  max-width: 70%;
}
.chat-history-block .message-text {
  padding: 1.4285714286rem;
  border-radius: 7px;
  margin: 1.0714285714rem 0 0.3571428571rem;
  line-height: 1.35;
}
.chat-history-block .message-time {
  font-size: 0.8571428571rem;
  font-family: "Raleway", sans-serif;
}
.chat-history-block .history-action {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
  color: #454545;
  margin-bottom: 1.0714285714rem;
  line-height: 1.8571428571rem;
}
.chat-history-block .history-action.align-right {
  margin-left: auto;
}

.admin-history .dj-rating {
  pointer-events: none;
}
.admin-history .chat-history-block--right .dj-select {
  width: calc(100% - 85px);
}

.chat-history-block-convo {
  border: 2px solid #ed9636;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 1rem;
}
.chat-history-block-convo .chat-history-block-header {
  background: #e1e1e0;
  margin: 0;
  padding: 1.4285714286rem;
}
.chat-history-block-convo .chat-history-block-header i {
  border: 0;
}
.chat-history-block-convo .chat-history-block-header .user-data label {
  display: block;
  font-size: 1rem;
  font-family: "Raleway SemiBold", sans-serif;
  margin-bottom: 1.0714285714rem;
}
.chat-history-block-convo .chat-history-block-header .user-data span {
  font-size: 1.2857142857rem;
  font-family: "Raleway SemiBold", sans-serif;
}
.chat-history-block-convo .chat-history-block-header .dj-rating {
  width: 14.2857142857rem;
}
.chat-history-block-convo .chat-history-block-header .dj-rating__star:before {
  font-size: 2rem;
}
.chat-history-block-convo .chat-history-block--right-inner {
  border-radius: 0;
}

.message-block-content .dj-rating {
  width: 9rem;
}

.user-mngm-block.indent {
  padding-left: 2.5714285714rem;
}
.user-mngm-block .block-gray {
  padding: 2.8571428571rem 4.2857142857rem;
  width: 80%;
  margin-bottom: 2.1428571429rem;
}
.user-mngm-block .input-row {
  margin-bottom: 1.4285714286rem;
}
.user-mngm-block .input-row label {
  display: block;
  font-size: 1.1428571429rem;
  color: #333333;
  font-family: "Raleway Bold", sans-serif;
}
.user-mngm-block .input-row .wrap {
  flex-wrap: wrap;
}
.user-mngm-block .input-row .dj-form-control,
.user-mngm-block .input-row .dj-form-control-readonly {
  padding: 0.7142857143rem 1.4285714286rem;
  font-size: 1.1428571429rem;
  font-family: "Raleway SemiBold", sans-serif;
  color: #454545;
}
.user-mngm-block .input-row .dj-form-control::placeholder,
.user-mngm-block .input-row .dj-form-control-readonly::placeholder {
  font-family: "Raleway SemiBoldItalic", sans-serif;
  font-style: normal;
}
.user-mngm-block .input-row .dj-form-control.ui.fluid.dropdown,
.user-mngm-block .input-row .dj-form-control-readonly.ui.fluid.dropdown {
  padding: 0.5714285714rem 1.7857142857rem 0.5714285714rem 0.5714285714rem;
  height: auto;
  min-height: 50px;
  border-radius: 0;
}
.user-mngm-block .input-row .dj-form-control.ui.fluid.dropdown > .dropdown.icon,
.user-mngm-block .input-row .dj-form-control-readonly.ui.fluid.dropdown > .dropdown.icon {
  top: 15px;
}
.user-mngm-block .user-options {
  margin-right: 2.8571428571rem;
}
.user-mngm-block .user-options label {
  font-size: 1.1428571429rem;
  color: #333333;
  font-family: "Raleway SemiBold", sans-serif;
  padding-left: 2.8571428571rem;
}
.user-mngm-block .exact-match-item {
  margin-bottom: 0;
  padding-left: 2.5rem;
}
.user-mngm-block .exact-match-item:before {
  top: 0;
}
.user-mngm-block .exact-match-item:after {
  top: -0.1428571429rem !important;
}
.user-mngm-block .dj-error-msg {
  margin-top: 5px;
}
.user-mngm-block .dj-select,
.user-mngm-block .dj-select__dropdown li {
  padding: 10px 20px;
}

.profile-actions {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: space-between;
}
.profile-actions .profile-action--left {
  padding-left: 0;
}
.profile-actions .profile-action--right {
  padding-right: 0;
}

.profile-block .block-gray {
  padding: 2.3571428571rem 3.7857142857rem 3.5714285714rem;
}
.profile-block .right-block {
  padding: 1.7857142857rem 0 0 2.1428571429rem;
  width: 100%;
}
.profile-block .user-data-block {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
  grid-gap: 2vw;
}
.profile-block .data-block {
  font-size: 1.1428571429rem;
}
.profile-block .data-block > label {
  font-family: "Raleway Bold", sans-serif;
  color: #222222;
  display: block;
  line-height: 1.5714285714rem;
}
.profile-block .data-block > span {
  font-family: "Raleway SemiBoldItalic", sans-serif;
  color: #454545;
}

.dj-rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}
.dj-rating__star:before {
  content: "\e953";
  font-family: "Djump-icons" !important;
  font-size: 2.5714285714rem;
  cursor: pointer;
  color: white;
}
.dj-rating__star:hover:before {
  color: #ce7d0b;
}
.dj-rating__star:hover ~ .dj-rating__star:before {
  color: #ce7d0b;
}
.dj-rating__star--selected:before {
  color: #f39819;
}
.dj-rating__star--selected:hover:before {
  color: #ce7d0b;
}
.dj-rating--half .dj-rating__star:before {
  font-size: 1.2857142857rem;
}

.dj-rating-feedback {
  border: none;
  border-radius: 0.7142857143rem;
  padding: 1.1428571429rem 1.4285714286rem;
  height: 10.7142857143rem;
  width: 100%;
}

.wiki-main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
  grid-gap: 2vw;
}
.wiki-main.my-docs-block {
  grid-template-columns: repeat(5, 1fr);
}

.wiki-block {
  padding: 10px;
  height: 300px;
  background: #f0f0ee;
  box-shadow: 6px 8px 0 0 rgba(0, 0, 0, 0.17);
  cursor: pointer;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
}
.wiki-block:hover {
  text-decoration: none;
}
.wiki-block--img {
  width: 70%;
  margin-bottom: 2rem;
}
.wiki-block--title {
  font-family: "Raleway Bold", sans-serif;
  font-size: 1.1428571429rem;
  text-transform: uppercase;
  color: #222222;
  width: 70%;
  text-align: center;
  line-height: 1.35;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wiki-block__title--blue {
  color: #128caf;
}
.wiki-block__date {
  font-family: "Raleway Bold", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  color: #b2b2b2;
  width: 70%;
  text-align: center;
  line-height: 1.35;
  margin-top: auto;
}
.wiki-block--delete {
  margin-top: auto;
  padding-left: 0;
  padding-right: 0;
  align-self: flex-end;
}

.dj-nav-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.8571428571rem;
  border-bottom: 3px solid #f0f0ee;
}
.dj-nav-list__item {
  font-family: "Raleway Bold", sans-serif;
  font-size: 1.1428571429rem;
  text-transform: uppercase;
  color: #222222;
  padding-bottom: 1.0714285714rem;
  position: relative;
}
.dj-nav-list__item--longer {
  width: 7.1428571429rem;
  justify-content: center;
  display: flex;
}
.dj-nav-list__item:hover:before, .dj-nav-list__item--active:before {
  width: calc(100% + 10px);
  content: "";
  position: absolute;
  border-bottom: 4px solid #f39819;
  left: 0;
  bottom: -0.2142857143rem;
  transform: translateX(-5px);
  cursor: pointer;
}
.dj-nav-list__item a, .dj-nav-list__item:hover a {
  color: #222222;
  text-decoration: none;
}

.toasted {
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  font-size: 1rem !important;
  height: 3.5714285714rem !important;
  width: 34.2857142857rem !important;
  justify-content: start !important;
  top: 1.4285714286rem !important;
  transform: translateY(-1.4285714286rem) !important;
}
.toasted i {
  margin-bottom: 0.3571428571rem;
  margin-right: 1.4285714286rem;
}

.toasted.bubble {
  border-radius: 0.7142857143rem !important;
}

.toasted.bubble.error {
  background: #333333 !important;
}
.toasted.bubble.error i {
  color: #c11e53;
  margin-bottom: 0.3571428571rem;
  margin-right: 1.4285714286rem;
}

.toasted.bubble.success {
  background: #333333 !important;
}
.toasted.bubble.success i {
  color: #1cb09c;
  margin-bottom: 0.3571428571rem;
  margin-right: 1.4285714286rem;
}

.quotation-request-block {
  margin-bottom: 2rem;
}

.quotation-error {
  font-size: 16px;
  font-weight: bold;
  color: #dc3545;
}
.quotation-error span {
  margin-bottom: 5px;
}

.quotation-base {
  padding: 2.8571428571rem 2.8571428571rem 1.4285714286rem;
  font-family: "Raleway SemiBold", sans-serif;
  font-size: 1.1428571429rem;
}
.quotation-base .input-custom {
  border: none;
  height: 2.5rem;
  font-family: "Raleway SemiBold", sans-serif;
}
.quotation-base .row-wrapper {
  margin: 0 2.1428571429rem;
}
.quotation-base .white-block {
  height: 3.5714285714rem;
  background: #fff;
  padding: 0 1.4285714286rem;
  margin-bottom: 1.4285714286rem;
  margin-right: 2.1428571429rem;
}
.quotation-base .more-options {
  text-transform: uppercase;
  margin-left: 10px;
  align-self: center;
}
.quotation-base .button-advanced {
  height: 25px;
  object-fit: contain;
  border-radius: 10px;
  background-color: #128caf;
  justify-content: center;
  cursor: pointer;
  border-style: solid;
}
.quotation-base .button-row {
  cursor: pointer;
  padding: 0;
}
.quotation-base .button-row:disabled {
  cursor: default;
}
.quotation-base .row-count {
  width: 4.6428571429rem;
  background: #fff;
  font-size: 1.7142857143rem;
  color: #333;
  text-align: center;
  line-height: 3.5714285714rem;
  margin: 0 1.4285714286rem;
}
.quotation-base .reset-margin {
  margin: 0 5px 0 0;
}

.quotation-base-inner {
  margin-right: -2.1428571429rem;
}
.quotation-base-inner label {
  margin: 0;
}

.autocomplete-input-block {
  flex-grow: 1;
}
.autocomplete-input-block input {
  width: 100%;
}
.autocomplete-input-block input::placeholder {
  font-style: italic;
}

.type-block {
  cursor: pointer;
  margin: 0.7142857143rem 4.2857142857rem 2.1428571429rem 0;
}
.type-block.checked .type-img-container:before {
  opacity: 1;
  content: "\e907";
  color: #f39819;
}
.type-block .type-img-container {
  width: 4.6428571429rem;
  height: 4.8571428571rem;
  position: relative;
}
.type-block .type-img-container:before {
  font-size: 5.3571428571rem;
  font-family: "Djump-icons";
  content: "\e917";
  position: absolute;
  top: -3px;
  left: -4px;
  opacity: 0.2;
}
.type-block .type-name {
  color: #333;
  font-size: 1rem;
}
.type-block [class^=icon-] {
  display: inline-block;
  position: relative;
}
.type-block:not(.checked) [class^=icon-], .type-block:not(.checked) .type-name {
  opacity: 0.35;
}

.quotation-row {
  background: #e1e1e0;
  padding: 2.1428571429rem 2.1428571429rem 0;
  margin-bottom: 1.0714285714rem;
  position: relative;
}
.quotation-row .remove-row {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -4.2857142857rem;
}

.quotation-desc {
  cursor: pointer;
}
.quotation-desc span {
  border: 2px solid #128caf;
  border-radius: 25px;
  color: #128caf;
  line-height: 2.8571428571rem;
  text-align: center;
  width: 11.4285714286rem;
  margin-right: 2.1428571429rem;
  font-size: 1rem;
  margin-bottom: 2.5rem;
}
.no-option-selected .quotation-desc span {
  border-color: #c11e53;
}
.quotation-desc input:checked + span {
  background: #0c8db8;
  color: #fff;
}

.quotation-adjust-quantity {
  margin-right: 2.1428571429rem;
  margin-bottom: 2.5rem;
}

.quotation-label {
  margin: 1.4285714286rem 0;
  font-size: 1.1428571429rem;
}

.quotation-icon-block {
  position: relative;
}

.quotation-price-message {
  font-weight: bold;
  font-size: 1.1rem;
  padding: 0 1.0714285714rem;
}

.quotation-delivery-option {
  cursor: pointer;
  height: 30rem;
  object-fit: cover;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  color: white;
  border-radius: 0.7142857143rem;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.quotation-delivery-option:hover {
  box-shadow: 2.3px 2px 7px 0 rgba(0, 0, 0, 0.45);
}
.quotation-delivery-option.orange {
  background: #f5a00d;
  color: #222222;
}
.quotation-delivery-option.orange .dark {
  background: #c87e18;
}
.quotation-delivery-option.blue {
  background: #1c8fb9;
}
.quotation-delivery-option.blue .dark {
  background: #197fa5;
}
.quotation-delivery-option.red {
  background: #c11e53;
}
.quotation-delivery-option.red .dark {
  background: #9d1944;
}
.quotation-delivery-option.green {
  background: #1cb09c;
}
.quotation-delivery-option.green .dark {
  background: #189181;
}
.quotation-delivery-option .option-header {
  height: 4.2857142857rem;
  font-size: 1rem;
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
}
.quotation-delivery-option .header-separator {
  height: 0.1428571429rem;
}
.quotation-delivery-option .header-left {
  width: 1.4285714286rem;
  border-radius: 0.7142857143rem 0 0 0;
}
.quotation-delivery-option .header-text {
  padding-right: 1.4285714286rem;
  flex-grow: 1;
  justify-content: center;
}
.quotation-delivery-option .header-text span {
  align-self: center;
}
.quotation-delivery-option .price-title {
  margin-top: 2.1428571429rem;
  font-family: "Raleway Bold", sans-serif;
  font-size: 1rem;
}
.quotation-delivery-option .price-constraints {
  max-width: 70%;
  margin: 1.0714285714rem auto 0;
  line-height: 1.4285714286rem;
  display: inline-block;
}
.quotation-delivery-option .price {
  margin: 0.9285714286rem 15% 1.7142857143rem 15%;
  font-size: 2.8571428571rem;
  font-family: "Raleway Bold", sans-serif;
}
.quotation-delivery-option ul {
  text-align: left;
  margin: 0 12% 0 12%;
}
.quotation-delivery-option ul li {
  font-family: "Raleway Bold", sans-serif;
  font-size: 1rem;
  padding: 0.3571428571rem 0;
}
.quotation-delivery-option ul li.delivery-timeframe {
  font-size: 1.7142857143rem;
  font-family: "Raleway Bold", sans-serif;
  text-align: center;
  margin-top: 0.7142857143rem;
}
.quotation-delivery-option .delivery-button {
  line-height: 3.5714285714rem;
  font-size: 1rem;
  text-transform: uppercase;
  margin-top: auto;
  margin-bottom: 1.7857142857rem;
  font-family: "Raleway Bold", sans-serif;
  color: #222;
  height: 3.5714285714rem;
  object-fit: contain;
  border-radius: 1.7857142857rem;
  background-color: #ffffff;
  width: 80%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.quotation-option-placeholder {
  padding: 1.0714285714rem;
  width: 22%;
  min-width: 19.2857142857rem;
}

.quotation-description {
  padding: 1.0714285714rem;
}
.quotation-description h6 {
  margin: 1.4285714286rem 0 1.4285714286rem 0;
  font-size: 1.1428571429rem;
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
}
.quotation-description h5 {
  display: inline-flex;
  align-items: center;
  margin-top: 1.4285714286rem;
  font-size: 1rem;
  text-transform: uppercase;
  border-bottom: 2px solid #222222;
  cursor: pointer;
}
.quotation-description h5 i {
  font-size: 1.7142857143rem;
}
.quotation-description .result {
  height: 7.1428571429rem;
  border-radius: 0.7142857143rem;
  border: solid 0.1428571429rem #ed9636;
  background-color: #f0f0ee;
  display: flex;
  align-items: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.quotation-description .result span {
  font-size: 1.7857142857rem;
  font-family: "Raleway Bold", sans-serif;
  color: #454545;
  text-transform: uppercase;
  margin: 0 0.7142857143rem 0 0.7142857143rem;
}
.quotation-description .result .separator {
  color: #ed9636;
}
.quotation-description .result .lowercase {
  text-transform: lowercase;
}
.quotation-description .result .selected-price {
  font-size: 2.8571428571rem;
  font-weight: bold;
  margin-left: 5rem;
}

.quotation-options {
  flex-wrap: wrap;
  justify-content: space-between;
}

.vue-form-wizard .wizard-progress-with-circle {
  display: none;
}
.vue-form-wizard .stepTitle {
  font-family: "Raleway Bold", sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  color: rgb(41, 41, 41);
}
.vue-form-wizard .active .checked:before {
  color: #1cb09c !important;
}
.vue-form-wizard .wizard-icon-circle {
  border: none;
  position: relative;
  margin-bottom: 1.4285714286rem;
}
.vue-form-wizard .wizard-icon-circle:before {
  content: "\e917";
  font-size: 5.3571428571rem;
  font-family: "Djump-icons";
  font-weight: normal;
  position: absolute;
  top: 0.3571428571rem;
  left: 0.3571428571rem;
}
.vue-form-wizard .wizard-icon-circle.checked:before {
  content: "\e907";
  color: #c7c7c7;
}
.vue-form-wizard .wizard-icon-circle.checked .wizard-icon {
  color: #fff;
}
.vue-form-wizard .wizard-icon-circle .wizard-icon-container {
  background: transparent !important;
}
.vue-form-wizard .wizard-icon-circle .wizard-icon {
  font: normal normal normal 2.5714285714rem "Raleway Bold", sans-serif;
  position: relative;
}
.vue-form-wizard.md .wizard-nav-pills > li.active > a .wizard-icon {
  font-size: 2.5714285714rem;
}
.vue-form-wizard.md .wizard-nav-pills > li a.disabled .stepTitle {
  color: rgb(41, 41, 41);
}
.vue-form-wizard.md .wizard-icon-circle {
  width: 5rem;
  height: 5rem;
  font-size: 1.7142857143rem;
}

.quotation-form-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 3.5714285714rem;
}
.quotation-form-box:not(.register-page) {
  background-color: #f0f0ee;
  border-radius: 0.7142857143rem;
}

.quotation-form {
  width: 35.7142857143rem;
  padding: 3.5714285714rem 6.7857142857rem 0;
}
.register-page .quotation-form {
  background-color: #f0f0ee;
  border-radius: 0.7142857143rem;
}
.quotation-form h3 {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 1.0714285714rem;
}
.quotation-form h4 {
  width: 100%;
  font-size: 1.1428571429rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 2.8571428571rem;
  min-height: 2.7142857143rem;
}

.selected-option {
  width: 16.4285714286rem;
  height: 4.5rem;
  color: white;
  font-size: 1.1428571429rem;
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  border-radius: 0.7142857143rem;
  margin-right: 3.9285714286rem;
}
.selected-option .header-left {
  border-radius: 0.7142857143rem 0 0 0.7142857143rem;
  width: 1.4285714286rem;
}
.selected-option .header-text {
  flex-grow: 1;
  justify-content: center;
  padding-right: 1.4285714286rem;
}
.selected-option .header-text span {
  align-self: center;
}
.selected-option.orange {
  background: #f5a00d;
  color: #222222;
}
.selected-option.orange .dark {
  background: #c87e18;
}
.selected-option.blue {
  background: #1c8fb9;
}
.selected-option.blue .dark {
  background: #197fa5;
}
.selected-option.red {
  background: #c11e53;
}
.selected-option.red .dark {
  background: #9d1944;
}
.selected-option.green {
  background: #1cb09c;
}
.selected-option.green .dark {
  background: #189181;
}

.selected-option-row {
  display: flex;
  align-items: center;
  margin-top: 0.7142857143rem;
}

.calendar-customized {
  padding: 0 2.1428571429rem 0 1.4285714286rem;
  background-color: #f0f0ee;
}
.selected-option-row .calendar-customized, .options-grid .calendar-customized {
  width: 25rem;
}

.calendar-label {
  font-weight: bold;
  align-items: center;
}

.options-grid {
  padding: 0.7142857143rem;
}
.options-grid .title {
  margin-top: 1.4285714286rem;
  margin-bottom: 1.4285714286rem;
  font-size: 1.1428571429rem;
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
}
.options-grid .options {
  padding: 1.4285714286rem;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.options-grid .options .option {
  width: 32%;
  min-width: 17.8571428571rem;
  margin: 0.7142857143rem 0 0.7142857143rem 0;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.options-grid .options .option label {
  font-size: 16px;
  font-weight: 600;
  padding-right: 0.7142857143rem;
}
.options-grid .options .option-tooltip {
  margin-left: 0.7142857143rem;
}
.options-grid .options-insurance {
  width: 100%;
  flex-direction: row-reverse;
}
.options-grid .options-insurance .third-box {
  width: 35%;
  flex-direction: column;
}
.options-grid .options-insurance h4 {
  font-size: 14px;
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
  margin-bottom: 0.7142857143rem;
}
.options-grid .options-insurance .insurance-input {
  width: 70%;
  height: 3.5714285714rem;
  margin-bottom: 1.4285714286rem;
  flex-direction: row;
}
.options-grid .options-insurance .insurance-input .insurance-sign {
  width: 2.8571428571rem;
  background-color: #128caf;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: bold;
}
.options-grid .options-insurance .insurance-input .input-box {
  height: 3.5714285714rem;
  background-color: #f0f0ee;
  padding: 0 1.4285714286rem;
  width: 90%;
}
.options-grid .options-insurance .insurance-input .input-custom {
  border: none;
  font-family: "Raleway SemiBold", sans-serif;
  height: 3.5714285714rem;
  background-color: #f0f0ee;
}
.options-grid .options-insurance .insurance-output {
  padding: 0 1.4285714286rem;
  align-items: center;
  justify-content: space-between;
  background-color: #128caf;
  color: #ffffff;
  height: 3.5714285714rem;
  width: 70%;
  text-transform: uppercase;
  font-weight: bold;
}

.wizard-footer-custom {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.wizard-footer-custom_final-buttons {
  margin-right: auto;
}
.wizard-footer-custom_final-buttons button {
  margin: 0 1.4285714286rem;
}

.quotation-summary {
  color: #333333;
}
.quotation-summary .title {
  margin-top: 1.4285714286rem;
  margin-bottom: 2.1428571429rem;
  font-size: 1.1428571429rem;
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
}
.quotation-summary .price-cell {
  width: 10.7142857143rem;
  margin-left: auto;
  font-size: 1.1428571429rem;
  font-family: "Raleway SemiBold", sans-serif;
}
.quotation-summary .price-cell_big {
  font-size: 25px;
}
.quotation-summary .box {
  margin: 1.0714285714rem 0 2.1428571429rem 0;
  background-color: #f0f0ee;
  padding: 1.4285714286rem 1.7857142857rem;
}
.quotation-summary .box h4 {
  font-size: 1rem;
  font-family: "Raleway Bold", sans-serif;
  margin: 0.7142857143rem 0 0.7142857143rem 0;
  text-transform: uppercase;
}
.quotation-summary .box span {
  width: 100%;
}
.quotation-summary .logistics .section {
  width: 50%;
}
.quotation-summary .logistics .section div {
  margin: 0.3571428571rem 0.3571428571rem 0.3571428571rem 0;
  font-size: 1.1428571429rem;
  font-family: "Raleway SemiBold", sans-serif;
  overflow: hidden;
}
.quotation-summary .logistics .section.centered i {
  font-size: 40px;
}
.quotation-summary .selected-items {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.quotation-summary .selected-items h4 {
  width: 100%;
}
.quotation-summary .selected-items .static-word {
  font-size: 1rem;
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
}
.quotation-summary .selected-items .box-row {
  width: 100%;
  flex-direction: row;
  margin: 0.7142857143rem 0 0.7142857143rem;
}
.quotation-summary .selected-items .section {
  width: 40%;
  min-width: 10.7142857143rem;
  font-size: 1.1428571429rem;
  font-family: "Raleway SemiBold", sans-serif;
}
.quotation-summary .selected-items .totals-row .section,
.quotation-summary .selected-items .totals-row .price-cell {
  font-size: 1.1428571429rem;
  font-family: "Raleway Bold", sans-serif;
}
.quotation-summary .selected-items .totals-row .static-word {
  font-size: 1rem;
}
.quotation-summary .options-totals {
  padding: 0 !important;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.quotation-summary .options-totals_row {
  font-size: 1rem;
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
  padding: 1.4285714286rem 1.7857142857rem;
  height: 2.1428571429rem;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.quotation-summary .options-totals > :nth-child(even) {
  background-color: #ffffff;
}
.quotation-summary .price-totals {
  width: 55%;
  border: solid 2px #ed9636;
  border-radius: 0.7142857143rem;
  margin-bottom: 1.0714285714rem;
  margin-left: auto;
}
.quotation-summary .price-totals_row {
  font-size: 1rem;
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
  padding: 1.4285714286rem 0 1.4285714286rem 0;
  height: 2.1428571429rem;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.quotation-summary .quotation-terms {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  margin: 2.8571428571rem 0 1.4285714286rem 0;
}
.quotation-summary .quotation-terms_row {
  font-size: 1.1428571429rem;
  font-family: "Raleway SemiBold", sans-serif;
  margin: 0.7142857143rem 0 0.7142857143rem 0;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.quotation-summary-back {
  font-size: 1rem;
  cursor: pointer;
  margin-right: auto;
  align-self: center;
  margin-bottom: 0;
}

.quotation-user-block {
  justify-content: space-between;
}
.quotation-user-block > * {
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-download-block {
  margin-left: auto;
  padding: 1.0714285714rem 0;
}

.contact-form:not(.p-0) {
  padding: 2.8571428571rem 6.7857142857rem;
}

.contact-item {
  padding-bottom: 1.4285714286rem;
}

.quotation-contact-block .title-text {
  color: #333;
}

@media (min-width: 1550px) {
  .double-spacing-md.row {
    margin-right: -30px;
    margin-left: -30px;
  }
  .double-spacing-md.row > *[class^=col] {
    padding-right: 30px;
    padding-left: 30px;
  }
  .home-block-item {
    font-size: 1.4285714286rem;
  }
  .home-nav .home-block-item {
    height: 14.2857142857rem;
  }
  .block-gray:not(.custom-indent):not(.items-list) {
    padding: 2.8571428571rem;
  }
  .block-gray.with-title .content.number {
    font-size: 7rem;
  }
  .block-gray.quotation-base {
    padding: 3.5714285714rem 6.7857142857rem 1.4285714286rem;
  }
  .font-lg-20 {
    font-size: 1.4285714286rem;
  }
  .font-lg-16 {
    font-size: 1.1428571429rem;
  }
  .home-block-item .icon-cotation {
    font-size: 10.7142857143rem;
  }
  .home-block-item .icon-tracking {
    font-size: 12.8571428571rem;
  }
  .home-block-item .icon-reporting {
    font-size: 14.2857142857rem;
  }
  .home-block-item .icon-faq {
    font-size: 11.7857142857rem;
  }
  .quotation-user-block {
    justify-content: space-between;
  }
  .quotation-user-block > * {
    height: 20rem;
  }
  .quotation-summary {
    padding: 0 5.7142857143rem;
  }
}
@media (max-width: 1549px) {
  .quotation-row .remove-row {
    right: -35px;
  }
  .carnet img,
.jours img,
.dimensions img {
    width: 95%;
    height: auto;
  }
  .options-grid .option-with-tooltip {
    display: inline-block;
    max-width: 60%;
  }
  .dj-tr-fold__location {
    margin-right: 38px;
  }
  .dj-tr-fold__control--toggle .icon-arrow-menu {
    margin-right: -17px;
  }
  .my-docs-block .wiki-block {
    height: auto;
    padding: 10px 10px 50px;
  }
}
@media (min-width: 1450px) {
  .forum-admin-container {
    padding: 0 7.1428571429rem;
  }
}
@media (min-width: 1300px) {
  .main-header {
    padding: 0 9.2857142857rem 0 3.9285714286rem;
  }
  #content {
    padding: 3.5714285714rem 9.2857142857rem 7.8571428571rem 3.9285714286rem;
  }
  .chat-history-open #content {
    padding-bottom: 0;
  }
  .contact-button-round {
    background: url("../../public/images/contact-bg.png") 0 0 no-repeat;
    width: 10.1428571429rem;
    height: 9.4285714286rem;
    border-radius: 0;
    bottom: 0;
    right: 0;
  }
  .contact-button-round .icon-chatbot {
    font-size: 4.2857142857rem;
    margin-top: 2.1428571429rem;
    margin-left: 3.2142857143rem;
  }
  .tooltip-button {
    display: flex;
  }
  .icons-nav {
    width: 60%;
  }
  .home-faq-block {
    min-height: 11.2142857143rem;
  }
  .livezilla-button {
    bottom: 5.3571428571rem;
    right: 7.1428571429rem;
  }
  .form-button {
    bottom: 9.4285714286rem;
    right: 7.1428571429rem;
  }
}
@media (max-width: 1299px) {
  html {
    font-size: 12px;
  }
  .news-desc-inner {
    display: none;
  }
  .home-block-item [class^=icon-] {
    right: -1.4285714286rem;
    bottom: -1.4285714286rem;
  }
  .quotation-row .remove-row {
    right: -30px;
  }
  .quotation-form {
    padding: 3.57143rem 5.2rem 0;
  }
  .quotation-form.login {
    border-radius: 0.7142857143rem;
  }
  .quotation-form.register {
    border-radius: 0.7142857143rem;
    position: relative;
  }
  .quotation-form.register:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, #f0f0ee 0%, #999 50%, #f0f0ee 100%);
  }
  .quotation-detailed-item {
    margin-right: -2.1428571429rem;
  }
  .reporting-type-filter .dj-tr-filter__label {
    width: 9rem;
  }
  .calendar-reporting .dj-select {
    width: 20rem;
  }
  .operator-main .main-header .dropdown-inner.user {
    width: 25rem;
  }
}
@media (min-width: 1025px) {
  .modal-open {
    padding-right: 17px;
  }
  .operator-chat .dj-chat__message-block--system .dj-chat__message-content {
    width: 100%;
    max-width: none;
  }
  .operator-chat .dj-chat__message-content {
    max-width: 75%;
  }
}
@media (max-width: 1024px) {
  .operator-chat .helper-block {
    display: none;
  }
  .operator-chat .select-block {
    width: auto;
    margin-left: auto;
  }
}
@media (min-width: 998px) {
  .navigation .nav-item {
    padding-left: 1.2857142857rem;
  }
  .navigation .nav-link [class^=icon-] {
    margin-right: 1.0714285714rem;
  }
  .navigation .nav-link [class^=icon-].icon-custom-tower {
    margin-right: 1.2142857143rem;
  }
  .navigation .nav-link [class^=icon-].icon-tracking, .navigation .nav-link [class^=icon-].icon-reporting, .navigation .nav-link [class^=icon-].icon-gestion {
    margin-right: 0.9285714286rem;
  }
  .navigation .nav-link [class^=icon-].icon-tchat {
    margin-right: 1.3571428571rem;
  }
  .mobile-menu-icon {
    display: none;
  }
}
@media (max-width: 997px) {
  aside {
    display: none;
    position: fixed;
    top: 74px;
    bottom: 0;
    width: 100%;
    z-index: 3;
    overflow: auto;
  }
  .menu-open aside {
    display: block;
  }
  #app.with-aside {
    width: 100%;
  }
  .logo-aside {
    display: none;
  }
  .navigation {
    padding: 0;
  }
  .navigation .nav-item {
    height: 6rem;
    padding: 0 0 0 3rem;
  }
  .navigation .nav-item:after {
    content: "\e906";
    font-size: 4rem;
    font-family: "Djump-icons";
    transform: rotate(-90deg);
  }
  .navigation .nav-item.active:before, .navigation .nav-item:hover:before {
    display: none;
  }
  .navigation .nav-item:not(:last-child) {
    border-bottom: 1px solid rgba(51, 51, 51, 0.5);
  }
  .navigation .nav-link {
    font-size: 1.3rem;
    justify-content: start;
    width: 100%;
    padding: 0;
  }
  .navigation .nav-link [class^=icon-] {
    display: none;
  }
  .icons-nav {
    width: 80%;
    margin-left: auto;
  }
  .icons-nav > .nav-item:not(.user-block) {
    width: auto;
  }
  .icons-nav > .nav-item > .nav-link {
    padding: 0 1rem;
  }
  .icons-nav .chat-history-icon-header {
    display: none;
  }
  .main-header {
    padding: 0 1.4285714286rem;
  }
  #content {
    padding: 1.4285714286rem;
  }
  .sm-indent-top {
    margin-top: 1.5rem;
  }
  .chat-history-block--left {
    width: 17.1428571429rem;
  }
  .chat-history-block--right {
    width: calc(100% - 18.57143rem);
  }
  .cookies-accept-block {
    display: block;
    text-align: center;
  }
  .cookies-accept-block .cookies-info {
    margin: 0 0 1.4285714286rem;
    max-width: none;
  }
  .cookies-accept-block .cookies-actions {
    justify-content: center;
  }
  .operator-chat .dj-chat-header-inner {
    width: 200px;
    margin-left: 35px;
  }
  .operator-chat .dj-tabs {
    width: calc(100% - 250px);
  }
  .operator-main .lang-dropdown .dropdown-inner.show {
    left: auto;
    right: 0;
  }
}
@media (min-width: 768px) {
  .account-block {
    margin-top: 3rem;
  }
  .quotation-list-link {
    margin-left: auto;
  }
  .mobile-info {
    display: none;
  }
  .home-text {
    width: 43.2142857143rem;
  }
  .navigation:not(.operator-menu) .nav-item-chat_history {
    display: none;
  }
  .mobile-menu-icon {
    margin-right: 1.4285714286rem;
  }
  .quotation-label-md {
    display: flex;
  }
  .quotation-label-sm {
    display: none;
  }
  .quotation-summary .logistics .section {
    width: 20%;
    min-width: 10.7142857143rem;
  }
  .quotation-summary .logistics .section.centered {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .quotation-summary .logistics .section.centered i {
    font-size: 40px;
  }
  .quotation-summary .quotation-terms_row {
    justify-content: center;
  }
  .operator-chat-inner {
    display: flex;
  }
  .operator-chat-inner .dj-chat-header {
    order: 2;
  }
  .operator-chat-inner .dj-tabs {
    order: 1;
  }
  .operator-chat-inner .dj-chat-header-inner {
    display: flex;
    justify-content: flex-end;
  }
  .operator-chat-inner .dj-chat-header-inner > *:first-child {
    margin-bottom: 3rem;
  }
  .toasted-container.bottom-left {
    bottom: 1.4285714286rem !important;
    left: 15rem !important;
  }
}
@media (max-width: 767px) {
  .dj-chat.sm {
    height: 100%;
    width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    border-radius: 0;
  }
  .sm .dj-chat-header {
    border-radius: 0;
  }
  .dj-chat-header .select-block .btn--orange {
    padding: 0 2.1428571429rem;
  }
  .sm .dj-chat-content {
    height: calc(100% - 7rem);
  }
  .sm .dj-chat__textarea {
    border-radius: 0;
  }
  .sm .dj-chat__messages {
    height: calc(100% - 5rem);
  }
  .dj-chat__message {
    font-size: 12px;
  }
  .dj-chat .dj-btn--square {
    font-size: 14px;
    padding: 0 2rem;
    width: auto;
  }
  html {
    font-size: 16px;
  }
  .vue-form-wizard .stepTitle,
.vue-form-wizard .wizard-header {
    display: none;
  }
  .vue-form-wizard .wizard-tab-content {
    padding: 0;
  }
  .vue-form-wizard .wizard-card-footer {
    padding: 0;
  }
  .hide-mobile {
    display: none;
  }
  .break-word-mobile {
    word-break: break-all;
  }
  .userlist-filter-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .userlist-filter-block > .col {
    -ms-flex-preferred-size: auto;
    -webkit-flex-basis: auto;
    flex-basis: auto;
    margin-bottom: 1rem;
  }
  .sm-wrap {
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #content {
    padding-top: 2.5rem;
  }
  #content:not(.op-content) {
    padding-bottom: 100px;
  }
  #content > .sm-spacing-x-0 {
    margin-left: -24px;
    margin-right: -24px;
  }
  .account-block {
    width: 100%;
    margin: 5% 0;
  }
  .api-exposure {
    max-width: none;
  }
  .api-exposure .action-block {
    margin-top: 10px;
  }
  .search-block {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    background: #129ecd;
    line-height: 60px;
    padding: 0 1rem;
    z-index: 2;
  }
  .search-block .icon-search {
    margin-right: 0;
  }
  .contact-button-round {
    bottom: 4.6428571429rem;
  }
  .livezilla-button {
    bottom: 10rem;
  }
  .form-button {
    bottom: 14.2857142857rem;
  }
  body:not(.modal-open) .main-header {
    z-index: 2;
  }
  .main-header .dropdown-inner.show {
    min-width: 4.7857142857rem;
    max-width: 26rem;
  }
  .dj-thread-list__item-cell {
    justify-content: left;
  }
  .flex-column-sm {
    flex-direction: column;
  }
  .d-flex > .flex-item-12-sm {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .d-flex > .flex-item-6-sm {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .more-options-block {
    justify-content: center;
    margin-top: 2rem;
  }
  .quotation-types {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .quotation-adjust-quantity {
    margin-right: 0;
  }
  .flex-wrap-sm {
    flex-wrap: wrap;
  }
  .quotation-row .remove-row {
    right: -40px;
  }
  .home-faq-block,
.news-feed {
    width: 100%;
  }
  .news-feed {
    margin-bottom: 2rem;
  }
  .news-feed .VueCarousel {
    margin: 0 1rem;
  }
  .news-feed .VueCarousel-navigation {
    display: none;
  }
  .news-feed .carousel-block {
    width: 100%;
  }
  .type-block {
    width: 50%;
    margin-right: 0;
  }
  .quotation-user-block {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .quotation-user-block > [class*=flex-item-] {
    flex: 0 0 45%;
    max-width: 45%;
  }
  .quotation-user-block > [class*=flex-item-]:first-child {
    margin-right: 10%;
    margin-bottom: 2rem;
  }
  .quotation-user-block > [class*=flex-item-]:last-child {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .operator-chat .dj-chat-header {
    padding: 0;
  }
  .operator-chat .dj-chat-header-inner {
    width: 100%;
    margin: 0;
    padding-bottom: 30px;
  }
  .operator-chat .dj-chat-header-inner > *:first-child {
    margin-right: 30px;
  }
  .operator-chat .dj-chat-header-inner .btn--orange {
    padding: 0 2.5rem;
  }
  .operator-chat .dj-tabs {
    width: 100%;
    height: 100vh;
  }
  .operator-chat .dj-chat__message {
    font-size: 12px;
    padding: 1.1428571429rem;
  }
  .operator-chat .dj-chat__textarea {
    height: 50px;
  }
  .operator-chat textarea {
    padding: 1.1428571429rem;
    font-size: 16px;
  }
  .operator-chat textarea::placeholder {
    font-size: 12px;
  }
  .operator-chat .dj-select {
    font-size: 1rem;
  }
  .operator-chat .dj-popup__body {
    padding-left: 30px;
    padding-right: 30px;
  }
  input[data-flex-tab]:checked + .dj-tabs__tab + .dj-tabs__content {
    height: calc(100% - 106px);
  }
  .dj-popup--pom {
    width: auto;
    max-width: 100%;
  }
  .dj-popup--pom .dj-popup__body {
    padding: 0;
  }
  .dj-popup--pom .pom-actions {
    flex-direction: column;
  }
  .dj-popup--pom .pom-actions > * {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 1.5rem;
    text-align: center;
  }
  .dj-popup--pom .pom-actions > *:first-child {
    margin-bottom: 2rem;
  }
  .quotation-form {
    width: 100%;
    padding: 3rem 1rem 0;
  }
  .quotation-form.register:before {
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
  }
  .quotation-description .result .selected-price {
    display: block;
    margin: 0;
    text-align: center;
  }
  .selected-option-row {
    display: block;
  }
  .selected-option-row .selected-option {
    margin-bottom: 2rem;
  }
  .selected-option-row .calendar-label {
    margin-bottom: 0.7142857143rem;
  }
  .options-grid .options {
    padding: 1rem 0;
  }
  .options-grid .options .option {
    width: 100%;
  }
  .options-grid .option-with-tooltip {
    max-width: none;
  }
  .options-grid .options-insurance {
    flex-direction: row;
  }
  .options-grid .options-insurance .third-box {
    width: 100%;
  }
  .wizard-footer-custom.wizard-footer-last {
    flex-direction: column;
  }
  .wizard-footer-custom.wizard-footer-last .wizard-footer-custom_final-buttons {
    flex-direction: column;
    padding-top: 2.8571428571rem;
    margin: 0;
    align-items: center;
  }
  .wizard-footer-custom.wizard-footer-last .wizard-footer-custom_final-buttons button {
    margin: 0 0 1.4285714286rem;
    width: 18rem;
    padding: 0 2rem;
  }
  .quotation-label-sm {
    display: block;
  }
  .quotation-summary .price-totals {
    width: 70%;
  }
  .quotation-summary .price-totals .price-cell {
    width: auto;
  }
  .quotation-summary .quotation-terms_row {
    line-height: 1.35;
  }
  .quotation-summary .quotation-terms_row .custom-checkbox-icon + label {
    padding-left: 2.5rem;
    max-width: 100%;
  }
  .quotation-summary .quotation-terms_row .link--bordered {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    vertical-align: text-top;
  }
  .quotation-summary .options-totals_row {
    line-height: 1.35;
    padding: 1rem;
    height: auto;
  }
  .quotation-summary .options-totals_row .price-cell {
    width: auto;
    white-space: nowrap;
    padding-left: 0.7142857143rem;
  }
  .quotation-summary .logistics .centered {
    padding-top: 1rem;
  }
  .dj-popup--overflow .dj-popup__body-inner {
    padding: 0;
  }
  .contact-form:not(.p-0) {
    padding: 2rem;
  }
  .toasted-container {
    max-width: 90%;
  }
}
@media (min-width: 581px) {
  .multiple-quotations-link {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
@media (max-width: 670px) {
  .dj-popup--pom .pom-actions {
    padding-top: 2rem;
  }
}
@media (max-width: 665px) {
  .quotation-options {
    justify-content: center;
  }
  .quotation-option-placeholder {
    padding: 1rem;
    min-width: 16rem;
  }
}
@media (max-width: 640px) {
  .block-gray.quotation-base {
    padding: 1.4285714286rem;
  }
  .block-gray.quotation-base .label-autocomplete {
    display: none;
  }
  .quotation-row .remove-row {
    transform: none;
    top: 10px;
    right: 10px;
  }
}
@media (max-width: 580px) {
  .sm-full {
    width: 100%;
  }
  .multiple-quotations-block {
    width: 95%;
    min-height: 465px;
  }
  .upload-files-header {
    font-size: 30px;
    padding: 15px 0;
  }
  .upload-files-body {
    padding-top: 30px;
  }
  .excel-img {
    width: 100px;
    height: 100px;
  }
  .upload-files .preview {
    margin-bottom: 30px;
  }
  .dj-popup {
    width: auto;
    max-height: 90vh;
    overflow: auto;
    max-width: 95%;
  }
  .dj-modal__content {
    min-width: 90%;
    max-width: 100%;
  }
  .dj-popup__body, .operator-chat .dj-popup__body {
    padding: 0;
  }
  #app .quotation-base .mx-datepicker-popup {
    left: -63px !important;
  }
  #app .quotation-description .calendar-customized .mx-datepicker-popup {
    left: -38px !important;
  }
  .type-block {
    justify-content: space-around;
  }
  .type-block .type-name {
    display: none;
  }
  .quotation-delivery-option .price {
    font-size: 2.35rem;
  }
}
@media (min-width: 561px) {
  .contact-form .contact-form-row {
    display: flex;
    justify-content: space-between;
  }
  .contact-form .contact-item {
    width: 45%;
  }
  .operator-transfer-textarea {
    margin-bottom: 3rem;
  }
}
@media (max-width: 560px) {
  .vue-form-wizard .wizard-card-footer .wizard-footer-left,
.vue-form-wizard .wizard-card-footer .wizard-footer-right {
    float: none;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left button,
.vue-form-wizard .wizard-card-footer .wizard-footer-right button {
    width: 60%;
    margin-top: 10px;
  }
  .flex-column-xs {
    flex-direction: column;
  }
  .d-flex > .flex-item-12-xs {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .icons-nav > .nav-item.user-block .nav-link {
    padding: 0 1rem;
  }
  .icons-nav .user-name:not(.login-text),
.icons-nav .login-text {
    display: none;
  }
  .home-nav .home-block-item {
    width: 100%;
  }
  .home-nav .home-block-item:not(:last-child) {
    margin-bottom: 2rem;
  }
  .user-block.dropdown {
    position: static;
  }
  .main-header {
    position: relative;
    padding: 0 0 0 1rem;
  }
  .main-header .dropdown-inner.user {
    max-width: 100%;
  }
  .contact-button-round {
    width: 3.5rem;
    height: 3.5rem;
  }
  .contact-button-round .icon-chatbot {
    font-size: 2rem;
  }
  .livezilla-button {
    bottom: 8.5rem;
  }
  .form-button {
    bottom: 12.5rem;
  }
  .quotation-user-block > [class*=flex-item-] {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .quotation-user-block > [class*=flex-item-]:first-child {
    margin-right: 0;
  }
  .quotation-user-block > [class*=flex-item-]:not(:last-child) {
    margin-bottom: 2rem;
  }
  .quotation-option-placeholder {
    padding-left: 0;
    padding-right: 0;
  }
  .vue-form-wizard.md .wizard-icon-circle {
    width: 4rem;
    height: 4rem;
  }
  .vue-form-wizard.md .wizard-icon-circle:before {
    font-size: 4rem;
  }
  .vue-form-wizard.md .wizard-icon-circle .wizard-icon {
    font-size: 2rem;
  }
  .vue-form-wizard.md .wizard-nav-pills > li.active > a .wizard-icon {
    font-size: 2rem;
  }
  .quotation-description .result span {
    font-size: 1.5rem;
  }
  #captcha-account-placeholder {
    transform: scale(0.97);
    transform-origin: 0 0;
  }
  .selected-option-row .calendar-customized {
    width: 100%;
  }
  .options-grid {
    padding: 0;
  }
  .options-grid .options-insurance .insurance-input,
.options-grid .options-insurance .insurance-output {
    width: 100%;
  }
  .options-grid .options .option label {
    padding-right: 0;
  }
  .options-grid .custom-checkbox-icon + label {
    padding-left: 3rem;
  }
  .quotation-description {
    padding-left: 0;
    padding-right: 0;
  }
  .quotation-description .result {
    padding: 0.7142857143rem 0;
    height: auto;
  }
  .quotation-description .result .result-places {
    width: 100%;
    text-align: center;
    padding: 0 0 0.7142857143rem;
  }
  .quotation-description .result .result-places + .separator {
    display: none;
  }
  .quotation-description .result .selected-price {
    padding-top: 0.7142857143rem;
  }
  .quotation-summary .price-totals {
    width: 100%;
    padding: 1rem;
  }
  .quotation-summary .box {
    padding: 1rem;
  }
  .quotation-summary .totals-row {
    flex-wrap: wrap;
  }
  .quotation-summary .totals-row .static-word {
    width: 100%;
    margin-bottom: 1rem;
  }
  .quotation-summary .totals-row .price-cell {
    width: auto;
  }
  .quotation-summary .selected-items .quotation-adv-row + .totals-row {
    margin-top: 2rem;
  }
  .quotation-summary .selected-items .quotation-adv-row .adv-type {
    width: auto;
    min-width: auto;
  }
  .quotation-summary .selected-items .quotation-adv-row .adv-quantity {
    width: auto;
    margin-left: auto;
    min-width: auto;
  }
  .contact-form .btn--orange.btn--orange-lg {
    min-width: auto;
  }
  .search-block input {
    font-size: 15px;
  }
  .operator-chat .dj-chat-header-inner {
    padding: 0;
  }
  .operator-chat .dj-chat-header-inner > *:last-child {
    margin: 1.5rem 0;
  }
  .operator-transfer-textarea {
    margin-bottom: 1rem;
    height: 115px;
  }
  .btn--orange.reduce-sm {
    padding: 0 2rem;
    font-size: 0.9rem;
  }
}
@media (max-width: 365px) {
  .icons-nav > .nav-item > .notifications-icon-header {
    padding: 0 0.5rem;
  }
  .notifications-icon-header .icon-notification {
    font-size: 2rem;
  }
  .notifications-icon-header .icon-notification.notify span {
    top: 0;
    left: 1rem;
  }
  .main-header {
    font-size: 1rem;
  }
  .main-header .icon-arrow-menu {
    font-size: 1.5rem;
  }
  .main-header .user-pic {
    width: 30px;
    height: 30px;
  }
  .main-header .currency-dropdown .dropdown-inner.show {
    min-width: auto;
    max-width: none;
    width: 100%;
  }
}
@media (max-width: 350px) {
  .main-header .dropdown-inner.user {
    max-width: 100%;
  }
  .main-header .dropdown-inner .dropdown-item {
    padding: 1rem;
    font-size: 1rem;
  }
  .quotation-base .white-block {
    padding: 0 0.7142857143rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
  .quotation-base-inner {
    margin-right: -1rem;
  }
  .btn--orange {
    padding: 0 2rem;
  }
  #app .quotation-base .mx-input-append {
    width: 43px;
  }
  #app .quotation-base .mx-datepicker-popup {
    left: -55px !important;
  }
  #app .quotation-description .calendar-customized .mx-datepicker-popup .mx-calendar,
#app .quotation-base .mx-datepicker-popup .mx-calendar {
    padding: 20px;
  }
  #app .quotation-description .calendar-customized .mx-datepicker-popup .mx-icon-last-month,
#app .quotation-base .mx-datepicker-popup .mx-icon-last-month {
    margin-left: -15px;
  }
  #app .quotation-description .calendar-customized .mx-datepicker-popup .mx-icon-next-month,
#app .quotation-base .mx-datepicker-popup .mx-icon-next-month {
    margin-right: -15px;
  }
  .quotation-row {
    padding: 1.3rem 1.3rem 0;
  }
  .quotation-row + .quotation-row {
    padding-top: 3rem;
  }
  .quotation-desc span {
    margin-right: 0;
  }
  .quotation-base .quotation-detailed-item {
    margin-right: 0;
  }
  .quotation-base .quotation-detailed-item .white-block {
    margin-right: 0;
  }
  #captcha-account-placeholder {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
  .quotation-form h3 {
    font-size: 1.4285714286rem;
  }
  .quotation-form h4 {
    font-size: 1rem;
  }
}
@media (max-height: 400px) {
  .terms-content {
    max-height: 60vh;
  }
  .dj-modal__container {
    height: 90vh;
  }
  .dj-modal__content,
.dj-popup {
    height: 100%;
    min-width: 70%;
    max-width: 100%;
  }
  .dj-popup {
    overflow: auto;
  }
  .dj-popup.dj-popup--pom {
    max-height: 70vh;
    transform: translateY(-50%);
    top: 50%;
  }
}
.dj-show-md {
  display: none;
}

@media (max-width: 1200px) {
  .dj-hide-md {
    display: none;
  }
  .dj-show-md {
    display: block;
    overflow: hidden;
  }
  .dj-md-90pct {
    width: 90%;
  }
  .dj-transportation-type .type-block {
    margin: 0.7142857143rem 2.1428571429rem 2.1428571429rem 0;
  }
}