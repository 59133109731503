.dj-tabs {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 11rem);
  width: calc(100% - 300px);
  &.full-width {
    width: 100%;
  }

  &__tab {
    cursor: pointer;
    order: -1;
    width: auto;
    padding: 20px 35px;
    position: relative;
    margin: 0 2px 0 0;
    font-family: "Raleway SemiBold", sans-serif;
    font-size: 14px;

    &:hover {
      background: rgba(240, 240, 238, .5);
    }
  }

  &__notify {
    position: absolute;
    top: rem(6);
    right: rem(6);
    width: rem(8);
    height: rem(8);
    border-radius: rem(8);
    background-color: $red;
  }

  &__content {
    background: $dj-color-gray;
    width: 100%;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.3s ease-out;
  }
}

input[data-flex-tab] {
  display: none;
  visibility: hidden;
  &:checked + .dj-tabs {
    &__tab {
      background: $dj-color-gray;
      font-family: "Raleway Bold", sans-serif;
      pointer-events: none;
      & + .dj-tabs__content {
        height: calc(100% - 136px);
        opacity: 1;
        transition: opacity 0.3s ease-in;
      }
    }
  }
}