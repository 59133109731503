.account-block {
    width: 650px;
    .error-msg {
        margin-top: .25rem;
        font-size: 80%;
        color: #dc3545;
    }
}

.account-success {
    @include flex-column;
    //@include flex-justify-content(center);
    align-items: center;
    height: 300px;

    .success-msg {
        color: #73AF55;
        font-size: 1.5rem;
    }

    .check-img {
        width: 150px;
        margin: 0 0 40px;
    }
    .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
    }
    .path.circle {
        -webkit-animation: dash 1.1s ease-in-out;
        animation: dash 1.1s ease-in-out;
    }

    .path.check {
        stroke-dashoffset: -100;
        -webkit-animation: dash-check 1.1s 0.55s ease-in-out forwards;
        animation: dash-check 1.1s 0.55s ease-in-out forwards;
    }
}

@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}
@keyframes dash-check {
    0% {
        stroke-dashoffset: -100;
    }
    100% {
        stroke-dashoffset: 900;
    }
}
