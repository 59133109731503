.forum-icon-block {
  position: relative;
}

.forum-nav {
  background: $dj-color-gray;
  border: 2px solid $light-orange;
  border-radius: 10px;
  font-family: 'Raleway Bold', sans-serif;
  font-size: rem(16);
  line-height: rem(53);
  color: $dj-black-2;
  text-align: center;
  text-transform: uppercase;
  &:hover {
    color: $dj-black-2;
    text-decoration: none;
    background: $light-orange;
  }
}

.forum-active {
  color: $dj-black-2;
  text-decoration: none;
  background: $light-orange;
}

.dj-thread-list {
  color: $dj-black-1;

  &__link, &__link:hover {
    color: $dj-black-1;
    text-decoration: none;
  }

  b {
    text-transform: uppercase;
  }

  &__header-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header-cell--left {
    justify-content: left;
  }

  &__item {
    border: 2px solid $dark-orange;
    background: $dj-color-gray;
    border-radius: rem(10);
  }

  &__item-desc {
    font-family: $dj-font-semibold, sans-serif;
  }

  &__item-cell {
    font-family: $dj-font-semibold, sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__item-cell--left {
    justify-content: left;
    text-align: left;
  }
}

.forum-subject-respond {
  padding: rem(22) rem(22) 0;
  margin-bottom: rem(20);
  .subject-block {
    background: $bg-dark-gray;
    padding: rem(20);
    font-size: rem(14);
    color: $dj-black-2;
    font-family: 'Raleway SemiBold', sans-serif;
    p {
      line-height: rem(20);

      &:empty {
        display: none;
      }
    }
  }

  .subject-name {
    margin-bottom: rem(20);
    font-family: 'Raleway Bold', sans-serif;
    font-size: rem(16);
    text-transform: uppercase;
  }

  .reply-btn {
    padding: 0 rem(30);
    margin: rem(10) 0;
    &:hover {
      text-decoration: none;
    }
  }
}

.forum-post-message {
  background: #fff;
  padding: rem(24);
  margin-top: rem(14);
   p {
     line-height: rem(20);
   }
}

.forum-post-actions {
  &.actions-top-indent {
    margin-top: rem(-6);
  }
  a:hover {
    text-decoration: none;
  }

  [class^="icon-"] {
    color: $dj-black-2;
    font-size: rem(34);
    margin-left: rem(16);
  }
}

textarea.wf_textarea_post {
  border: 0;
  padding: rem(20);
  color: $dj-black-2;
  font-size: rem(16);
  font-family: "Raleway SemiBold", sans-serif;
  margin-bottom: rem(20);
}

.wf_new_post {
  #wf_smiley {
    margin: rem(16) 0;
  }
}

.forum-breadcrumb {
  background: transparent;
  font-family: "Raleway SemiBold", sans-serif;
  padding-left: 0;
  .breadcrumb-item {
    color: $dj-black-2;
    text-transform: uppercase;
    &.active {
      color: $dj-black-2;
    }
    a {
      color: inherit;
    }
    &:last-child {
      font-family: "Raleway Bold", sans-serif;
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
}

.forum-thread-admin-controls {
  a {
    color: $dj-black-2;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }

  [class^="icon-"] {
    color: $dj-black-2;
    font-size: rem(34);
  }

  .admin-controls-icon-block {
    margin: rem(10);
  }
}

.forum-post-form {
  label {
    font-size: rem(16);
    color: $dj-black-2;
    font-family: "Raleway SemiBold", sans-serif;
  }

  .form-control {
    border: 0;
    background: $dj-color-gray;
    border-radius: 0;
    font-size: rem(16);
    color: $dj-black-2;
    padding: rem(15);
    font-family: "Raleway SemiBold", sans-serif;
  }

  textarea {
    min-height: 250px;
  }

  .custom-checkbox-icon+label {
    padding-left: 2.5rem;
  }
}