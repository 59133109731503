.filter-icon {
    border: 2px solid #fff;
    @include transition(border-color);
    &.filtered {
      border-color: $red;
    }
}

.bg-gradient-blue {
    background: -webkit-linear-gradient(top, #f0f9ff 0%,#cbebff 47%,#a1dbff 100%);
    background: linear-gradient(top, #f0f9ff 0%,#cbebff 47%,#a1dbff 100%);
}

.tracking-filter-block {
    .customs-icon {
        width: 35px;
        height: 35px;
        margin-top: 5px;
    }

    .material-icons {
        color: #000;
        font-size: 34px;
    }

    .lifter-img {
        width: 30px;
        height: 30px;
    }
}

.card-header {
    &.tracking-item {
        padding: 0.5rem 1.25rem;
        font-size: 0.85rem;
        .material-icons {
            font-size: 28px;
        }
    }
}

.timeline {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 6px;
        background-color: #a1dbff;
        top: 0;
        bottom: 0;
        left: 0;
        margin-left: -3px;
    }

    &__item {
        padding: 10px 40px;
        position: relative;
        background-color: inherit;

        &::after {
            content: '';
            position: absolute;
            width: 25px;
            height: 25px;
            left: -12px;
            background-color: white;
            border: 4px solid #ff9f55;
            top: 20px;
            border-radius: 50%;
            z-index: 1;
        }

        &::before {
            content: ' ';
            height: 0;
            position: absolute;
            top: 22px;
            width: 0;
            z-index: 1;
            left: 30px;
            border: medium solid #8db255;
            border-width: 10px 10px 10px 0;
            border-color: transparent #8db255 transparent transparent;
        }

        &--yellow::before {
            border: medium solid #e2b13c;
            border-width: 10px 10px 10px 0;
            border-color: transparent #e2b13c transparent transparent;
        }
    }

    &__content {
        padding: 20px 30px;
        background-color: #8db255;
        position: relative;
        border-radius: 6px;

        &--yellow {
            background-color: #e2b13c;
        }
    }
}

.tracking {
    display: flex;
    width: 100%;
    padding: 8px 20px;

    &__column-1,
    &__column-2,
    &__column-3,
    &__column-4,
    &__column-5,
    &__column-6,
    &__column-7,
    &__column-8 {
        align-items: center;
        display: flex;
        width: 10%;
    }

    &__column-1 {
        width: 8%;
    }

    &__column-1,
    &__column-2 {
        span {
            margin-left: 10px;
        }
    }

    &__column-2 {
        width: 12%;
    }

    &__column-3 {
        width: 12%;
        flex-direction: column;
    }

    &__column-4,
    &__column-5 {
        flex-direction: column;
        align-items: flex-start;
        width: 20%;
    }

    &__column-6,
    &__column-7 {
        width: 8%;
    }

    &__column-8 {
        width: 14%;
    }

    @media (max-width: 768px) {
        flex-wrap: wrap;

        &__column-1,
        &__column-2,
        &__column-3,
        &__column-4,
        &__column-5,
        &__column-6,
        &__column-7,
        &__column-8 {
            flex-grow: 1;
            width: 100%;
            margin-top: 10px;
        }

        &__column-1 {
            width: 40%;
            justify-content: space-around;
        }
        &__column-2 {
            width: 60%;
            justify-content: space-around;
        }


        &__column-3 {
            justify-content: flex-start;

            span:first-child {
                margin-right: 10px;
            }
        }

        &__column-4,
        &__column-5 {
            flex-direction: column;
            margin-top: 20px;
        }

        &__column-6,
        &__column-7 {
            width: 50%;
            justify-content: space-around;
            margin-top: 20px;
        }
    }

    @media (min-width: 769px) and (max-width: 1600px) {
        flex-wrap: nowrap;

        &__column-1,
        &__column-2,
        &__column-3,
        &__column-4,
        &__column-5,
        &__column-6,
        &__column-7,
        &__column-8 {
            align-items: center;
            display: flex;
            width: 10%;
            margin-right: 10px;
        }

        &__column-1 {
            width: 8%;
        }

        &__column-1,
        &__column-2 {
            flex-direction: column;
        }

        &__column-2 {
            width: 12%;
        }

        &__column-3 {
            width: 12%;
            flex-direction: column;
        }

        &__column-4,
        &__column-5 {
            flex-direction: column;
            align-items: flex-start;
            width: 18%;
        }

        &__column-6,
        &__column-7 {
            width: 6%;
        }

        &__column-8 {
            flex-wrap: wrap;
            width: 18%;
        }
    }
}

@media (max-width: 768px) {
    .hidden-sm {
        display: none !important;
    }
}

.tracking-suboption {
    margin-left: rem(-15);
    font-weight: bold;
    margin-bottom: rem(-5);
}

.tracking-map {
    width: 100%;
    height: rem(400);
}

.tracking-column-rows {
    width: 95%;
    padding-left: rem(15);
}

// loader
.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 16px;
    padding-left: 50%;
}
.lds-ellipsis div {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}
.noloader {
    opacity: 0;
}
.loader-middle {
    padding-left: 50%;
    margin-left: -23px;
}

.tracking-item-actions-block {
    .btn {
        padding: .375rem .5rem;
    }
}

.results-count {
    font-size: 90%;
}

.editr .editr--content {
    background-color: #ffffff;
}

.tracking-filter-reset {
    display: flex;
    align-items: center;
    border-left: 1px solid $dj-color-middle-gray;
    cursor: pointer;
}

.autocomplete-reset {
    display: flex;
    align-items: center;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: rem(5);
    transition: box-shadow 0.1s ease, width 0.1s ease;
    border-left: none;
    cursor: pointer;
}

.tracking-enterprise {
    width: rem(400);
    margin-bottom: rem(20);
}

.tracking-delivery-date {
    color: $dj-color-red;
}

.enterprise-autocomplete {
    font-size: rem(20);
    font-family: "Raleway SemiBold", sans-serif;
}

.cgv .enterprise-autocomplete {
    font-size: rem(14);
}

.ui.dropdown .menu {
position: absolute;
top: 100%;
border: 1px solid rgba(34,36,38,.15);
}
