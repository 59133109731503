.dj-rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;

  &__star:before {
    content: '\e953';
    font-family: 'Djump-icons' !important;
    font-size: rem(36);
    cursor: pointer;
    color: white;
  }

  &__star:hover:before {
    color: darken($light-orange, 10%);
  }

  &__star:hover ~ &__star:before {
    color: darken($light-orange, 10%);
  }

  &__star--selected:before {
    color: $light-orange;
  }

  &__star--selected:hover:before {
    color: darken($light-orange, 10%);
  }

  &--half &__star:before {
    font-size: rem(18);
  }
}

.dj-rating-feedback {
  border: none;
  border-radius: rem(10);
  padding: rem(16) rem(20);
  height: rem(150);
  width: 100%;
}