.dj-btn {
  background: transparent;
  border: 2px solid $light-blue;
  border-radius: 28px;
  color: $light-blue;
  font-family: "Raleway Bold";
  padding: 14px 26px;
  text-transform: uppercase;
  white-space: nowrap;

  &:hover, &--active {
    color: $white;
    background: $light-blue;
    cursor: pointer;
  }

  &-pink {
    color: $red;
    border-color: $red;

    &:hover, &--active {
      color: $white;
      background: $red;
    }
  }

  &-green {
    color: $green;
    border-color: $green;

    &:hover, &--active {
      color: $white;
      background: $green;
    }
  }

  &-gray {
    color: $gray;
    border-color: $gray;

    &:hover, &--active {
      color: $white;
      background: $gray;
    }
  }

  &--transparent {
    background-color: transparent;
    border: none;
    color: $dj-black-1;
    display: flex;
    align-items: center;

    &.tc-white {
      color: #fff;
      span {
        border-color: #fff;
        color: #fff;
      }
    }

    span {
      border-bottom: 2px solid $dj-black-1;
      padding-bottom: 3px;
      text-transform: uppercase;
      color: $dj-black-1;
      a {
        color: inherit;
        &:hover {
          text-decoration: none;
        }
      }
    }

    [class^="icon-"] {
      margin-right: 5px;
    }

    &:hover, &--active {
      background: inherit;
      color: $light-orange;
      cursor: pointer;
      text-decoration: none;

      span {
        color: $light-orange;
        border-bottom: 2px solid $light-orange;
        a {
          color: $light-orange;
        }
      }
    }
  }

  &--bg-white {
    background-color: $dj-color-white;
  }
}

.dj-btn--bg-white.dj-btn--active {
  color: $white;
  background: $light-blue;
  cursor: pointer;
}

.dj-btn--orange, .btn--orange {
  color: $dj-black-1;
  font-family: "Raleway Bold", sans-serif;
  font-size: rem(14);
  line-height: rem(50);
  padding: 0 rem(45);
  border-radius: 25px;
  background: $light-orange;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
  &:hover {
    background: $dark-orange;
    color: $dj-black-1;
    text-decoration: none;
  }
  &:disabled {
    background: $dj-color-middle-gray;
    cursor: default;
  }

  &.btn--orange-md {
    min-width: rem(200);
  }

  &.btn--orange-lg {
    min-width: rem(250);
  }

  &.btn--orange-sm {
    padding: 0 rem(30);
  }

  &.btn--orange-outline {
    background: transparent;
    line-height: rem(46);
    border: 2px solid $light-orange;
    &:hover {
      background: transparent;
    }
  }

  &-user {
    padding-left: 43px;
    padding-right: 20px;
  }
}

.btn--white {
  color: $blue;
  font-family: "Raleway Bold", sans-serif;
  font-size: 14px;
  line-height: 45px;
  padding: 0 30px;
  border-radius: 25px;
  background: #fff;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  border: 0;
  &:disabled {
    background: $dj-color-middle-gray;
    cursor: default;
  }
}

.dj-btn--square {
  display: flex;
  justify-content: center;
  border-radius: rem(10);
  border: 2px solid $dark-orange;
  color: $dj-black-1;
  font-family: "Raleway Bold", sans-serif;
  font-size: rem(16);
  line-height: rem(50);
  padding: 0 rem(45);
  background: $dj-color-gray;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  width: rem(255);

  &:hover, &--active {
    background: $dark-orange;
    color: $dj-black-1;
    text-decoration: none;
    cursor: pointer;
  }

  &:disabled {
    background: $dj-color-middle-gray;
  }

  &.chat-rating-green {
    @include rating($green);
  }

  &.chat-rating-orange {
    @include rating($dark-orange);
  }

  &.chat-rating-red {
    @include rating($error);
  }
}

.add-icon {
  border: 2px solid $dj-color-orange;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
  font-size: 0;
  &.rotated {
    transform: rotate(45deg);
  }
}

.btn--back {
  display: inline-flex;
  align-items: center;
  color: $dj-black-1;
  font-family: "Raleway Bold", sans-serif;
  border-bottom: 2px solid $dj-black-1;
  margin-bottom: rem(20);
  text-transform: uppercase;
  &:hover {
    color: $dark-orange;
    border-bottom: 2px solid $dark-orange;
    text-decoration: none;
  }
}

.dj-checkbox-checked {
  position: relative;
  .icon-check {
    position: absolute;
    left: rem(-3);
    top: rem(-4);
    font-size: rem(30);
  }
}

.btn--sticky-1 {
  display: flex;
  align-items: center;
  border: 2px solid $dark-orange;
  position: fixed;
  right: -2px;
  top: rem(170);
  background: $dj-color-gray;
  border-radius: 35px 0 0 35px;
  padding: rem(20);
  color: $dj-black-2;
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  cursor: pointer;
  width: rem(210);

  //&:hover {
  //  background: $dj-color-middle-gray;
  //}
}

.btn--sticky-2 {
  display: flex;
  align-items: center;
  position: fixed;
  right: -2px;
  top: rem(110);
  background: $light-orange;
  border-radius: 35px 0 0 35px;
  padding: rem(20);
  color: $dj-black-2;
  font-size: 1rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  cursor: pointer;
  width: rem(210);

  &:hover {
    background: $dark-orange;
  }
}
