.block-gray {
  background-color: $dj-color-gray;
  border-radius: 10px;
  display: block;
  color: inherit;
  .home-block-item & {
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    &:hover:not(.items-list) {
      color: inherit;
      text-decoration: none;
      [class^="icon-"] {
        opacity: .25;
      }
    }

    span {
      position: relative;
      z-index: 1;
    }
  }

  &:not(.borderless) {
    border: 2px solid $dj-color-orange;
  }

  &.border-dashed {
    border-style: dashed;
  }

  &:not(.custom-indent):not(.items-list) {
    padding: rem(30);
  }

  &.with-title {
    display: block;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-bottom: 2px solid $dj-color-orange;
      height: rem(60);
      width: 100%;
      position: relative;
      padding: 0 rem(40);
      text-transform: uppercase;
      font-size: rem(14);
      font-family: "Raleway Bold", sans-serif;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: rem(20);
        background: $dj-color-orange;
        border-top-left-radius: 10px;
      }
    }

    .content {
      padding: rem(30);
      width: 100%;
      height: calc(100% - 4.28571rem);
      &.number {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dj-color-orange;
        font-size: rem(65);
        font-family: "Raleway SemiBold", sans-serif;
      }
    }
  }

  &.hover {
    &:hover {
      background: $light-orange;
      box-shadow: 2.3px 2px 7px 0 rgba(0, 0, 0, 0.21);
      cursor: pointer;
    }
  }

  &.quotation-base {
    padding: rem(40) rem(40) rem(20);
  }

  &.mb-sm {
    margin-bottom: rem(17);
  }

  &.items-list {
    padding: rem(20);
    flex-direction: column;
    align-items: self-start;
    text-transform: none;
    font-family: "Raleway SemiBold", sans-serif;
    justify-content: flex-start;

    .title {
      align-self: center;
      text-transform: uppercase;
      margin-bottom: rem(10);
    }

    .content {
      flex-direction: column;
      height: rem(200);
      justify-content: space-between;
      a {
        padding: rem(10) 0;

        @media (max-width: 1680px) {
          padding: 0;
        }
      }
    }

    .no-items {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-right: -50%;
      transform: translate(-50%, -50%)
    }

    a {
      color: $dj-black-1;
    }
  }
}

.dj-anchors {
  &__header {
    background: $dj-color-gray;
    border-radius: 10px;
    padding: rem(45) rem(75);

    li {
      font-family: "Raleway SemiBold", sans-serif;
      font-size: 16px;
      color: $dj-black-2;
      position: relative;
      padding-left: 20px;
      &:not(:last-child) {
        margin-bottom: rem(15);
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 5px;
        width: 10px;
        height: 10px;
        background: $light-orange;
        border-radius: 50%;
      }

      a {
        color: inherit;
        line-height: 1.5rem;
        &:hover {
          color: inherit;
          text-decoration: none;
          border-bottom: 2px solid $light-orange;
        }
      }
    }
  }

  &__content {
    padding: 0 rem(45) 0 rem(75);
    color: $dj-black-2;
    &-block {
      padding-bottom: 2rem;
      & > * {
        font-size: 14px;
        font-family: "Raleway Medium", sans-serif;
        line-height: 1.5rem;
        margin: 0;
        &:not(:last-child):not(h5):not(.subtitle) {
          margin-bottom: 2rem;
        }
      }

      & > h5 {
        margin: rem(15) 0;
        font-family: "Raleway Bold", sans-serif;
        font-size: 16px;
      }

      & > .subtitle {
        margin: rem(15) 0;
        font-family: "Raleway SemiBold", sans-serif;
      }

      ul {
        li {
          position: relative;
          padding-left: 25px;
          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 5px;
            width: 10px;
            height: 10px;
            background: $dj-black-2;
            border-radius: 50%;
          }

          &:not(:last-child) {
            margin-bottom: rem(20);
          }
        }
      }
    }
  }
}

.blue-list-indent {
  margin-bottom: 1rem;
  display: flex;
  .blue-block {
    color: $light-blue;
    text-transform: uppercase;
    font-family: "Raleway Bold", sans-serif;
    width: 100px;
    margin-right: 14px;
  }

  .cont-block {
    font-family: "Raleway SemiBoldItalic", sans-serif;
    width: calc(100% - 114px);
  }
}