.forum-admin-block {
  width: 100%;
  display: flex;
  flex-direction: column;

  h4 {
    text-transform: uppercase;
    font-size: 16px;
    font-family: "Raleway Bold", sans-serif;
    margin: 0 rem(20);
  }

  .forum-admin-block__content {
    margin: rem(20);
    background-color: $dj-color-gray;
    border-radius: rem(10);
    flex-wrap: wrap;
    padding: rem(15);

    a {
      margin: rem(15);
    }
  }
}

.forum-admin-container {
  flex-direction: row;
}

.forum-white-link {
  border-radius: rem(10);
  background-color: $dj-color-white;
  font-family: "Raleway Bold", sans-serif;
  line-height: rem(53);
  color: $dj-black-2;
  text-transform: uppercase;
  padding: 0 rem(20);
  &:hover {
    color: $dj-black-2;
    text-decoration: none;
    background: $light-orange;

    .forum-white-link__arrow {
      color: $dj-color-white;
    }
  }

  .forum-white-link__arrow {
    justify-content: center;
    align-items: center;
    line-height: rem(53);
    font-size: rem(30);
    color: $dj-color-orange;
    transform: rotate(180deg);
  }
}
