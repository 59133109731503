//html, body {
//    height: 100%;
//}

html {
    font-size: 14px;
}

body {
    font-family: "Raleway", sans-serif;
    font-size: rem(14);
    color: #222;
}

aside {
    width: 15rem;
    background: #f0f0ee;
    box-shadow: 3px 0 3px 0 rgba(0, 0, 0, 0.11);
}

#app {
    &.with-aside {
        width: calc(100% - 14rem);
    }
}

.logo {
    padding: 10px;
    width: 100%;
}

.navigation {
    padding-top: rem(30);
    .nav-item {
        display: flex;
        height: rem(70);
        position: relative;
        align-items: center;
        &.active, &:hover {
            &:before {
                content: "\e907";
                font-size: rem(80);
                font-family: 'Djump-icons';
                position: absolute;
                top: 3px;
                left: -5px;
                color: $red;
            }

            .nav-link {
                color: $red;
            }

            [class^="icon-"] {
                color: #fff;
            }

            .icon-custom-tower {
                filter: invert(100%);
            }
        }

        //&:not(:first-child) {
            &.active, &:hover {
                &:before {
                    color: $green;
                }

                .nav-link {
                    color: $green;
                }
            }
        //}
    }

    .nav-select {
        display: flex;
        height: rem(70);
        position: relative;
        align-items: center;
        flex-direction: column;
        padding: rem(10);
        margin-bottom: rem(30);
        margin-top: rem(-20);

        .header-select {
            width: 100%;
        }
    }

    .nav-link {
        font-size: rem(14);
        text-transform: uppercase;
        font-family: "Raleway ExtraBold", sans-serif;
        color: #222;
        display: flex;
        align-items: center;
        padding: rem(10) 0 0;
        line-height: normal;
        [class^="icon-"] {
            position: relative;
            display: inline-block;
            font-size: rem(34);
            margin-top: rem(-10);
        }
    }
}

.secondary-links {
    position: relative;
    margin: rem(20) 0 0;
    padding: rem(20) 0 0;
    &:before {
        content: '';
        width: calc(100% - 3rem);
        height: 2px;
        background: $dj-color-middle-gray;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    li {
        padding: rem(10) rem(20);
    }

    a {
        font-family: 'Raleway BoldItalic', sans-serif;
        font-size: rem(16);
        color: $dj-color-middle-gray;
        &:hover {
            text-decoration: none;
            color: $light-orange;
        }
    }
}

.main-header {
    background: $blue;
    padding: 0 rem(40);
    height: 74px;
    font-size: rem(16);
    .header-inner,
    [class^="icon-"] {
        color: #fff;
    }
    .dropdown-inner {
        display: none;
        background: $blue;
        &.show {
            display: block;
            position: absolute;
            z-index: 11;
            top: 100%;
            left: 0;
            min-width: rem(80);
        }

        &.user {
            width: rem(400);
            .dropdown-item {
                line-height: rem(50);
            }
        }

        .dropdown-item {
            padding: rem(14) rem(20) rem(14) rem(27);
            font-family: 'Raleway Bold', sans-serif;
            font-size: rem(16);
            line-height: rem(20);
            color: #fff;
            background: transparent;
            &:not(:last-child) {
                border-bottom: 2px solid $header-item-border;
            }
        }

        a.dropdown-item:hover {
            color: $header-menu-active;
            cursor: pointer;
            [class^="icon-"] {
                color: $header-menu-active;
            }
        }

        .header-select-label {
            width: rem(100);
        }

        .header-select {
            flex-grow: 1;
            max-width: rem(253);
            .dj-select__dropdown {
                li {
                    &:hover {
                        span {
                            border-color: #fff;
                        }
                    }
                    span {
                        padding-top: 4px;
                        border-bottom: 2px solid transparent;
                    }
                }
            }
        }
    }

    .user-pic {
        border-radius: 50%;
    }

    .user-name {
        font-family: 'Raleway SemiBold', sans-serif;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: rem(400);
    }
}

.search-block {
    width: 40%;
    input {
        background: transparent;
        border: 0;
        color: #fff;
        font-size: rem(16);
        font-family: "Raleway Bold", sans-serif;
        width: 80%;
        &::placeholder {
            color: #fff;
        }
    }
}

body {
    &:not(.modal-open) {
        .main-header {
            z-index: 1;
        }
    }

    &.menu-open {
        overflow: hidden;
        .main-header {
            position: fixed;
            width: 100%;
        }
    }

    &.with-iframe {
        overflow: hidden;
        #content {
            padding: 0;
        }

        aside {
            position: relative;
            z-index: 1;
        }
    }
}

.nav-link {
    cursor: pointer;
    padding: 0;
}

.icons-nav {
    width: 60%;
    & > .nav-item {
        font-family: "Raleway Bold", sans-serif;
        height: 100%;
        margin: 0;
        &:not(.user-block) {
            width: rem(80);
        }

        &.user-block {
            .nav-link {
                padding: 0 rem(28);
            }
        }

        &.sm-block {
            width: rem(70);
        }

        & > .nav-link {
            height: 100%;
            border-right: 2px solid $header-item-border;
            border-bottom: 2px solid transparent;
            &.opened {
                border-bottom-color: $header-item-border;
                background: $header-menu-active;
            }
        }
    }
}

#content {
    min-height: calc(100vh - 74px);
    padding: rem(40);
}

.table-fixed {
    table-layout: fixed;
}

.error-msg {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: $error;
}

.mobile-menu-icon {
    width: 34px;
    height: 22px;
    position: relative;
    cursor: pointer;
    span {
        position: absolute;
        height: 3px;
        width: 34px;
        background: #fff;
        opacity: 1;
        left: 0;
        @include transform(rotate(0deg));
        @include transition(.25s ease-in-out);
        @include transform-origin(left center);
        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: 9px;
        }

        &:nth-child(3) {
            top: 18px;
        }
    }

    .menu-open & {
        span {
            &:nth-child(1) {
                @include transform(rotate(45deg));
                top: -3px;
                left: 3px;
            }

            &:nth-child(2) {
                width: 0%;
                opacity: 0;
            }

            &:nth-child(3) {
                @include transform(rotate(-45deg));
                top: 20px;
                left: 3px;
            }
        }
    }
}

.custom-checkbox {
    display: none;
    & + label {
        cursor: pointer;
        &:before {
            font-family: 'Material Icons';
            content: "\e835";
            display: inline-block;
            vertical-align: middle;
            font-size: 30px;
        }
    }
    &:checked + label:before {
        content: "\e834";
        color: $red;
    }
}

.custom-checkbox-icon {
    display: none;

    &:disabled {
        *:not(.disabled-clear-bg) > & {
            & + label {
                cursor: default;
                &:before {
                    content: "\e943";
                    color: $bg-color-dark-grey;
                    font-size: rem(30);
                    left: rem(-1);
                    top: rem(-6);
                }
                span {
                    color: $bg-color-dark-grey;
                }
            }
        }

        .disabled-clear-bg & {
            & + label {
                cursor: default;
                &:before,
                &:after {
                    opacity: .5;
                }
            }
        }

    }

    & + label {
        cursor: pointer;
        position: relative;
        padding-left: rem(50);
        &:before {
            font-family: 'Djump-icons' !important;
            content: "\e940";
            font-size: rem(25);
            color: $light-orange;
            position: absolute;
            left: 0;
            top: rem(-5);
        }
    }
    &:checked + label:after {
        content: "\e914";
        font-family: 'Djump-icons' !important;
        color: $light-orange;
        position: absolute;
        left: rem(-3);
        top: rem(-7);
        font-size: rem(35);
    }
}

.custom-input {
    cursor: pointer;
    input {
        display: none;
        &:checked + i {
            color: $red;
        }

        &:checked + svg {
            fill: $red;
        }

        &:checked + .badge {
            opacity: 1;
        }

        &:not(:checked) + .badge {
            background: #000;
            color: #fff;
        }
    }
    i {
        @include transition(color .15s ease-in-out);
    }
    .badge {
        opacity: .5;
    }
}

.input-group-disabled {
    background: #e9ecef;
}

#app {
    .mx-datepicker {
        &:not(.dj-datepicker-icon) {
            width: 100%;
        }

        .mx-input {
            border: 0;
            padding-left: 0;
            font-family: 'Raleway SemiBold', sans-serif;
            font-size: rem(16);
            color: #333;
            height: rem(46);
        }

        .mx-input-append {
            cursor: pointer;
            top: 0;
            &:after {
                content: "\e908";
                font-family: 'Djump-icons';
                font-size: rem(35);
                color: $blue;
                font-weight: normal;
            }
        }

        .mx-calendar-icon {
            display: none;
        }

        &-popup {
            border-color: $dj-color-gray;
            border-radius: 10px;
        }
    }
}

.mx-datepicker {
    .date-picker-full & {
        width: 100%;
    }

    .mx-input {
        border-color: #ced4da;
        @include box-shadow(none);
        height: 38px;

        .calendar-customized & {
            background-color: $dj-color-gray;
        }
    }

    .mx-input-append {
        .calendar-customized & {
            background-color: $dj-color-gray;
        }

        .calendar-reporting & {
            &:after {
                line-height: rem(37);
            }
        }
    }
}

.fade-enter-active, .fade-leave-active {
    transition: all .3s ease-out;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

.additional-emails {
    left: auto;
    right: 0;
    padding: 18px;

    ul {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    li {
        font-size: 16px;
    }

    a {
        align-items: center;
        border-radius: 50%;
        background-color: $dj-color-gray;
        display: inline-flex;
        height: 16px;
        justify-content: center;
        width: 16px;
    }
}

.alt-loading-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .8);
    z-index: 2;
}

.loop {
  animation: spin 2s linear infinite;
}

.link--bordered {
    display: inline-block;
    border-bottom: 2px solid #222;
    line-height: rem(20);
    color: $dj-black-1;
    &:hover,
    &.active {
        cursor: pointer;
        color: $light-orange;
        border-color: $light-orange;
        text-decoration: none;
    }

    &:not(.lowercase) {
        text-transform: uppercase;
    }

    &:not(.sm) {
        font-family: "Raleway Bold", sans-serif;
        line-height: rem(24);
    }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.dj-error {
    @extend %dj-f14;
    color: $dj-validation-error;
}

.dj-error-msg {
    @extend %dj-f12;
    color: $dj-validation-error;
    margin-top: -1rem;
    margin-bottom: 1rem;

    &--line {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &--top-indent {
        margin-top: rem(-12);
        line-height: 1rem;
    }

    @for $i from 1 through 3 {
        &--offset#{$i} {
            margin-top: (-1 * $i) * 1rem;
            margin-bottom: ($i) * 1rem;
        }
    }
}

.dj-invalid, %dj-invalid {
    border: 2px solid $dj-validation-error;
}

.logo-aside {
    display: block;
    padding-top: 10px;
}

.mobile-info {
    display: flex;
    height: calc(100vh - 135px);
    align-items: center;
    justify-content: center;
    font: 1.5rem 'Raleway SemiBold';
    text-align: center;
    opacity: .7;
}

.carnet {
    img {
        width: auto;
        height: 800px;
    }
}

.jours {
    img {
        width: 100%;
        height: auto;
    }
}

.dimensions {
    img {
        width: 75%;
        height: auto;
    }
}

.terms-content {
    font-family: "Raleway SemiBold", sans-serif;
    line-height: 1.5;
}

.hidden {
    display: none !important;
}

.pom-actions {
    display: flex;
    justify-content: space-between;
    padding: 4rem 0 2rem;
}
