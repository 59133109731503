.selected-option {
  width: rem(230);
  height: rem(63);
  color: white;
  font-size: rem(16);
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  border-radius: rem(10);
  margin-right: rem(55);

  .header-left {
    border-radius: rem(10) 0 0 rem(10);
    width: rem(20);
  }

  .header-text {
    flex-grow: 1;
    justify-content: center;
    padding-right: rem(20);

    span {
      align-self: center;
    }
  }

  &.orange {
    background: #f5a00d;
    color: #222222;

    .dark {
      background: #c87e18;
    }
  }

  &.blue {
    background: #1c8fb9;

    .dark {
      background: #197fa5;
    }
  }

  &.red {
    background: #c11e53;

    .dark {
      background: #9d1944;
    }
  }

  &.green {
    background: #1cb09c;

    .dark {
      background: #189181;
    }
  }
}

.selected-option-row {
  display: flex;
  align-items: center;
  margin-top: rem(10);
}

.calendar-customized {
  padding: 0 rem(30) 0 rem(20);
  background-color: $dj-color-gray;
  .selected-option-row &,
  .options-grid & {
    width: rem(350);
  }
}

.calendar-label {
  font-weight: bold;
  align-items: center;
}

.options-grid {
  padding: rem(10);

  .title {
    margin-top: rem(20);
    margin-bottom: rem(20);
    font-size: rem(16);
    font-family: "Raleway Bold", sans-serif;
    text-transform: uppercase;
  }

  .options {
    padding: rem(20);
    @include flex-wrap;
    @include flex-justify-content(center);

    .option {
      width: 32%;
      min-width: rem(250);
      margin: rem(10) 0 rem(10) 0;
      @include align-items(center);

      label {
        font-size: 16px;
        font-weight: 600;
        padding-right: rem(10);
      }
    }

    .option-tooltip {
      margin-left: rem(10);
    }
  }

  .options-insurance {
    width: 100%;
    flex-direction: row-reverse;

    .third-box {
      width: 35%;
      flex-direction: column;
    }

    h4 {
      font-size: 14px;
      font-family: "Raleway Bold", sans-serif;
      text-transform: uppercase;
      margin-bottom: rem(10);
    }

    .insurance-input {
      width: 70%;
      height: rem(50);
      margin-bottom: rem(20);
      flex-direction: row;

      .insurance-sign {
        width: rem(40);
        background-color: $blue;
        justify-content: center;
        align-items: center;
        color: $white;
        font-weight: bold;
      }

      .input-box {
        height: rem(50);
        background-color: $dj-color-gray;
        padding: 0 rem(20);
        width: 90%;
      }

      .input-custom {
        border: none;
        font-family: "Raleway SemiBold", sans-serif;
        height: rem(50);
        background-color: $dj-color-gray;
      }
    }

    .insurance-output {
      padding: 0 rem(20);
      align-items: center;
      justify-content: space-between;
      background-color: $blue;
      color: $white;
      height: rem(50);
      width: 70%;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
}

.wizard-footer-custom {
  @include flex-justify-content(center);

  &_final-buttons {
    margin-right: auto;
    button {
      margin: 0 rem(20);
    }
  }
}
