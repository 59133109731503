@for $i from 10 through 30 {
  %dj-f#{$i}, .dj-f#{$i} {
    font-size: rem($i);
  }
}

strong, b, .dj-fb {
  font-family: "Raleway Bold";
}

i {
  font-family: "Raleway SemiBoldItalic";
}

.dj-tdn {
  text-decoration: none;
}

.dj-tdu {
  text-decoration: underline;
}

.dj-tdu-2 {
  border-bottom: 2px solid $dj-black-1;
}

.dj-f-r {
  float: right;
}

.dj-ttu {
  text-transform: uppercase;
}

.dj-tc {
  text-align: center;
}

.dj-ch {
  cursor: pointer;
}

.dj-pos-rel {
  position: relative;
}

.dj-pos-abs {
  position: absolute;

  &--vcenter {
    top: 50%;
    transform: translateY(-50%);
  }

  &--l10 {
    left: rem(10);
  }
}

.dj-container-icon {
  color: $dj-color-gray;
  position: absolute;
  font-size: rem(256);
  left: 0;
  top: 0;
  z-index: -1;
  &.dj-container-icon--admin {
    font-size: 15rem;
    opacity: .75;
  }

  &--reporting {
    left: rem(-130);
    top: rem(-106);
  }

  &--tracking {
    left: rem(-130);
    top: rem(-92);
  }

  &--user,
  &--admin {
    left: rem(-105);
    top: rem(-135);
  }

  &--hover {
    &:hover {
      [class^="icon-"],
      .dj-link {
        color: $dj-color-orange;
      }
    }
  }
}

.dj-bg-white {
  background-color: $dj-color-white;
}

.dj-dn {
  display: none;
}

.dj-w100pct {
  width: 100%;
}