.wiki-main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16em, 1fr));
  grid-gap: 2vw;

  &.my-docs-block {
    grid-template-columns: repeat(5, 1fr);
  }
}

.wiki-block {
  padding: 10px;
  height: 300px;
  background: $dj-color-gray;
  box-shadow: 6px 8px 0 0 rgba(0, 0, 0, 0.17);
  cursor: pointer;
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  &:hover {
    text-decoration: none;
  }

  &--img {
    width: 70%;
    margin-bottom: 2rem;
  }

  &--title {
    font-family: "Raleway Bold", sans-serif;
    font-size: rem(16);
    text-transform: uppercase;
    color: $dj-black-1;
    width: 70%;
    text-align: center;
    line-height: 1.35;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__title--blue {
    color: $blue;
  }

  &__date {
    font-family: "Raleway Bold", sans-serif;
    font-size: rem(14);
    text-transform: uppercase;
    color: $dj-color-middle-gray;
    width: 70%;
    text-align: center;
    line-height: 1.35;
    margin-top: auto;
  }

  &--delete {
    margin-top: auto;
    padding-left: 0;
    padding-right: 0;
    align-self: flex-end;
  }
}
