.subscription-section {
  margin-top: rem(70);
  font-size: 16px;

  img {
    margin-bottom: rem(50);
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }

  .title {
    text-transform: uppercase;
  }
}
