.quotation-request-block {
  margin-bottom: rem(28);
}

.quotation-error {
  font-size: 16px;
  font-weight: bold;
  color: $error;

  span {
    margin-bottom: 5px;
  }
}

.quotation-base {
  padding: rem(40) rem(40) rem(20);
  font-family: 'Raleway SemiBold', sans-serif;
  font-size: rem(16);

  .input-custom {
    border: none;
    height: rem(35);
    font-family: "Raleway SemiBold", sans-serif;
  }

  .row-wrapper {
    margin: 0 rem(30);
  }

  .white-block {
    height: rem(50);
    background: #fff;
    padding: 0 rem(20);
    margin-bottom: rem(20);
    margin-right: rem(30);
  }

  .more-options {
    text-transform: uppercase;
    margin-left: 10px;
    align-self: center;
  }

  .button-advanced {
    height: 25px;
    object-fit: contain;
    border-radius: 10px;
    background-color: #128caf;
    justify-content: center;
    cursor: pointer;
    border-style: solid;
  }

  .button-row {
    cursor: pointer;
    padding: 0;
    &:disabled {
      cursor: default;
    }
  }

  .row-count {
    width: rem(65);
    background: #fff;
    font-size: rem(24);
    color: #333;
    text-align: center;
    line-height: rem(50);
    margin: 0 rem(20);
  }

  .reset-margin {
    margin: 0 5px 0 0;
  }
}

.quotation-base-inner {
  margin-right: rem(-30);
  label {
    margin: 0;
  }
}

.autocomplete-input-block {
  flex-grow: 1;
  input {
    width: 100%;
    &::placeholder {
      font-style: italic;
    }
  }
}

.type-block {
  cursor: pointer;
  margin: rem(10) rem(60) rem(30) 0;
  &.checked {
    .type-img-container {
      &:before {
        opacity: 1;
        content: "\e907";
        color: $light-orange;
      }
    }
  }

  .type-img-container {
    width: rem(65);
    height: rem(68);
    position: relative;
    &:before {
      font-size: rem(75);
      font-family: 'Djump-icons';
      content: "\e917";
      position: absolute;
      top: -3px;
      left: -4px;
      opacity: .2;
    }
  }

  .type-name {
    color: #333;
    font-size: rem(14);
  }

  [class^="icon-"] {
    display: inline-block;
    position: relative;
  }

  &:not(.checked) {
    [class^="icon-"], .type-name {
      opacity: .35;
    }
  }
}

.quotation-row {
  background: $bg-dark-gray;
  padding: rem(30) rem(30) 0;
  margin-bottom: rem(15);
  position: relative;
  .remove-row {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: rem(-60);
  }
}

.quotation-desc {
  //margin-bottom: rem(20);
  cursor: pointer;
  span {
    border: 2px solid $blue;
    border-radius: 25px;
    color: $blue;
    line-height: rem(40);
    text-align: center;
    width: rem(160);
    margin-right: rem(30);
    font-size: rem(14);
    margin-bottom: rem(35);
    .no-option-selected & {
      border-color: $border-error;
    }
  }

  input:checked + span {
    background: $bg-btn-round-blue;
    color: #fff;
  }
}

.quotation-adjust-quantity {
  margin-right: rem(30);
  margin-bottom: rem(35);
}

.quotation-label {
  margin: rem(20) 0;
  font-size: rem(16);
}
