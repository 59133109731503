.quotation-user-block {
  justify-content: space-between;
  & > * {
    height: rem(210);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.template-download-block {
  margin-left: auto;
  padding: rem(15) 0;
}