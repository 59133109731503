.spinner {
  animation: spin 1s linear infinite;
  border: 3px solid $bg-dark-gray;
  border-top-color: $blue;
  border-radius: 50%;
  height: 75px;
  width: 75px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}