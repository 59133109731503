.contact-button-round {
  background: $blue;
  position: fixed;
  bottom: rem(10);
  right: rem(10);
  width: rem(70);
  height: rem(65);
  padding: 0;
  cursor: pointer;
  border-radius: 15px;
  z-index: 1;
}

.livezilla-button {
  bottom: rem(82);
}

.form-button {
  bottom: rem(140);
}

.chat-hidden {
  display: none !important;
}

.tooltip-button {
  display: none;
  position: fixed;
  bottom: rem(20);
  right: rem(100);
  height: rem(50);
  border-radius: 25px;
  border: solid 2px #e3e2da;
  background-color: #ffffff;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "Raleway Bold", sans-serif;
  color: $dj-black-2;
  padding: 0 rem(20);
  z-index: 1;
  &.active,
  &:hover {
    box-shadow: 0 0 5.3px 1.8px rgba(0, 0, 0, 0.11);
  }
}

.contact-option {
  background: $light-orange;
  position: fixed;
  right: rem(10);
  border-radius: 25px;
  box-shadow: 0 0 5.3px 1.8px rgba(0, 0, 0, 0.11);
  color: $dj-black-2;
  text-align: center;
  padding: 0 rem(25);
  font-size: rem(14);
  line-height: rem(50);
  font-family: "Raleway Bold", sans-serif;
  text-transform: uppercase;
  z-index: 1;

  &:hover {
    cursor: pointer;
    background: $dark-orange;
  }
}

#contact-details {
  height: 10rem;
}