.dj-dashboard {
  display: block;
  max-width: 1012px;
  width: 100%;
}

.dj-panel {
  // do not define padding here
  // instead use the inner __container-* html elements
  background-color: $dj-color-gray;
  border: solid 2px $dj-color-orange;
  border-radius: 10px;
  margin-bottom: 1rem;
  position: relative;
  //overflow: hidden;

  &--gray {
    border-color: $dj-color-middle-gray;
  }
}
.dj-panel__container-common {
  padding: rem(12) rem(21);
}
.dj-panel__container-tr-fold {
  padding-top: 0;
}
.dj-panel__container-tr-status-text {
  padding-left: 60px;
  padding-top: 12px;
  padding-bottom: 34px;
  padding-right: 12px;
}

.dj-panel-2 {
  background-color: $green;
  color: $dj-color-white;
  font-family: $dj-font-bold;
  padding: rem(20);
  margin-bottom: rem(20);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(20);
  }

  &__body {
    text-transform: uppercase;
  }

  &--blue {
    background-color: $light-blue;
  }

  &--red {
    background-color: $red;
  }
}

.dj-form-group {

  &--inline {
    display: flex;
    align-items: center;
  }
}

.dj-status-icon {
  background-color: $dj-color-white;
  border-radius: 50%;
  padding: rem(6);
  position: relative;
  width: rem(34);
  height: rem(34);
  transform: translateY(-20%);

  i {
    color: $green;
    position: absolute;
    top: rem(2);
    left: rem(3);
    &.icon-check {
      top: rem(4);
    }
  }

  &--blue {
    i {
      color: $light-blue;
    }
  }
}

.dj-tr-filter {

  &__type {

  }
  &__input {
    display: none;
  }
  &__label {
    display: inline-block;
    margin-bottom: 0;
    cursor: pointer;
    width: rem(160);
    text-align: center;
  }
}
.dj-tr-info {
  &__title {
    color: $dj-black-1;
    text-transform: uppercase;
    font-family: $dj-font-bold;
  }

  &__header {
    margin-bottom: 1rem;
  }
}

.dj-tr-info__c-name {
  font-family: $dj-font-bold;
  color: $dj-black-2;
  text-transform: uppercase;
  padding-right: 15px;
}

.dj-tr-info__c-name--1 {
  width: 7%;
}

.dj-tr-info__c-name--2 {
  width: 7%;
}

.dj-tr-info__c-name--3 {
  width: 9%;
}

.dj-tr-info__c-name--4 {
  width: 15%;
}

.dj-tr-info__c-name--5 {
  width: 15%;
}

.dj-tr-info__c-name--6 {
  width: 18%;

}

.dj-tr-info__c-name--7 {
  width: 18%;
}

.dj-tr-info__c-name--8 {
  width: 5%;
}

.dj-tr-info__c-name--9 {
  width: 5%;
}

.dj-tr-fold {
  position: relative;
}

.dj-tr-fold__header {
  position: relative;
  min-height: 90px;
  cursor: pointer;
}

.dj-tr-fold__location {
  margin-right: 55px;
  cursor: pointer;
  font-family: $dj-font-bold;
  text-transform: uppercase;
}

.dj-tr-fold__control {
  position: absolute;
  cursor: pointer;
}

.dj-tr-fold__control--toggle {
  // if there are no other links in html before this in .dj-tr-fold
  // this should be moved to .dj-tr-fold__location as a regular element
  right: 24px;
  top: 50%;
  transform: translateY(-50%);

  svg {
    width: 17px;
  }
}

.dj-tr-fold__control--fold {
  text-align: center;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.dj-tr-fold__c-name {
  white-space: nowrap;
  padding-right: 15px;
  line-height: 1.5rem;
}

.status-col {
  //padding-left: 15px;
}

.dj-tr-fold__c-name--1 {
  width: 7%;
}

.dj-tr-fold__c-name--2 {
  width: 7%;
}

.dj-tr-fold__c-name--3 {
  width: 9%;
}

.dj-tr-fold__c-name--4 {
  width: 15%;
}

.dj-tr-fold__c-name--5 {
  width: 15%;
}

.dj-tr-fold__c-name--6 {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 18%;
}

.dj-tr-fold__c-name--7 {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 18%;
}

.dj-tr-fold__c-name--8 {
  width: 5%;
}

.dj-tr-fold__c-name--9 {
  width: 5%;
  padding-left: rem(25);
}

.dj-tr-fold__footer {
  height: 68px;
  position: relative;

  &--hide {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
  }
}
.dj-tr-fold__body {
  background-color: $bg-dark-gray;
  padding: 30px;
  transition: all 0.5s ease;
}

.dj-tr-fold__body--hide {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
}

.dj-tr-fold__left {
  padding-right: 20px;
}

.dj-tr-status {
  position: relative;
}

.dj-tr-status__strip {
  position: absolute;
  top: 0;
  bottom: 0;
  left: rem(16);
  width: 3px;
  background-color: $dj-color-white;
}

.dj-tr-status__strip--first {
  top: 40%;
}

.dj-tr-status__strip--last {
  height: rem(20);

  &.dj-tr-status__strip--first {
    height: 0;
  }
}

.dj-tr-status__left {
  display: flex;
  align-items: center;
}

.dj-tr-status__right {
}

.dj-tr-status-text {
  &__header {
    padding: 25px 18px 25px 24px;
    border-left: 36px solid $dj-color-orange;
    margin: -12px 0px 33px -60px;
    border-bottom: 3px solid $dj-color-orange;
    text-align: center;
    border-top-left-radius: 5px;

    &--gray {
      border-color: $dj-color-middle-gray;
    }
  }

  &__title {
    font-size: rem(14);
    font-family: $dj-font-bold;
    color: $dj-black-1;
    text-transform: uppercase;
  }

  &__body {
    min-height: rem(160);
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      font-size: rem(65);
      font-family: $dj-font-semibold;
    }
  }

  &__row {
    position: relative;
    border-bottom: 2px solid $dj-color-middle-gray;
    padding-bottom: 10px;
    margin-bottom: 10px;
    &:after {
      content: ' ';
      position: absolute;
      width: 24px;
      left: -24px;
      bottom: -2px;
      border-bottom: 2px solid $dj-color-middle-gray;
    }
    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
      &:after {
        display: none;
      }
    }
  }

  &__text {
    font-weight: bold;
    font-size:  rem(14);
    color: $dj-black-1;
  }

}
.dj-tr-fold__right {
  padding-left: 20px;
}

.dj-tr-package-info {
  margin-bottom: rem(40);

  &__title {
    font-size: rem(16);
    font-family: $dj-font-bold;
    display: block;
    margin-bottom: rem(18);
    text-transform: uppercase;
  }
  &__receiver {
    font-family: $dj-font-semibold;
    text-transform: uppercase;
    display: block;
  }
  &__text {
    font-family: $dj-font-semibold;
    display: block;
    margin-bottom: rem(8);
  }
}

.dj-tr-options {

}

.dj-tr-options__option {
  margin-left: rem(-10);
  display: flex;
  align-items: center;
  margin-bottom: rem(46);
}

.dj-tr-additional {

}

.dj-tr-additional__item {
  font-size: rem(14);
  font-family: "Raleway Bold", sans-serif;
  display: flex;
  align-items: center;
  margin-bottom: rem(22);
  text-transform: uppercase;

  &-emails {
    margin-bottom: rem(18);
  }

  i {
    margin-right: rem(10);
  }

  &--disabled {
    color: $dj-color-middle-gray;
  }

  .dj-error-msg {
    text-transform: none;
    margin: rem(5) 0 0;
  }
}

.dj-link {
  color: $dj-text-color;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    color: $dj-color-orange;
    text-decoration: underline;
  }

  &--no_underline {
    &:hover {
      color: $dj-color-orange;
      text-decoration: none;
    }
  }

  &--underlined {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: -2px;
      left: 0;
      height: 2px;
      background-color: $dj-text-color;
    }

    &:hover {
      text-decoration: none;
      &::after {
        background-color: $dj-color-orange;
      }
    }
  }
  &--bold {
    font-family: $dj-font-bold;
  }
  &--warning {
      color: $dj-color-red;
      text-decoration: none;
  }

}

.dj-export-excel {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.dj-e-list {
  list-style: none;
  font-size: 1rem;
  font-weight: normal;

  &__item {
    margin-bottom: rem(9);
  }

  &__remove {
    cursor: pointer;
  }

  &__icon-remove {
    font-size: rem(28);
    vertical-align: middle;
    color: $dj-color-white;
  }

  &__text {
    font-family: $dj-font-semibold;

  }
}

.dj-search-tracking {
  margin-right: rem(30);

  i {
    background-color: $dj-color-white;
    color: $dj-color-middle-gray;
    font-size: rem(36);
    height: 50px;
    display: flex;
    align-items: center;
  }

  .dj-form-control {
    font-family: "Raleway Bold", sans-serif;
  }

  &:not(.w-auto) {
    width: calc(100% - 560px);
  }
}

.dj-select-tracking {
  .dj-select {
    margin-right: rem(25);
    &.l-indent {
      margin-left: rem(25);
      &.max-w {
        width: calc(100% - 50px);
      }
    }
  }

  label {
    width: rem(250);
    color: $dj-color-middle-gray;
    font-family: "Raleway Bold", sans-serif;
    font-size: rem(16);
    text-align: right;
  }
}

.tr-doc-disabled {
  color: $dj-color-middle-gray;
}

.forced-notification-mail-input {
  label {
    color: $dj-color-middle-gray;
    font-family: "Raleway Bold", sans-serif;
    font-size: rem(16);
    text-align: right;
  }
}
