@media (min-width: 1550px) {
    .double-spacing-md.row {
        margin-right: -30px;
        margin-left: -30px;
        & > *[class^="col"] {
            padding-right: 30px;
            padding-left: 30px;
        }
    }

    .home-block-item {
        font-size: rem(20);
        .home-nav & {
            height: rem(200);
        }
    }

    .block-gray {
        &:not(.custom-indent):not(.items-list) {
            padding: rem(40);
        }

        &.with-title {
            .content.number {
                font-size: 7rem;
            }
        }

        &.quotation-base {
            padding: rem(50) rem(95) rem(20);
        }
    }

    .font-lg-20 {
        font-size: rem(20);
    }

    .font-lg-16 {
        font-size: rem(16);
    }

    .icon-cotation {
        .home-block-item & {
            font-size: rem(150);
        }
    }

    .icon-tracking {
        .home-block-item & {
            font-size: rem(180);
        }
    }

    .icon-reporting {
        .home-block-item & {
            font-size: rem(200);
        }
    }

    .icon-faq {
        .home-block-item & {
            font-size: rem(165);
        }
    }

    .quotation-user-block {
        justify-content: space-between;
        & > * {
            height: 20rem;
        }
    }

    .quotation-summary {
        padding: 0 rem(80);
    }
}

@media (max-width: 1549px) {
    .quotation-row {
        .remove-row {
            right: -35px;
        }
    }

    .carnet,
    .jours,
    .dimensions {
        img {
            width: 95%;
            height: auto;
        }
    }

    .options-grid {
        .option-with-tooltip {
            display: inline-block;
            max-width: 60%;
        }
    }

    .dj-tr-fold__location {
        margin-right: 38px;
    }

    .status-col {
        //padding-left: 15px;
    }

    .icon-arrow-menu {
      .dj-tr-fold__control--toggle & {
        margin-right: -17px;
      }
    }

    .my-docs-block {
        .wiki-block {
            height: auto;
            padding: 10px 10px 50px;
        }
    }
}

@media (min-width: 1450px) {
    .forum-admin-container {
        padding: 0 rem(100);
    }
}

@media (min-width: 1300px) {
    .main-header {
        padding: 0 rem(130) 0 rem(55);
    }

    #content {
        padding: rem(50) rem(130) rem(110) rem(55);
        .chat-history-open & {
            padding-bottom: 0;
        }
    }

    .contact-button-round {
        background: url('../../public/images/contact-bg.png') 0 0 no-repeat;
        width: rem(142);
        height: rem(132);
        border-radius: 0;
        bottom: 0;
        right: 0;
        .icon-chatbot {
            font-size: rem(60);
            margin-top: rem(30);
            margin-left: rem(45);
        }
    }

    .tooltip-button {
        display: flex;
    }

    .icons-nav {
        width: 60%;
    }

    .home-faq-block {
        min-height: rem(157);
    }

    .livezilla-button {
        bottom: rem(75);
        right: rem(100);
    }

    .form-button {
        bottom: rem(132);
        right: rem(100);
    }
}

@media (max-width: 1299px) {
    html {
        font-size: 12px;
    }

    .news-desc-inner {
        display: none;
    }

    .home-block-item {
        [class^="icon-"] {
            //font-size: rem(85);
            right: rem(-20);
            bottom: rem(-20);
        }
    }

    .quotation-row {
        .remove-row {
            right: -30px;
        }
    }

    .quotation-form {
        padding: 3.57143rem 5.2rem 0;
        &.login {
            border-radius: rem(10);
        }

        &.register {
            border-radius: rem(10);
            position: relative;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background: linear-gradient(to right, #f0f0ee 0%, #999 50%, #f0f0ee 100%);
            }
        }
    }

    .quotation-detailed-item {
        margin-right: rem(-30);
    }

    .reporting-type-filter .dj-tr-filter__label {
        width: 9rem;
    }

    .calendar-reporting .dj-select {
        width: 20rem;
    }

    .operator-main {
        .main-header .dropdown-inner.user {
            width: 25rem;
        }
    }
}

@media (min-width: 1025px) {
    .modal-open {
        padding-right: 17px;
    }

    .operator-chat {
        .dj-chat {
            &__message-block--system {
                .dj-chat__message-content {
                    width: 100%;
                    max-width: none;
                }
            }

            &__message-content {
                max-width: 75%;
            }
        }
    }
}

@media (max-width: 1024px) {
    .operator-chat {
        .helper-block {
            display: none;
        }

        .select-block {
            width: auto;
            margin-left: auto;
        }
    }
}

@media (min-width: 998px) {
    .navigation {
        .nav-item {
            padding-left: rem(18);
        }

        .nav-link {
            [class^="icon-"] {
                margin-right: rem(15);
                &.icon-custom-tower {
                    margin-right: rem(17);
                }

                &.icon-tracking,
                &.icon-reporting,
                &.icon-gestion {
                    margin-right: rem(13);
                }

                &.icon-tchat {
                    margin-right: rem(19);
                }
            }
        }
    }

    .mobile-menu-icon {
        display: none;
    }

}

@media (max-width: 997px) {
    aside {
        display: none;
        position: fixed;
        top: 74px;
        bottom: 0;
        width: 100%;
        z-index: 3;
        overflow: auto;
        .menu-open & {
            display: block;
        }
    }

    #app {
        &.with-aside {
            width: 100%;
        }
    }

    .logo-aside {
        display: none;
    }

    .navigation {
        padding: 0;
        .nav-item {
            height: 6rem;
            padding: 0 0 0 3rem;
            &:after {
                content: '\e906';
                font-size: 4rem;
                font-family: "Djump-icons";
                transform: rotate(-90deg);
            }

            &.active, &:hover {
                &:before {
                    display: none;
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid rgba(51, 51, 51, 0.5);
            }
        }
        .nav-link {
            font-size: 1.3rem;
            justify-content: start;
            width: 100%;
            padding: 0;
            [class^=icon-] {
                display: none;
            }
        }
    }

    .icons-nav {
        width: 80%;
        margin-left: auto;
        & > .nav-item {
            &:not(.user-block) {
                width: auto;
            }

            & >.nav-link {
                padding: 0 1rem;
            }
        }

        .chat-history-icon-header {
            display: none;
        }
    }

    .main-header {
        padding: 0 rem(20);
    }

    #content {
        padding: rem(20);
    }

    .sm-indent-top {
        margin-top: 1.5rem;
    }

    .chat-history-block--left {
        width: rem(240);
    }

    .chat-history-block--right {
        width: calc(100% - 18.57143rem);
    }

    .cookies-accept-block {
        display: block;
        text-align: center;
        .cookies-info {
            margin: 0 0 rem(20);
            max-width: none;
        }

        .cookies-actions {
            justify-content: center;
        }
    }

    .operator-chat {
        .dj-chat-header-inner {
            width: 200px;
            margin-left: 35px;
        }

        .dj-tabs {
            width: calc(100% - 250px);
        }
    }

    .operator-main {
        .lang-dropdown {
            .dropdown-inner.show {
                left: auto;
                right: 0
            }
        }
    }
}

@media (min-width: 768px) {
    .account-block {
        margin-top: 3rem;
    }

    .quotation-list-link {
        margin-left: auto;
    }

    .mobile-info {
        display: none;
    }

    .home-text {
        width: rem(605);
    }

    .navigation:not(.operator-menu) {
        .nav-item-chat_history {
            display: none;
        }
    }

    .mobile-menu-icon {
        margin-right: rem(20);
    }

    .quotation-label-md {
        display: flex;
    }

    .quotation-label-sm {
        display: none;
    }

    .quotation-summary {
        .logistics {
            .section {
                width: 20%;
                min-width: rem(150);

                &.centered {
                    @include flex-justify-content(center);
                    @include align-items(center);

                    i {
                        font-size: 40px;
                    }
                }
            }
        }

        .quotation-terms_row {
            justify-content: center;
        }
    }

    .operator-chat-inner {
        display: flex;
        .dj-chat-header {
            order: 2;
        }

        .dj-tabs {
            order: 1;
        }

        .dj-chat-header-inner {
            display: flex;
            justify-content: flex-end;
            & > *:first-child {
                margin-bottom: 3rem;
            }
        }
    }

    .toasted-container.bottom-left {
        bottom: rem(20) !important;
        left: 15rem !important;
    }
}
  
@media (max-width: 767px) {
    .dj-chat {
        &.sm {
            height: 100%;
            width: 100%;
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;
            border-radius: 0;
        }

        &-header {
            .sm & {
                border-radius: 0;
            }
            .select-block {
                .btn--orange {
                    padding: 0 rem(30);
                }
            }
        }

        &-content {
            .sm & {
                height: calc(100% - 7rem);
            }
        }

        &__textarea {
            .sm & {
                border-radius: 0;
            }
        }

        &__messages {
            .sm & {
                height: calc(100% - 5rem);
            }
        }

        &__message {
            font-size: 12px;
        }

        .dj-btn--square {
            font-size: 14px;
            padding: 0 2rem;
            width: auto;
        }
    }

    html {
        font-size: 16px;
    }

    .vue-form-wizard {
        .stepTitle,
        .wizard-header {
            display: none;
        }

        .wizard-tab-content {
            padding: 0;
        }

        .wizard-card-footer {
            padding: 0;
        }
    }

    .hide-mobile {
        display: none;
    }

    .break-word-mobile {
        word-break: break-all;
    }

    .userlist-filter-block {
        @include flex-column;
        & > .col {
            @include flex-basis(auto);
            margin-bottom: 1rem;
        }
    }

    .sm-wrap {
        @include flex-wrap;
    }

    #content {
        padding-top: 2.5rem;
        &:not(.op-content) {
            padding-bottom: 100px;
        }

        > .sm-spacing-x-0 {
            margin-left: -24px;
            margin-right: -24px;
        }
    }

    .account-block {
        width: 100%;
        margin: 5% 0;
    }

    .api-exposure {
        max-width: none;
        .action-block {
            margin-top: 10px;
        }
    }

    .search-block {
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
        background: $bg-btn-sm-blue;
        line-height: 60px;
        padding: 0 1rem;
        z-index: 2;
        .icon-search {
            margin-right: 0;
        }
    }

    .contact-button-round {
        bottom: rem(65);
    }

    .livezilla-button {
        bottom: rem(140);
    }

    .form-button {
        bottom: rem(200);
    }

    .main-header {
        body:not(.modal-open) & {
            z-index: 2;
        }

        .dropdown-inner.show {
            min-width: rem(67);
            max-width: 26rem;
        }
    }

    .dj-thread-list__item-cell {
        justify-content: left;
    }

    .flex-column-sm {
        flex-direction: column;
    }

    .d-flex {
        > .flex-item-12-sm {
            flex: 0 0 100%;
            max-width: 100%;
        }

        > .flex-item-6-sm {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .more-options-block {
        justify-content: center;
        margin-top: 2rem;
    }

    .quotation-types {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .quotation-adjust-quantity {
        margin-right: 0;
    }

    .flex-wrap-sm {
        flex-wrap: wrap;
    }

    .quotation-row .remove-row {
        right: -40px;
    }

    .home-faq-block,
    .news-feed {
        width: 100%;
    }

    .news-feed {
        margin-bottom: 2rem;
        .VueCarousel {
            margin: 0 1rem;
        }

        .VueCarousel-navigation {
            display: none;
        }

        .carousel-block {
            width: 100%;
        }
    }

    .type-block {
        width: 50%;
        margin-right: 0;
    }

    .quotation-user-block {
        flex-wrap: wrap;
        justify-content: flex-start;
        & > [class*="flex-item-"] {
            flex: 0 0 45%;
            max-width: 45%;

            &:first-child {
                margin-right: 10%;
                margin-bottom: 2rem;
            }

            &:last-child {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }

    .operator-chat {
        .dj-chat-header {
            padding: 0;
        }

        .dj-chat-header-inner {
            width: 100%;
            margin: 0;
            padding-bottom: 30px;
            & > *:first-child {
                margin-right: 30px;
            }

            .btn--orange {
                padding: 0 2.5rem;
            }
        }

        .dj-tabs {
            width: 100%;
            height: 100vh;
        }

        .dj-chat {
            &__message {
                font-size: 12px;
                padding: rem(16);
            }

            &__textarea {
                height: 50px;
            }
        }

        textarea {
            padding: rem(16);
            font-size: 16px;
            &::placeholder {
                font-size: 12px;
            }
        }

        .dj-select {
            font-size: rem(14);
        }

        .dj-popup__body {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    input[data-flex-tab]:checked+.dj-tabs__tab+.dj-tabs__content {
        height: calc(100% - 106px);
    }

    .dj-popup--pom {
        width: auto;
        max-width: 100%;
        .dj-popup__body {
            padding: 0;
        }

        .pom-actions {
            flex-direction: column;
            & > * {
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 0 1.5rem;
                text-align: center;
                &:first-child {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .quotation-form {
        width: 100%;
        padding: 3rem 1rem 0;

        &.register {
            &:before {
                width: 70%;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .quotation-description {
        .result {
            .selected-price {
                display: block;
                margin: 0;
                text-align: center;
            }
        }
    }

    .selected-option-row {
        display: block;
        .selected-option {
            margin-bottom: 2rem;
        }

        .calendar-label {
            margin-bottom: rem(10);
        }
    }

    .options-grid {
        .options {
            padding: 1rem 0;
            .option {
                width: 100%;
            }
        }

        .option-with-tooltip {
            max-width: none;
        }

        .options-insurance {
            flex-direction: row;
            .third-box {
                width: 100%;
            }
        }
    }

    .wizard-footer-custom {
        &.wizard-footer-last {
            flex-direction: column;
            .wizard-footer-custom_final-buttons {
                flex-direction: column;
                padding-top: rem(40);
                margin: 0;
                align-items: center;
                button {
                    margin: 0 0 rem(20);
                    width: 18rem;
                    padding: 0 2rem;
                }
            }
        }
    }

    .quotation-label-sm {
        display: block;
    }

    .quotation-summary {
        .price-totals {
            width: 70%;
            .price-cell {
                width: auto;
            }
        }

        .quotation-terms_row {
            line-height: 1.35;
            .custom-checkbox-icon+label {
                padding-left: 2.5rem;
                max-width: 100%;
            }

            .link--bordered {
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 100%;
                overflow: hidden;
                vertical-align: text-top;
            }
        }

        .options-totals_row {
            line-height: 1.35;
            padding: 1rem;
            height: auto;
            .price-cell {
                width: auto;
                white-space: nowrap;
                padding-left: rem(10);
            }
        }

        .logistics {
            .centered {
                padding-top: 1rem;
            }
        }
    }

    .dj-popup--overflow {
        .dj-popup__body-inner {
            padding: 0;
        }
    }

    .contact-form {
        &:not(.p-0) {
            padding: 2rem;
        }
    }

    .toasted-container {
        max-width: 90%;
    }
}

@media (min-width: 581px) {
    .multiple-quotations-link {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }
}

@media (max-width: 670px) {
    .dj-popup--pom {
        .pom-actions {
            padding-top: 2rem;
        }
    }
}

@media (max-width: 665px) {
    .quotation-options {
        justify-content: center;
    }

    .quotation-option-placeholder {
        padding: rem(14);
        min-width: 16rem;
    }
}

@media (max-width: 640px) {
    .block-gray.quotation-base {
        padding: rem(20);
        .label-autocomplete {
            display: none;
        }
    }

    .quotation-row .remove-row {
        transform: none;
        top: 10px;
        right: 10px;
    }
}
  
@media (max-width: 580px) {
    .sm-full {
        width: 100%;
    }

    .multiple-quotations-block {
        width: 95%;
        min-height: 465px;
    }

    .upload-files-header {
        font-size: 30px;
        padding: 15px 0;
    }

    .upload-files-body {
        padding-top: 30px;
    }

    .excel-img {
        width: 100px;
        height: 100px;
    }

    .upload-files .preview {
        margin-bottom: 30px;
    }

    .dj-popup {
        width: auto;
        max-height: 90vh;
        overflow: auto;
        max-width: 95%;
    }

    .dj-modal__content {
        min-width: 90%;
        max-width: 100%;
    }

    .dj-popup__body, .operator-chat .dj-popup__body {
        padding: 0;
    }

    #app {
        .quotation-base {
            .mx-datepicker-popup {
                left: -63px !important;
            }
        }

        .quotation-description {
            .calendar-customized {
                .mx-datepicker-popup {
                    left: -38px !important;
                }
            }
        }
    }

    .type-block {
        justify-content: space-around;
        .type-name {
            display: none;
        }
    }

    .quotation-delivery-option {
        .price {
            font-size: 2.35rem;
        }
    }
}

@media (min-width: 561px) {
    .contact-form {
        .contact-form-row {
            display: flex;
            justify-content: space-between;
        }

        .contact-item {
            width: 45%;
        }
    }

    .operator-transfer-textarea {
        margin-bottom: 3rem;
    }
}

@media (max-width: 560px) {
    .vue-form-wizard {
        .wizard-card-footer {
            .wizard-footer-left,
            .wizard-footer-right {
                float: none;
                display: flex;
                flex: 1;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                button {
                    width: 60%;
                    margin-top: 10px;
                }
            }
        }
    }

    .flex-column-xs {
        flex-direction: column;
    }

    .d-flex {
        > .flex-item-12-xs {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .icons-nav {
        & > .nav-item {
            &.user-block {
                .nav-link {
                    padding: 0 1rem;
                }
            }
        }

        .user-name:not(.login-text),
        .login-text {
            display: none;
        }
    }

    .home-nav {
        .home-block-item {
            width: 100%;
            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }

    .user-block.dropdown {
        position: static;
    }

    .main-header {
        position: relative;
        padding: 0 0 0 1rem;
        .dropdown-inner.user {
            max-width: 100%;
        }
    }

    .contact-button-round {
        width: 3.5rem;
        height: 3.5rem;
        .icon-chatbot {
            font-size: 2rem;
        }
    }

    .livezilla-button {
        bottom: 8.5rem;
    }

    .form-button {
        bottom: 12.5rem;
    }

    .quotation-user-block {
        & > [class*="flex-item-"] {
            flex: 0 0 100%;
            max-width: 100%;

            &:first-child {
                margin-right: 0;
            }

            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }

    .quotation-option-placeholder {
        padding-left: 0;
        padding-right: 0;
    }

    .vue-form-wizard.md {
        .wizard-icon-circle {
            width: 4rem;
            height: 4rem;
            &:before {
                font-size: 4rem;
            }

            .wizard-icon {
                font-size: 2rem;
            }
        }

        .wizard-nav-pills>li.active>a .wizard-icon {
            font-size: 2rem;
        }
    }

    .quotation-description {
        .result {
            span {
                font-size: 1.5rem;
            }
        }
    }

    #captcha-account-placeholder {
        transform: scale(0.97);
        transform-origin: 0 0;
    }

    .selected-option-row {
        .calendar-customized {
            width: 100%;
        }
    }

    .options-grid {
        padding: 0;
        .options-insurance {
            .insurance-input,
            .insurance-output {
                width: 100%;
            }
        }

        .options {
            .option {
                label {
                    padding-right: 0;
                }
            }
        }

        .custom-checkbox-icon+label {
            padding-left: 3rem;
        }
    }

    .quotation-description {
        padding-left: 0;
        padding-right: 0;
        .result {
            padding: rem(10) 0;
            height: auto;
            .result-places {
                width: 100%;
                text-align: center;
                padding: 0 0 rem(10);
                & + .separator {
                    display: none;
                }
            }

            .selected-price {
                padding-top: rem(10);
            }
        }
    }

    .quotation-summary {
        .price-totals {
            width: 100%;
            padding: 1rem;
        }

        .box {
            padding: 1rem;
        }

        .totals-row {
            flex-wrap: wrap;
            .static-word {
                width: 100%;
                margin-bottom: 1rem;
            }

            .price-cell {
                width: auto;
            }
        }

        .selected-items {
            .quotation-adv-row {
                & + .totals-row {
                    margin-top: 2rem;
                }

                .adv-type {
                    width: auto;
                    min-width: auto;
                }

                .adv-quantity {
                    width: auto;
                    margin-left: auto;
                    min-width: auto;
                }
            }
        }
    }

    .contact-form {
        .btn--orange.btn--orange-lg {
            min-width: auto;
        }
    }

    .search-block {
        input {
            font-size: 15px;
        }
    }

    .operator-chat {
        .dj-chat-header-inner {
            padding: 0;
            & > *:last-child {
                margin: 1.5rem 0;
            }
        }
    }

    .operator-transfer-textarea {
        margin-bottom: 1rem;
        height: 115px;
    }

    .btn--orange.reduce-sm {
        padding: 0 2rem;
        font-size: 0.9rem;
    }
}

@media (max-width: 365px) {
    .icons-nav>.nav-item>.notifications-icon-header {
        padding: 0 0.5rem;
    }

    .notifications-icon-header {
        .icon-notification {
            font-size: 2rem;
            &.notify {
                span {
                    top: 0;
                    left: 1rem;
                }
            }
        }
    }

    .main-header {
        font-size: 1rem;
        .icon-arrow-menu {
            font-size: 1.5rem;
        }

        .user-pic {
            width: 30px;
            height: 30px;
        }

        .currency-dropdown {
            .dropdown-inner.show {
                min-width: auto;
                max-width: none;
                width: 100%;
            }
        }
    }
}

@media (max-width: 350px) {
    .main-header {
        .dropdown-inner {
            &.user {
                max-width: 100%;
            }

            .dropdown-item {
                padding: 1rem;
                font-size: 1rem;
            }
        }
    }

    .quotation-base .white-block {
        padding: 0 rem(10);
        margin-bottom: 1rem;
        margin-right: 1rem;
    }

    .quotation-base-inner {
        margin-right: -1rem;
    }

    .btn--orange {
        padding: 0 2rem;
    }

    #app {
        .quotation-base {
            .mx-input-append {
                width: 43px;
            }
            .mx-datepicker-popup {
                left: -55px!important;
            }
        }

        .quotation-description .calendar-customized,
        .quotation-base {
            .mx-datepicker-popup {
                .mx-calendar {
                    padding: 20px;
                }

                .mx-icon-last-month {
                    margin-left: -15px;
                }

                .mx-icon-next-month {
                    margin-right: -15px;
                }
            }
         }
    }

    .quotation-row {
        padding: 1.3rem 1.3rem 0;
        & + & {
            padding-top: 3rem;
        }
    }

    .quotation-desc span {
        margin-right: 0;
    }

    .quotation-base {
        .quotation-detailed-item {
            margin-right: 0;
            .white-block {
                margin-right: 0;
            }
        }
    }

    #captcha-account-placeholder {
        transform: scale(0.8);
        transform-origin: 0 0;
    }

    .quotation-form {
        h3 {
            font-size: rem(20);
        }

        h4 {
            font-size: 1rem;
        }
    }
}

@media (max-height: 400px) {
    .terms-content {
        max-height: 60vh;
    }

    .dj-modal__container {
        height: 90vh;
    }

    .dj-modal__content,
    .dj-popup {
        height: 100%;
        min-width: 70%;
        max-width: 100%;
    }

    .dj-popup {
        overflow: auto;
        &.dj-popup--pom {
            max-height: 70vh;
            transform: translateY(-50%);
            top: 50%;
        }
    }
}

.dj-show-md {
    display: none;
}

@media (max-width: 1200px) {
    .dj-hide-md {
        display: none;
    }

    .dj-show-md {
        display: block;
        overflow: hidden;
    }

    .dj-md-90pct {
        width: 90%;
    }

    .dj-transportation-type .type-block{
        margin: rem(10) rem(30) rem(30) 0;
    }
}
